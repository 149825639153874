<script setup lang="ts">
import { computed, markRaw, ref, watchEffect, type Ref } from 'vue'
import ProfileMenuItem from '@/components/Profile/ProfileMenuItem.vue'
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n/index.js'
import { useRouter } from 'vue-router'
import HotIcon from '@/assets/icons/MenuFilters/HotIcon.vue'
import type { AttributesList, Restaurant } from '@/types/restaurants'
import { useToast } from 'primevue/usetoast'
import { useFetch } from '@/composables/useFetch'
import { useEnvStore } from '@/stores/envStore'
import ImagePlaceholderIcon from '@/assets/icons/Profile/ImagePlaceholderIcon.vue'
import ButtonProfile from '@/assets/icons/Buttons/ButtonProfile.vue'
import DiningIcon from '@/assets/icons/Menus/DiningIcon.vue'
import { useUserStore } from '@/stores/useUserStore'
import ButtonOrders from '@/assets/icons/Buttons/ButtonOrders.vue'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const toast = useToast()
const router = useRouter()

const user = ref(useUserStore().user)
const { fetchData } = useFetch()
const fileInput: Ref<HTMLInputElement | null> = ref(null);
const restaurant: Ref<Restaurant | null> = ref(null);
const attributes: Ref<AttributesList | null> = ref(null)
const userStore = useUserStore()
const envStore = useEnvStore();

watchEffect(async () => {
  if (user.value?.restaurants[0].id && !restaurant.value) {
    const { data: dbRestaurant, error: dbRestaurantError } = await fetchData(
      useEnvStore().apiUrl + '/restaurants/' + user.value?.restaurants[0].id,
      'GET'
    )
    if (dbRestaurantError) console.log(dbRestaurantError)
    else restaurant.value = dbRestaurant

    const { data: dbAttributes, error: dbAttributesError } = await fetchData(
      useEnvStore().apiUrl + '/restaurants/attributes',
      'GET'
    )
    if (dbAttributesError) console.log(dbAttributesError)
    else attributes.value = dbAttributes
  }
})

const userInfos = computed(() => {
  if (user.value) {
    return {
      fullName: `${user.value.first_name} ${user.value.last_name}`,
      restaurantName: user.value.restaurants[0].name,
      restaurationType: user.value.restaurants[0].restauration_type,
    }
  }
  return {
    fullName: '',
    restaurantName: '',
    restaurationType: '',
  }
})

async function uploadRestaurantLogo(event: any) {
  const files = event.target.files
  if (files.length > 0) {
    const file: File = files[0]

    // Taille maximale autorisée (en octets) - ici 2 Mo (2048 Ko)
    const maxFileSize = 2 * 1024 * 1024

    // Vérifier la taille de l'image avant de procéder à l'upload
    if (file.size > maxFileSize) {
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('menuDishView.fileTooLarge', { maxSizeMB: '2' }),
        life: 3000,
      })
      return // Empêcher l'upload si le fichier est trop gros
    }

    const formData = new FormData()
    formData.append('logo', file)

    try {
      const { data, error } = await fetchData(
        `${useEnvStore().apiUrl}/restaurants/${restaurant.value?.id}/logo`,
        'POST',
        formData
      )

      // Si une erreur est retournée, vérifiez et affichez un message spécifique ou générique
      if (error) {
        console.log(error)
        const errorMessage =
          error?.details?.errors?.logo?.[0] || t('menuDishView.addUserImageError')

        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: errorMessage,
          life: 3000,
        })
      } else if (data && restaurant.value) {
        // Si l'opération est réussie, mettez à jour le logo du restaurant
        restaurant.value.logo_url = data.logo_url
        restaurant.value.logo = data.logo

        await userStore.refreshUser()
      }
    } catch (error) {
      console.error('An unexpected error occurred:', error)
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('menuDishView.addUserImageError'),
        life: 3000,
      })
    }
  }
}

const links = [
  {
    name: t('profile.profile.profileTab.profileTabLabel'),
    icon: markRaw(ButtonProfile),
    url: '/profile/my-profile',
  },
  {
    name: t('profile.profile.restaurantTab.restaurantTabLabel'),
    icon: markRaw(DiningIcon),
    url: '/profile/my-restaurant',
  },
  {
    name: t('profile.profile.restaurantTab.other.mySuppliers'),
    icon: markRaw(HotIcon),
    url: '/profile/suppliers',
  },
  {
    name: t('profile.profile.restaurantTab.other.orders'),
    icon: markRaw(ButtonOrders),
    url: '/profile/orders',
  }
]

const nonProductionEnvironments = ['local', 'development', 'staging']

if (nonProductionEnvironments.includes(envStore.environment)) {
  links.push({
    name: t('profile.profile.restaurantTab.team'),
    icon: markRaw(ButtonProfile),
    url: '/profile/team',
  })
}
</script>

<template>
  <div>
    <!-- ProfileCard -->
    <div class="flex flex-row items-center gap-4 mb-6 justify-items-start">
      <div class="flex justify-center">
        <!-- Le div contenant le cercle -->
        <div @click="fileInput?.click()"
          class="relative flex items-center justify-center object-cover w-24 h-24 p-1 border-2 rounded-full cursor-pointer aspect-square max-w-24 max-h-24 border-primary-200">
          <!-- Affiche l'image si disponible -->
          <img v-if="restaurant?.logo" :src="restaurant?.logo" alt="Profile picture"
            class="absolute inset-0 object-cover w-full h-full p-1 rounded-full" />

          <!-- Affiche l'icône de placeholder si pas d'image -->
          <ImagePlaceholderIcon v-else class="w-8 h-8 text-primary-950" />
        </div>

        <!-- Input pour télécharger une image -->
        <input ref="fileInput" type="file" name="dish-image" id="dish-image"
          accept="image/jpeg, image/jpg, image/png, image/webp, image.heic" @change="uploadRestaurantLogo"
          class="hidden mb-4" />
      </div>
      <div class="">
        <h1 class="mb-2">{{ userInfos.restaurantName }}</h1>
        <p class="font-titles">{{ userInfos.fullName }}</p>
        <p class="text-sm font-titles">{{ t(`restaurationType.${userInfos.restaurationType}`) }}</p>
      </div>
    </div>

    <!-- Navigation Links -->
    <div class="flex-grow">
      <ProfileMenuItem v-for="(link, index) in links" :key="link.name" :name="link.name" :icon="link.icon"
        :url="link.url" :index="index" @click="router.push({ path: link.url })" />
    </div>
  </div>
</template>