<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import type { Dish, Ingredient } from '@/types/dish.js'
import InputNumber from '@/components/BaseComponents/InputNumber.vue'
import Button from 'primevue/button'
import ButtonMenu from '@/assets/icons/Buttons/ButtonMenu.vue'
import DishImage from '@/components/Menus/DishImage.vue'
import { calculateDishPrice, formatPrice } from '@/utils/prices'
import { computed, onMounted, ref, watch, nextTick } from 'vue'
import { useDishesStore, useRecipeStore } from '@/stores/dishesStore'
import { useFetch } from '@/composables/useFetch'
import Menu from 'primevue/menu'
import { useRoute } from 'vue-router'
import { useEnvStore } from '@/stores/envStore'
import { useRouterStore } from '@/stores/routerStore'
import { useLayoutStore } from '@/stores/layoutStore'
import { useToast } from 'primevue/usetoast'
import router from '@/router'
import LoadingView from '../LoadingView.vue'
import { debounce } from '@/utils/functions'
import { useDishes } from '@/composables/useDishes.js'
import type { DishHistoryState } from '@/types/history.js'
import type { Ref } from 'vue'
import EditIngredient from '@/components/Orders/EditIngredient.vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { transformAndSortIngredients } from '@/utils/dishes'
import ButtonConfirm from '@/assets/icons/Buttons/ButtonConfirm.vue'
import ButtonClose from '@/assets/icons/Buttons/ButtonClose.vue'
import Textarea from 'primevue/textarea'
import Dialog from 'primevue/dialog'
import LoadingBar from '@/components/Skeletons/LoadingBar.vue'
import { useConfirm } from 'primevue/useconfirm'
import { usePlanningStore } from '@/stores/planningStore'
import AddDishToPlanningDialog from '@/components/Planning/AddDishToPlanningDialog.vue'
import HeartFull from '@/assets/icons/2States/HeartFull.vue'
import HeartEmpty from '@/assets/icons/2States/HeartEmpty.vue'
import Chip from 'primevue/chip'
import type { DishTag } from '@/types/api'
import AddTagDialog from '@/components/Menus/AddTagDialog.vue'
import NutritionalValuesCard from '@/components/Menus/MenuDishViewComponents/NutritionalValuesCard.vue'
import { useUserStore } from '@/stores/useUserStore'
import PlaceholderImage from '@/components/BaseComponents/PlaceholderImage.vue'
import ShareCard from '@/assets/icons/Planning/ShareCard.vue'
import ShareMenu from '@/components/Share/ShareMenu.vue'


const { t, te } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const dishesStore = useDishesStore()
const recipeStore = useRecipeStore()
const planningStore = usePlanningStore()

const { fetchData } = useFetch()
const { deleteDish, duplicateDish, toggleFavorite } = useDishes()
const route = useRoute()
const toast = useToast()
const user = ref(useUserStore().user)
// const userStore = useUserStore();
const restaurantId: string = user.value?.restaurants[0].id || ''
const dishImageRef: Ref<InstanceType<typeof DishImage> | null> = ref(null)
const isImageModalVisible = ref(false)
const fileInput: Ref<HTMLInputElement | null> = ref(null)
const dish = ref<Dish | null>(null)
const newDishName = ref('')
const isEditingName = ref(false)
const numberOfPeople = ref()
// Replace dishId ref with a computed property
const dishId = computed(() => dish.value?.id ?? parseInt(route.params.id.toString()))

const hasIaImage = computed(() => {
  return dish.value?.image_url_gastro || dish.value?.image_url_bistro
})

const showDishSkeleton = computed(() => {
  if (!dish.value) {
    return true
  }
  if (dish.value?.only_title) {
    return true
  }
  return false
})
// const aiModel = ref('gpt-4o')
// const temperature = ref(0.8)
const generatingRecipe = ref(false)
const generatingImage = ref(false)
const ingredientToEdit: Ref<Ingredient> = ref({
  id: -1,
  category: 'other',
  name: '',
  quantity: 0,
  unit: '',
  price: 0,
})
const ingredientToEditIndex = ref(-1)
const showSidebar: Ref<boolean> = ref(false)
const confirm = useConfirm()
const showAddToPlanningDialog: Ref<boolean> = ref(false)
const openAddTagToDishDialog: Ref<boolean> = ref(false);

const showNutritionalValue: Ref<boolean> = ref(false);

const menu = ref()
// const favoriteLabel = computed(() =>
//   dish.value?.is_favorite
//     ? t('dishOperations.removeFromFavortite')
//     : t('dishOperations.addToFavorites')
// )
// const favoriteIcon = computed(() => (dish.value?.is_favorite ? 'pi pi-heart-fill' : 'pi pi-heart'))
const items = ref([
  {
    items: [
      {
        label: t('menuDishView.topMenu.addtoWeekMenu'),
        icon: 'pi pi-calendar',
        command: () =>
          addToWeekMenu()
      },
      {
        label: t('dish.nutritional.nutritionalValues'),
        icon: 'pi pi-list-check',
        command: () =>
          showNutritionalValue.value = true
      },
      // {
      //   label: favoriteLabel,
      //   icon: favoriteIcon,
      //   command: () =>
      //     toggleFavorite(dishId.value).then((favoriteResponse) =>
      //       handleFavoriteChange(favoriteResponse)
      //     ),
      // },
      {
        label: t('menuDishView.topMenu.rename'),
        icon: 'pi pi-pencil',
        command: () => {
          newDishName.value = dish.value?.name ?? ''
          isEditingName.value = true
        },
      },
      {
        label: t('menuDishView.topMenu.duplicate'),
        icon: 'pi pi-clone',
        command: () =>
          duplicateDish(dishId.value, 'copy').then((duplicationResponse) =>
            handleDuplication(duplicationResponse)
          ),
      },
      {
        label: t('menuDishView.topMenu.delete'),
        icon: 'pi pi-trash',
        command: (event) => {
          confirm.require({
            target: event.currentTarget,
            message: t('menuDishView.deleteDish.label'),
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: t('menuDishView.deleteDish.cancel'),
            rejectLabel: t('menuDishView.deleteDish.confirm'),
            accept: () => {
            },
            reject: () => {
              deleteDish(dishId.value).then((wasDeleted) => handleDeletion(wasDeleted))
            },
          })
        },
      },
      {
        label: t('menuDishView.topMenu.addToACategory'),
        icon: 'pi pi-tag',
        command: () => addTag(),
      },
      {
        label: t('menuDishView.topMenu.share'),
        icon: 'pi pi-share-alt',
        command: () => handleShare(dish.value?.selected_image !== 'none' ? 'storyMainDish' : 'postMainDish'),
      },
      ...(user.value?.role === 'admin' ? [{
        label: dish.value?.is_template ? t('dish.removeTemplate') : t('dish.setTemplate'),
        icon: 'pi pi-star',
        command: () => {
          useDishes().setDishTemplate(dishId.value, toast)
        }
      }] : []),
      // {
      //   label: t('menuDishView.topMenu.addPersonalPicture'),
      //   icon: 'pi pi-camera',
      //   command: () => fileInput.value?.click(),
      // },
      // {
      //   label: t('menuDishView.topMenu.share'),
      //   icon: 'pi pi-share-alt',
      // },
    ],
  },
])

const placeholderMenu = ref()
const placeholderMenuItems = computed(() => {
  const items = [
    {
      label: t('menuDishView.topMenu.addPersonalPicture'),
      icon: 'pi pi-upload',
      command: () => {
        uploadImage()
      },
    },
    {
      label: hasIaImage.value ? t('menuDishView.seeImage') : t('menuDishView.generateImage'),
      icon: 'pi pi-image',
      command: () => {
        getImage(hasIaImage.value)
      },
    }
  ]

  // Only add remove option if dish has a selected image that is not 'none'
  if (dish.value?.selected_image && dish.value.selected_image !== 'none') {
    items.push({
      label: t('menuDishView.removeImage'),
      icon: 'pi pi-times',
      command: () => {
        dishesStore.changeSelectedImage(dishId.value, 'none')
      },
    })
  }

  return items
})

function togglePlaceholderMenu(event) {
  placeholderMenu.value.toggle(event)
}

function uploadImage() {
  fileInput.value?.click()
}

async function addToWeekMenu() {
  if (!dish.value) return
  planningStore.dishToPlan = {
    clipboardDish: dish.value,
    selectedPlannedMenuIds: [],
    dishType: 'main'
  };

  showAddToPlanningDialog.value = true

  // // Prepare the data to send in the request
  // const payload = {
  //   dish_id: dish.value.id,
  //   date: new Date().toISOString().split('T')[0], // current date in YYYY-MM-DD format
  //   restaurant_id: user.value?.restaurants[0]?.id || null // assuming the user has at least one restaurant
  // }

  // if (!payload.restaurant_id) {
  //   toast.add({
  //     severity: 'error',
  //     summary: t('common.error'),
  //     detail: t('auth.user.noRestaurantConnected'),
  //     life: 3000
  //   })
  //   return
  // }

  // // Make the POST request to plan the dish
  // const { error, data } = await fetchData(`${useEnvStore().apiUrl}/planning/plan`, 'POST', payload)

  // if (error) {
  //   toast.add({
  //     severity: 'error',
  //     summary: t('common.error'),
  //     detail: t('menuDishView.addToWeekMenuError'),
  //     life: 3000
  //   })
  // } else {
  //   toast.add({
  //     severity: 'success',
  //     summary: t('common.success'),
  //     detail: t('menuDishView.addedToWeekMenu'),
  //     life: 3000
  //   })
  // }
}

function handleDeletion(wasDeleted: boolean) {
  if (wasDeleted) {
    toast.add({
      severity: 'success',
      summary: t('common.success'),
      detail: t('dishOperations.dishDeletionSuccess'),
      life: 3000,
    })

    const stateNames = ['historyState', 'myScansState', 'favoriteState']
    stateNames.forEach((stateName) => {
      const savedState = sessionStorage.getItem(stateName)
      if (savedState) {
        let state: DishHistoryState = JSON.parse(savedState)
        state.dishes = state.dishes.filter((stateDish) => stateDish.id !== dishId.value)
        sessionStorage.setItem(stateName, JSON.stringify(state))
      }
    })

    dishesStore.dishes = dishesStore.dishes.filter((storedDish) => {
      storedDish.id === dishId.value
    })

    if (
      useRouterStore().previousPath === '/profile/my-scans' ||
      useRouterStore().previousPath === '/profile/favorites' ||
      useRouterStore().previousPath === '/profile/generation-history' ||
      useRouterStore().previousPath === '/menu/results'
    ) {
      router.back()
    } else {
      router.push({ path: '/' })
    }
  } else {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('dishOperations.dishDeletionError'),
      life: 3000,
    })
  }
}

function handleDuplication(duplicationResponse: null | Dish) {
  if (duplicationResponse === null) {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('dishOperations.dishDuplicationError'),
      life: 3000,
    })
  } else {
    const originalDishId = dishId.value
    dish.value = duplicationResponse
    dishesStore.selectedDish = duplicationResponse
    recipeStore.recipe = null

    const stateNames = ['historyState', 'myScansState', 'favoriteState']
    stateNames.forEach((stateName) => {
      const savedState = sessionStorage.getItem(stateName)
      if (savedState) {
        let state: DishHistoryState = JSON.parse(savedState)
        console.log(state)
        const stateDishIndex = state.dishes.findIndex(
          (stateDish) => stateDish.id === originalDishId
        )
        state.dishes.splice(stateDishIndex, 0, duplicationResponse)
        sessionStorage.setItem(stateName, JSON.stringify(state))
      }
    })

    router.replace('/menu/dishes/' + duplicationResponse.id)
  }
}

function handleFavoriteChange(favoriteResponse: boolean | null) {
  if (favoriteResponse === null) {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('dishOperations.dishFavoriteError'),
      life: 3000,
    })
  } else {
    dish.value!.is_favorite = favoriteResponse

    const stateNames = ['historyState', 'myScansState', 'favoriteState']
    stateNames.forEach((stateName) => {
      const savedState = sessionStorage.getItem(stateName)
      if (savedState) {
        let state: DishHistoryState = JSON.parse(savedState)
        console.log(state)
        state.dishes.find((dish) => dish.id === dishId.value)!.is_favorite = favoriteResponse
        sessionStorage.setItem(stateName, JSON.stringify(state))
      }
    })

    // toast.add({
    //   severity: 'success',
    //   summary: t('common.success'),
    //   detail: favoriteResponse
    //     ? t('dishOperations.dishFavoriteAdded')
    //     : t('dishOperations.dishFavoriteRemoved'),
    //   life: 3000,
    // })
  }
}

const toggle = (event: any) => {
  menu.value.toggle(event)
}

onMounted(async () => {
  console.log(route.params.id)
  if (!user.value) return;

  if (!dishesStore.selectedDish || dishesStore.selectedDish.id.toString() !== route.params.id) {
    const { data, error } = await fetchData(`${useEnvStore().apiUrl}/dishes/${dishId.value}`)
    if (error) {
      console.error(error)
      router.back()
    } else {
      dish.value = data as Dish
      dishesStore.selectedDish = dish.value
      console.log(data)
      numberOfPeople.value = dish.value.servings
    }
  } else {
    dish.value = dishesStore.selectedDish
    numberOfPeople.value = dish.value.servings
  }
})

async function getRecipe(hasRecipe: boolean, event) {
  console.log('hasRecipe', hasRecipe)

  if (!hasRecipe) {
    confirmIngredientsBeforeGeneratingRecipe(event)
  } else if (dish.value?.needs_recipe_update && dish.value?.created_using !== 'ocr') {
    confirmSeeOrGenerateNewRecipe(event)
  } else {
    showRecipe(dishId.value)
  }
}


const confirmIngredientsBeforeGeneratingRecipe = (event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t('menuDishView.recipe.confirmallIngredientsAddedButton.label'),
    icon: 'pi pi-exclamation-triangle',
    acceptLabel: t('menuDishView.recipe.confirmallIngredientsAddedButton.confirm'),
    rejectLabel: t('menuDishView.recipe.confirmallIngredientsAddedButton.cancel'),
    accept: () => {
      if (!user.value || user.value.restaurants.length === 0) {
        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: t('auth.user.noRestaurantConnected'),
          life: 3000,
        })
        return
      }
      createRecipe()
    },
    reject: () => {
      // removeDishIngredients(dishId.value, restaurantId)
      // removeFromShoppingList()
    },
  })
}

const confirmSeeOrGenerateNewRecipe = (event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t('menuDishView.recipe.seeOrUpdateButton.label'),
    icon: 'pi pi-exclamation-triangle',
    acceptLabel: t('menuDishView.recipe.seeOrUpdateButton.see'),
    rejectLabel: t('menuDishView.recipe.seeOrUpdateButton.regenerate'),
    accept: () => {
      dishesStore.confirmSeeRecipe(dishId.value)
      showRecipe(dishId.value)
    },
    reject: () => {
      if (!user.value || user.value.restaurants.length === 0) {
        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: t('auth.user.noRestaurantConnected'),
          life: 3000,
        })
        return
      }
      createRecipe()
      // removeDishIngredients(dishId.value, restaurantId)
      // removeFromShoppingList()
    },
  })
}

async function getImage(hasIaImage) {
  console.log('image ')
  if (!hasIaImage) {
    if (!dish.value || !user.value || !dishesStore.selectedDish) {
      console.log('Required data not available.')
      return
    }
    let theDishId = Number(dishId.value)
    if (!useUserStore().validateUserCanGenerate()) {
      return null;
    }
    dishesStore.generateImages(theDishId, toast)
    router.push({ path: `/menu/dishes/${theDishId}/images` })
  } else {
    router.push({ path: `/menu/dishes/${dishId.value}/images` })
  }
}

async function showRecipe(dishId: number) {
  console.log('dishId ' + dishId)
  console.log('recipestore ' + recipeStore.recipe)
  if (recipeStore.recipe?.dish_id !== dishId || !recipeStore.recipe) {
    generatingRecipe.value = true
    useLayoutStore().pageMeta.headerConfig.showBack = false
    useLayoutStore().pageMeta.headerConfig.showProfile = false
    const { data, error } = await fetchData(
      useEnvStore().apiUrl + `/dishes/${dishId}/recipe`,
      'GET'
    )
    if (error) {
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('menuRecipeSteps.gettingRecipeError'),
        life: 3000,
      })
      console.log('error', error)
    } else {
      recipeStore.recipe = data
      console.log('data', data)
      router.push({ path: `/menu/dishes/${dishId}/recipe-steps` })
      generatingRecipe.value = false
    }
  } else {
    router.push({ path: `/menu/dishes/${dishId}/recipe-steps` })
  }
}

async function createRecipe(aiModel = 'gpt-4o', temperature = 0.6) {
  if (!useUserStore().validateUserCanGenerate()) {
    return null;
  }
  generatingRecipe.value = true
  console.log('Params : ', aiModel, temperature)
  useLayoutStore().pageMeta.headerConfig.showBack = false
  useLayoutStore().pageMeta.headerConfig.showProfile = false
  const { data, error } = await fetchData(
    useEnvStore().apiUrl +
    `/generate/recipe/${dishId.value}?aiModel=${aiModel}&temperature=${temperature}`,
    'GET'
  )

  if (error) {
    if (error.code === 'INSUFFICIENT_CREDITS') {
      toast.add({
        severity: 'error',
        summary: t('credits.noMoreCredit.title'),
        detail: t('credits.noMoreCredit.message'),
        life: 3000,
      })
    } else {
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('creationFilters.generationError'),
        life: 3000,
      })
    }
    console.log('error', error)
    generatingRecipe.value = false
    return
  }

  const date = Date.now()
  recipeStore.recipe = data.response
  if (dish.value) {
    dish.value.hasRecipe = true
    dish.value.needs_recipe_update = false
    dish.value.chill_time_min = recipeStore.recipe?.chill_time || 0
    dish.value.cook_time_min = recipeStore.recipe?.cook_time || 0
    dish.value.prep_time_min = recipeStore.recipe?.prep_time || 0
  }
  recipeStore.infoTotal = {
    date: date,
    temperature: data.temperature,
    ...data.response,
    usage: data.usage,
    status: data.status,
    ai_model: data.ai_model,
    duration: data.duration,
    instructions: data.instructions,
    thread_id: data.thread_id,
  }
  console.log('API URL  : ', `/menu/dishes/${dishId.value}/recipe-steps`)
  // console.log('My store : ', useDishesStore().dishes)

  toast.add({
    severity: 'success',
    summary: t('common.success'),
    detail: t('creationFilters.generationSuccess'),
    life: 3000,
  })
  console.log('data', data)
  router.push({ path: `/menu/dishes/${dishId.value}/recipe-steps` })
  generatingRecipe.value = false
}

async function updateServings(servings: number) {
  try {
    const theDishId = dishId.value
    const { error } = await fetchData(
      useEnvStore().apiUrl + `/dishes/${theDishId}/servings`,
      'PUT',
      { servings: servings }
    )

    if (error) {
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('menuDishView.servings.errorUpdating'),
        life: 3000,
      })
      console.log('error', error)
    } else {
      // console.log('Servings updated successfully')
      if (dish.value) dish.value.servings = servings
    }
  } catch (error) {
    console.error('Error updating servings:', error)
  }
}

const debouncedUpdateServings = debounce((value: number) => {
  updateServings(value)
}, 400)

const hasImage = computed(() => {
  return dish.value?.image_url_gastro || dish.value?.image_url_bistro || dish.value?.image_url_user
})


async function uploadUserImage(event: Event) {
  const files = (event.target as HTMLInputElement).files
  if (files && files.length > 0 && dish.value) {
    await dishesStore.uploadUserImage(dish.value.id, files[0], toast)
    // Optionally refresh the component's image if needed
    if (dishImageRef.value) {
      dishImageRef.value.getImage()
    }
  }
}

watch(numberOfPeople, (newVal) => {
  if (newVal && newVal !== 0) debouncedUpdateServings(newVal)
})

function addOrUpdateIngredient(ingredient: Ingredient | null) {
  if (ingredient) {
    const adjustedIngredient = listIngredients.value.find((ing) => ing.id === ingredient.id)
    ingredientToEdit.value = adjustedIngredient ? { ...adjustedIngredient } : { ...ingredient }
  } else {
    ingredientToEdit.value = { id: -1, category: '', name: '', quantity: 0, unit: '', price: 0 }
    ingredientToEditIndex.value = -1
  }
  showSidebar.value = true
}

watch(
  () => dishesStore.selectedDish,
  (newSelectedDish) => {
    dish.value = newSelectedDish
  },
  { deep: true }
)

function addIngredient() {
  if (ingredientToEdit.value.name) {
    dishesStore.addIngredients(dishId.value, [ingredientToEdit.value])
    showSidebar.value = false // Close the sidebar after adding
  } else {
    console.error('Ingredient data is incomplete.')
  }
}

function updateIngredient() {
  if (ingredientToEdit.value.id !== -1 && ingredientToEdit.value.name) {
    dishesStore.updateIngredients(dishId.value, [ingredientToEdit.value])
    showSidebar.value = false // Close the sidebar after editing
    console.log('NEW DISH', dish.value)
  } else {
    console.error('Ingredient data is incomplete or invalid ID.')
  }
}

function deleteIngredients() {
  const ingredientId = ingredientToEdit.value.id
  if (ingredientId !== -1) {
    dishesStore.deleteIngredients(dishId.value, [ingredientId])
    showSidebar.value = false // Close the sidebar after deleting
  } else {
    console.error('Invalid ingredient ID for deletion.')
  }
}

const listIngredients = computed(() => {
  if (!dish.value) return []
  return transformAndSortIngredients(dish.value.ingredients, numberOfPeople.value)
})

async function editName() {
  if (!dish.value) return
  // Limit dishName to 149 characters
  if (newDishName.value.length > 149) {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('menuDishView.dishNameTooLong'),
      life: 3000,
    })
    return
  }
  const { error } = await fetchData(useEnvStore().apiUrl + '/dishes/' + dishId.value, 'PATCH', {
    name: newDishName.value,
  })
  console.log("Error ? ", error)

  if (error) {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('menuDishView.updateNameError'),
      life: 3000,
    })
  } else {
    dish.value.name = newDishName.value
    console.log("DISH VALUE NAME : ", dish.value.name)
    if (dishesStore.selectedDish !== null) {
      dishesStore.selectedDish.name = newDishName.value
    }
    const index = dishesStore.dishes.findIndex((dish) => dish.id === dishId.value)
    console.log(" DISHES STORE", dishesStore.dishes)
    console.log("DISH ID : ", dishId.value)
    console.log("INDEX : ", index)
    if (index !== -1) dishesStore.dishes[index].name = newDishName.value


    const stateNames = ['historyState', 'myScansState', 'favoriteState']
    stateNames.forEach((stateName) => {
      const savedState = sessionStorage.getItem(stateName)
      if (savedState) {
        let state: DishHistoryState = JSON.parse(savedState)
        console.log("STATE : ", state)
        const foundDish = state.dishes.find((dish) => dish.id === dishId.value);
        if (foundDish && dish.value) {
          foundDish.name = dish.value.name;
        }
        sessionStorage.setItem(stateName, JSON.stringify(state))
      }
    })
    // toast.add({
    //   severity: 'success',
    //   summary: t('common.success'),
    //   detail: t('menuDishView.updateNameSucces'),
    //   life: 3000,
    // })
    isEditingName.value = false

    // Update the dish in the planningStore
    if (dish.value) {
      planningStore.updateDishInPlannedMenus(dish.value);
    }
  }
}

const showAllDates = ref(false)

const displayedPlannedDishes = computed(() => {
  if (showAllDates.value) {
    return dish.value?.isPlanned || []
  } else {
    return dish.value?.isPlanned?.slice(0, 3) || []
  }
})

// const formatDate = (dateString) => {
//   return new Date(dateString).toLocaleString('fr-CH', { dateStyle: 'short' })
// }

async function removeTag(tag: DishTag, removeCallback) {
  if (!tag || !dish.value) return

  try {
    const payload = {
      dishId: dish.value.id,
    }

    const response = await fetchData(`${useEnvStore().apiUrl}/dishes/tags/${restaurantId}/${tag.id}`, 'DELETE', payload);

    if (response.data) {
      // myDishTags.value = [...myDishTags.value, ...response.data.tags];
      if (dish.value.tags) {
        dish.value.tags = dish.value.tags.filter(t => t.id !== tag.id);
      }
      // toast.add({
      //   severity: 'success',
      //   summary: t('common.success'),
      //   detail: t('menuDishView.tag.tagRemovedSuccessfully'),
      //   life: 3000,
      // });
      removeCallback();
    } else if (response.error) {
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: response.error,
        life: 3000,
      });
    }
  } catch (error) {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('menuDishView.tag.errorRemovingTag'),
      life: 3000,
    });
  }
}

async function addTag() {
  openAddTagToDishDialog.value = true;
}

async function updateTagsOnDish(newTagSelection: DishTag[]) {
  if (!dish.value) return

  // Vérifiez s'il y a une différence entre la sélection actuelle et la nouvelle sélection
  const existingTags = dish.value.tags ?? []

  const isDifferent =
    existingTags.length !== newTagSelection.length ||
    existingTags.some(existingTag => !newTagSelection.some(newTag => newTag.id === existingTag.id))

  // If there's no change, don't do anything.
  if (!isDifferent) {
    // console.log("Pas de changement détecté dans les tags. Aucune action nécessaire.")
    return
  }

  try {
    const dishId = dish.value.id;
    const tagsId = newTagSelection.map(tag => tag.id); // envoyer les IDs des tags sélectionnés

    dishesStore.updateDishTags(dishId, tagsId, toast);

  } catch (e) {
    console.error('Erreur lors de la mise à jour des tags:', e)
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('menuDishView.tag.errorUpdatingTags'),
      life: 3000,
    })
  }
}

// Add this computed property to format tags with parent-child relationship
const formattedTags = computed(() => {
  if (!dish.value?.tags) return [];
  return dish.value.tags.map(tag => {
    if (tag.parent_dish_tag_id && dishesStore.tags.length > 0) {
      // get parent dish tag from the dishesStore.tags
      const parentTag = dishesStore.tags.find(t => t.id === tag.parent_dish_tag_id) || { name: '' };
      console.log(parentTag)
      return {
        ...tag,
        displayName: `${parentTag.name} ${tag.name === 'uncategorized' ? '' : ' | ' + tag.name}`
      };
    }
    return {
      ...tag,
      displayName: tag.name
    };
  });
});


/////// SHARE MENU ///////

const shareModalVisible = ref(false)
const formattedUrl = (url) => {
  return url.replace(/^https?:\/\//, '')
}

const dishImage = ref('');
const imageDataUrl = ref('') // Reactive property to hold the image data URL

const fetchDishImage = async () => {
  if (dish.value && dish.value.id && dish.value.selected_image !== 'none') {
    const { data, error } = await fetchData(
      `${useEnvStore().apiUrl}/dishes/${dish.value.id}/images/${dish.value.selected_image}`
    )

    if (error) {
      console.error('Error fetching image:', error)
      // Handle error (e.g., set a default image or show an error message)
    } else if (data && data.image) {
      dishImage.value = data.image
    }
  }
}
// NEW: reactive state for share
const shareData = ref({
  title: '',
  subtitle: '',
  items: [] as { order: number; name: string }[],
  imageSrc: '',
  price: '',
});
const loadingShare = ref(false);
const selectedShareFormat = ref<string | null>(null);

// NEW: computed formattedPrice using dish data
const formattedPrice = computed(() => {
  if (dish.value) {
    const symbol = dish.value.country?.currency_symbol || ''
    return `${symbol} ${formatPrice(calculateDishPrice(dish.value, 1))}`;
  }
  return '';
});

// NEW: prepareShareData for a dish
async function prepareShareData(format: string) {
  // let imageSrc = '';
  if (dish.value?.selected_image && dish.value.selected_image !== 'none') {
    await fetchDishImage();
  }
  return {
    title: '',
    subtitle: '', // no subtitle for dish
    items: [{ order: 1, name: dish.value?.name || '' }],
    imageSrc: dishImage.value,
    price: '',
  };
}

function prepareNutritionalData() {
  return {
    calories_kcal: dish.value?.calories_kcal || 0,
    protein_g: dish.value?.protein_g || 0,
    fat_g: dish.value?.fat_g || 0,
    saturated_fat_g: dish.value?.saturated_fat_g || 0,
    carbohydrates_g: dish.value?.carbohydrates_g || 0,
    sugars_g: dish.value?.sugars_g || 0,
    dietary_fiber_g: dish.value?.dietary_fiber_g || 0,
    salt_sodium_g: dish.value?.salt_sodium_g || 0,
  };
}

const nutritionalData = ref(prepareNutritionalData());
// NEW: handleShare function that prepares shareData and sets the share type
async function handleShare(format: string) {
  shareData.value = await prepareShareData(format);
  nutritionalData.value = prepareNutritionalData();
  loadingShare.value = true;
  selectedShareFormat.value = format;
}

// NEW: onImageGenerated callback from the ShareMenu component
function onImageGenerated(image: string) {
  dishImage.value = image;
  imageDataUrl.value = image;
  shareModalVisible.value = true;
  loadingShare.value = false;
  selectedShareFormat.value = null;
}
</script>

<template>
  <main class="relative flex flex-col gap-6">
    <Dialog v-if="dish" v-model:visible="isImageModalVisible" modal style="max-width: 80%" class="sm:max-w-3xl">
      <DishImage :dishId="dish.id" :type="dish?.selected_image ?? undefined" :alt="dish.name" size="full" />
    </Dialog>
    <div v-show="!generatingRecipe && !generatingImage">
      <div class="my-4">
        <div v-if="!isEditingName" class="flex justify-between gap-6 items-top">
          <h3 v-if="dish">
            {{ dish?.only_title ? t('menuDishView.generatingDish') : dish.name }}
          </h3>
          <h3 v-else>
            {{ t('common.loading') }}
          </h3>
          <div class="flex flex-row gap-2">
            <Button v-if="dish" link rounded :pt="{ root: { class: '!p-1.5 !w-8 !h-8' } }"
              :pt-options="{ mergeProps: true }" @click="
                toggleFavorite(dishId).then((favoriteResponse) =>
                  handleFavoriteChange(favoriteResponse)
                )">
              <template #icon>
                <HeartFull v-if="dish.is_favorite" />
                <HeartEmpty v-else />
              </template>
            </Button>
            <Button link rounded :pt="{ root: { class: '!p-1.5 !w-8 !h-8' } }" :pt-options="{ mergeProps: true }"
              @click="toggle" :disabled="!dish">
              <template #icon>
                <ButtonMenu />
              </template>
            </Button>
          </div>

        </div>
        <div v-else class="flex justify-between gap-2 items-top">
          <Textarea class="w-full" row="2" v-model="newDishName" autoResize maxlength="149" />
          <Button link rounded :pt="{ root: { class: '!p-1.5 !w-8 !h-8' } }" :pt-options="{ mergeProps: true }"
            @click="isEditingName = false">
            <template #icon>
              <ButtonClose />
            </template>
          </Button>
          <Button link rounded :pt="{ root: { class: '!py-1.5 !px-0 !w-12 !h-8' } }" :pt-options="{ mergeProps: true }"
            @click="editName">
            <template #icon>
              <ButtonConfirm />
            </template>
          </Button>
        </div>
        <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" :pt="{ submenuHeader: { class: '!p-0' } }"
          :pt-options="{ mergeProps: true }" />
        <input ref="fileInput" type="file" name="dish-image" id="dish-image"
          accept="image/jpeg, image/jpg, image/png, image/webp, image/heic" @change="uploadUserImage"
          class="hidden mb-4" />
        <div class="flex flex-wrap items-center gap-2 my-3" v-if="dish && dish.tags && dish.tags.length > 0">
          <!-- <p class="font-bold text-primary-950">{{ t('dishTag.label') }} : </p> -->
          <Chip v-for="tag in formattedTags" :key="tag.id" :label="tag.displayName" removable>
            <template #default>
              <div class="leading-4 my-1.5 mx-0 text-xs">{{ tag.displayName }}</div>
            </template>
            <template #removeicon="{ removeCallback }">
              <i class="pi pi-times-circle" @click="removeTag(tag, removeCallback)" style="cursor: pointer;" />
            </template>
          </Chip>
        </div>
      </div>
      <div class="flex justify-center mx-auto my-5 cursor-pointer w-60 h-60" v-if="dish" @click="togglePlaceholderMenu">
        <DishImage v-if="hasImage && dish.selected_image !== 'none'" :dishId="dish.id" :type="dish.selected_image"
          :alt="dish.name" ref="dishImageRef" />
        <PlaceholderImage v-else />
        <Menu ref="placeholderMenu" :model="placeholderMenuItems" :popup="true" />
      </div>
      <!-- <div v-if="dish && hasImage" class="flex justify-center my-5">
        <DishImage :dishId="dish.id" :type="dish.selected_image" :alt="dish.name" ref="dishImageRef"
          @click="isImageModalVisible = true" />
      </div> -->
      <div v-if="dish && dish.isPlanned" class="mb-6">
        <h3 class="mb-2">{{ t('menuDishView.planning.plannedOn') }} :</h3>
        <ul class="flex flex-col gap-3">
          <li v-for="(plannedDish, index) in displayedPlannedDishes" :key="index"
            @click="router.push({ path: `/planning/planned-menu/${plannedDish.planned_menu_id}` })"
            class="cursor-pointer">
            <i class="pi pi-arrow-up-right"></i> {{ new Date(plannedDish.date).toLocaleString('fr-CH', {
            dateStyle: 'short',
            }) }} - {{ plannedDish.menu_name }}
          </li>
        </ul>
        <div class="mt-3">
          <p v-if="!showAllDates && dish.isPlanned.length > 3" @click="showAllDates = true" class="mb-10 inline-button">
            {{ t('menuDishView.planning.showMoreDates', {
            count: dish.isPlanned.length - 3, s: dish.isPlanned.length - 3
            > 1 ? 's' : ''
            }) }}
          </p>
          <p v-if="showAllDates" @click="showAllDates = false" class="mb-10 inline-button">
            {{ t('menuDishView.planning.showLessDates') }}
          </p>
        </div>
        <!-- <ul class="flex flex-col gap-3">
          <li v-for="(plannedDish, index) in dish.isPlanned" :key="index"
            @click="router.push({ path: `/planning/planned-menu/${plannedDish.planned_menu_id}` })"
            class="cursor-pointer">
            <i class="pi pi-arrow-up-right"></i> {{ new Date(plannedDish.date).toLocaleString('fr-CH', {
            dateStyle: 'short',
            }) }} - {{ plannedDish.menu_name }}
          </li>
        </ul> -->
      </div>
      <div class="flex flex-col gap-3">
        <div class="flex flex-col justify-between gap-1">
          <label class="text-sm font-semibold text-surface-700" for="numberOfPeopleInput">
            {{ t('menuDishView.numberOfPeople') }}
          </label>
          <InputNumber v-model="numberOfPeople" inputId="numberOfPeopleInput" showButtons buttonLayout="horizontal"
            :step="1" :min="1" :suffix="` ${t('menuDishView.numberOfPeopleSuffix', { count: numberOfPeople })}`"
            :disabled="showDishSkeleton" />
        </div>

        <DataTable :value="listIngredients" size="small" @row-click="(evt) => addOrUpdateIngredient(evt.data)">
          <Column field="quantity" :header="t('profileFavorites.creationForm.quantityShort')">
            <template #body="slotProps">
              {{ slotProps.data.quantity > 0 ? slotProps.data.quantity : '' }}
            </template>
          </Column>
          <Column field="unit" :header="t('profileFavorites.creationForm.unit')">
            <template #body="slotProps">
              {{ te(`ingredients.unit.symbol.${slotProps.data.unit}`) ?
              t(`ingredients.unit.symbol.${slotProps.data.unit}`) : '' }}
            </template>
          </Column>
          <Column field="name" :header="t('profileFavorites.creationForm.name')" />
          <Column field="price" :header="t('profileFavorites.creationForm.price')" />
        </DataTable>
        <div class="hover:cursor-pointer inline-button" @click="addOrUpdateIngredient(null)">
          <p class="inline-button">+ {{ t('profileFavorites.creationForm.addIngredientButton') }}</p>
        </div>
        <div class="flex flex-col items-end gap-4">
          <!-- <p class="font-bold">~ CHF {{ dish ? calculateDishPrice(dish, numberOfPeople) : 0 }}</p> -->
          <div class="flex justify-between w-full gap-4">
            <p>
              <span>~ {{ dish?.country?.currency_symbol }} {{ dish ? calculateDishPrice(dish, 1) : 0 }} /
                {{ t('menuDishView.nbPersonShort') }}</span>
            </p>
            <p>
              <span class="font-bold">~ {{ dish?.country?.currency_symbol }} {{ dish ? calculateDishPrice(dish,
                numberOfPeople) : 0
                }}</span>
            </p>
          </div>
          <!-- <Button class="col-span-1" :disabled="!dish || showDishSkeleton" @click="
            router.push({
              path: `/menu/dishes/${dishId}/add-to-orders`,
              query: { nbPeople: numberOfPeople },
            })
            ">
            <ButtonWithIconWrapper>
              <template #icon>
                <ButtonOrders />
              </template>
              <template #label>
                {{
                dish?.hasIngredientsInShoppingList
                ? t('menuDishView.editIngredientsList')
                : t('menuDishView.addToIngredientsList')
                }}
              </template>
            </ButtonWithIconWrapper>
          </Button> -->
          <Button type="button" :label="dish?.hasIngredientsInShoppingList
            ? t('menuDishView.editIngredientsList')
            : t('menuDishView.addToIngredientsList')" icon="pi pi-shopping-cart" class="col-span-1"
            :disabled="!dish || showDishSkeleton" @click="
              router.push({
                path: `/menu/dishes/${dishId}/add-to-orders`,
                query: { nbPeople: numberOfPeople },
              })
              " />
        </div>
      </div>
      <div class="grid grid-cols-2 gap-2 mt-6 mb-4">
        <!-- <Button class="col-span-2" size="large" :disabled="!dish || showDishSkeleton" @click="addToWeekMenu">
          <ButtonWithIconWrapper>
            <template #icon>
              <NavPlanningIcon />
            </template>
            <template #label> {{ t('menuDishView.addtoWeekMenu') }} </template>
          </ButtonWithIconWrapper>
        </Button> -->
        <!-- <Button type="button" :label="t('menuDishView.addtoWeekMenu')" icon="pi pi-calendar" class="col-span-2"
          size="large" :disabled="!dish || showDishSkeleton" @click="addToWeekMenu" /> -->
        <!-- <Button :disabled="!dish || showDishSkeleton" class="col-span-1" severity="secondary"
          @click="getImage(hasIaImage)">
          <ButtonWithIconWrapper>
            <template #icon>
              <ButtonImage />
            </template>
            <template #label>
              {{ hasIaImage ? t('menuDishView.seeImage') : t('menuDishView.generateImage') }}
            </template>
          </ButtonWithIconWrapper>
        </Button> -->
        <!-- <Button type="button" :label="hasIaImage ? t('menuDishView.seeImage') : t('menuDishView.generateImage')"
          icon="pi pi-image" :disabled="!dish || showDishSkeleton" class="col-span-1" severity="secondary"
          @click="getImage(hasIaImage)" /> -->
        <Button type="button" :label="dish?.hasRecipe ? t('menuDishView.seeRecipe') : t('menuDishView.generateRecipe')"
          icon="pi pi-list" :disabled="!dish || showDishSkeleton" class="col-span-1"
          @click="getRecipe(dish?.hasRecipe ?? false, $event)" />
        <Button type="button" :label="t('menuDishView.addtoWeekMenu')" icon="pi pi-calendar"
          :disabled="!dish || showDishSkeleton" class="col-span-1" @click="addToWeekMenu" />
        <!-- <Button :disabled="!dish || showDishSkeleton" class="col-span-1" severity="secondary"
          @click="getRecipe(dish?.hasRecipe ?? false, $event)">
          <ButtonWithIconWrapper>
            <template #icon>
              <ButtonList />
            </template>
            <template #label>
              <div class="border-black border-3">
                {{ dish?.hasRecipe ? t('menuDishView.seeRecipe') : t('menuDishView.generateRecipe') }}
              </div>
            </template>
          </ButtonWithIconWrapper>
        </Button> -->

      </div>
    </div>
    <div v-if="generatingRecipe">
      <LoadingView :message="t('menuDishView.loadingRecipeLabel')" :generation="true" />
      <LoadingBar class="mt-20 mb-3" :durationInSeconds="25" />
    </div>
    <div v-if="generatingImage">
      <LoadingView :message="t('menuDishView.loadingImageLabel')" :generation="true" />
    </div>
    <EditIngredient v-model:ingredient="ingredientToEdit" :index="ingredientToEditIndex" v-model:visible="showSidebar"
      @add-ingredient="addIngredient" @edit-ingredient="updateIngredient" @delete-ingredient="deleteIngredients"
      showPrice />

    <AddDishToPlanningDialog v-model:visible="showAddToPlanningDialog" :dish="dish"
      @close-sidebar="showAddToPlanningDialog = false" />
    <AddTagDialog v-model:visible="openAddTagToDishDialog" @update-selection="updateTagsOnDish"
      :selected-tags="dish.tags ?? []" v-if="dish" />
    <Dialog v-model:visible="showNutritionalValue" modal :header="t('dish.nutritional.nutritionalValues')"
      :style="{ width: '25rem' }">
      <NutritionalValuesCard :dish="dish" />
    </Dialog>
    <Dialog v-if="dish" :header="t('socialNetworks.share')" v-model:visible="shareModalVisible" modal
      style="max-width: 90%" class="sm:max-w-3xl">
      <ShareCard :image="imageDataUrl" />
    </Dialog>
    <ShareMenu v-if="selectedShareFormat" :share-data="shareData" :nutritional-data="nutritionalData" :format="selectedShareFormat" @image-generated="onImageGenerated" />
  </main>
</template>
