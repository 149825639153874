<script setup lang="ts">
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'primevue/usetoast'
import { useUserStore } from '@/stores/useUserStore'
import { useFetch } from '@/composables/useFetch'
import { useManager } from '@/composables/useManager'
import { useEnvStore } from '@/stores/envStore'
import { PERMISSIONS, type PermissionCategory } from '@/constants/permissions'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import MultiSelect from 'primevue/multiselect'
import Dialog from 'primevue/dialog'
import { useRouter } from 'vue-router'

const { t } = useI18n()
const toast = useToast()
const userStore = useUserStore()
const envStore = useEnvStore()
const { fetchData } = useFetch()
const showDialog = ref(false)
const registrationUrl = ref('')
const router  = useRouter();

const employeeForm = ref({
  first_name: '',
  last_name: '',
  email: '',
  role: 'employee', // default role
  permissions: [] as string[],
})

const errors = ref({
  first_name: '',
  last_name: '',
  email: '',
  role: '',
})

const isLoading = ref(false)

// Role options for the dropdown
const roleOptions = [
  { label: t('employee.roles.employee'), value: 'employee' },
  { label: t('employee.roles.manager'), value: 'manager' },
]

// Available permissions based on PERMISSIONS constant
const availablePermissions = computed(() => {
  const allPermissions: string[] = []
  Object.keys(PERMISSIONS).forEach((category: string) => {
    const perms = PERMISSIONS[category as PermissionCategory]
    allPermissions.push(...perms)
  })
  return allPermissions.map(perm => ({ label: perm, value: perm }))
})

const validateForm = () => {
  let isValid = true
  errors.value = {
    first_name: '',
    last_name: '',
    email: '',
    role: '',
  }

  if (!employeeForm.value.first_name) {
    errors.value.first_name = t('auth.user.form.error.required')
    isValid = false
  }

  if (!employeeForm.value.last_name) {
    errors.value.last_name = t('auth.user.form.error.required')
    isValid = false
  }

  if (!employeeForm.value.email) {
    errors.value.email = t('auth.user.form.error.required')
    isValid = false
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(employeeForm.value.email)) {
    errors.value.email = t('auth.user.form.error.invalid_email')
    isValid = false
  }

  return isValid
}

const handleSubmit = async () => {
  if (!validateForm()) return
  if (!userStore.user?.restaurants[0].id) {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('employee.errors.restaurantRequired'),
      life: 3000,
    })
    return
  }

  isLoading.value = true
  try {
    const { data, error } = await fetchData(
      `${envStore.apiUrl}/manager/${userStore.user.active_restaurant?.restaurant_user_id}/employees/create`,
      'POST',
      {
        ...employeeForm.value,
        restaurant_id: userStore.user.restaurants[0].id,
      }
    )

    if (error) {
      console.log("Error creating employee:", error)
      // Check if we have a structured error response with email validation errors
      if (error?.details?.errors) {
        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: error.details.message,
          life: 3000,
        })
        // Set the email field error for form validation
        if (error?.details?.errors?.email) {
          errors.value.email = error.details.errors.email[0]
        }
      } else {
        // Generic error message for other types of errors
        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: t('employee.errors.creationError'),
          life: 3000,
        })
      }
      return
    }

    toast.add({
      severity: 'success',
      summary: t('common.success'),
      detail: t('employee.success.created'),
      life: 3000,
    })

    if (data?.registration_url) {
      registrationUrl.value = data.registration_url
      showDialog.value = true
    }

    // Reset form
    employeeForm.value = {
      first_name: '',
      last_name: '',
      email: '',
      role: 'employee',
      permissions: [],
    }
  } catch (err) {
    console.error('Error creating employee:', err)
  } finally {
    isLoading.value = false
  }
}

const copyInvitationLink = () => {
  navigator.clipboard.writeText(registrationUrl.value)
    .then(() => {
      toast.add({
        severity: 'info',
        summary: t('common.success'),
        detail: t('employee.invitationLink.copied'),
        life: 3000,
      })
    })
    .catch(err => {
      console.error('Could not copy text: ', err)
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('employee.errors.copyFailed'),
        life: 3000,
      })
    })
}

const returnToTeam = () => {
  showDialog.value = false
  router.go(-1)
}
</script>

<template>
  <main class="p-4">
    <h1 class="mb-6 text-2xl font-bold">{{ t('employee.addEmployee') }}</h1>

    <form @submit.prevent="handleSubmit" class="max-w-lg space-y-4">
      <div class="flex flex-col gap-2">
        <label for="firstName">{{ t('auth.user.form.label.first_name') }} *</label>
        <div>
          <InputText id="firstName" v-model="employeeForm.first_name" :class="{ 'p-invalid': errors.first_name }"
            class="w-full" />
        </div>
        <small class="text-red-500">{{ errors.first_name }}</small>
      </div>

      <div class="flex flex-col gap-2">
        <label for="lastName">{{ t('auth.user.form.label.last_name') }} *</label>
        <div>
          <InputText id="lastName" v-model="employeeForm.last_name" :class="{ 'p-invalid': errors.last_name }"
            class="w-full" />
        </div>
        <small class="text-red-500">{{ errors.last_name }}</small>
      </div>

      <div class="flex flex-col gap-2">
        <label for="email">{{ t('auth.user.form.label.mail') }} *</label>
        <div>
          <InputText id="email" v-model="employeeForm.email" type="email" :class="{ 'p-invalid': errors.email }"
            class="w-full" />
        </div>
        <small class="text-red-500">{{ errors.email }}</small>
      </div>

      <!-- <div class="flex flex-col gap-2">
        <label for="role">{{ t('employee.roles.label') }} *</label>
        <Dropdown id="role" v-model="employeeForm.role" :options="roleOptions" optionLabel="label" optionValue="value"
          class="w-full" />
      </div> -->

      <!-- <div class="flex flex-col gap-2">
        <label for="permissions">{{ t('employee.form.permissions') }}</label>
        <MultiSelect
          id="permissions"
          v-model="employeeForm.permissions"
          :options="availablePermissions"
          optionLabel="label"
          optionValue="value"
          :filter="true"
          class="w-full"
        />
      </div> -->

      <div class="flex justify-end mt-6">
        <Button type="submit" :label="t('employee.form.submit')" :loading="isLoading" class="w-full" />
      </div>
    </form>
    <Dialog v-model:visible="showDialog" modal :header="t('common.success')" class="max-w-md">
      <div class="p-4">
        <p class="mb-4">{{ t('employee.success.employeeCreated') }}</p>
        <div class="p-3 mb-4 text-sm break-all bg-gray-100 rounded-lg">
          {{ registrationUrl }}
        </div>
        <p class="text-sm text-gray-600">{{ t('employee.invitationLink.invitationLinkInfo') }}</p>
      </div>
      <template #footer>
        <div class="flex justify-end gap-2">
          <Button :label="t('employee.invitationLink.copy')" @click="copyInvitationLink" icon="pi pi-copy"
            class="p-button-secondary" />
          <Button :label="t('common.ok')" @click="returnToTeam" icon="pi pi-check" autofocus />
        </div>
      </template>
    </Dialog>
  </main>
</template>