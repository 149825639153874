import '@/assets/css/main.css'
import PrimeVue from 'primevue/config'
import 'primeicons/primeicons.css'
import ToastService from 'primevue/toastservice'
import Preset from '@/assets/presets'
import * as Sentry from '@sentry/vue'
import VueGtag from 'vue-gtag'
import vue3GoogleLogin from 'vue3-google-login'
import { useAppStore } from './stores/appStore'

declare global {
  interface Window {
    __initialLanguage: string;
  }
}

import ConfirmationService from 'primevue/confirmationservice'
import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import i18n from '@/i18n'
import { createPinia } from 'pinia'

import { useEnvStore } from '@/stores/envStore'

// Initialize language before app creation
function initializeLanguage() {
  const savedLanguage = localStorage.getItem('language')
  const browserLanguage = navigator.language || navigator.languages[0]
  const languageCode = browserLanguage.split('-')[0]
  const supportedLanguages = ['en', 'fr', 'de', 'it']
  
  let language = 'en' // Default
  
  if (savedLanguage && supportedLanguages.includes(savedLanguage)) {
    language = savedLanguage
  } else if (supportedLanguages.includes(languageCode)) {
    language = languageCode
  }
  
  // Set HTML lang attribute immediately
  document.documentElement.lang = language
  
  // Store for later use after app initialization
  window.__initialLanguage = language
}

// Call this function before creating the Vue app
initializeLanguage()

const app = createApp(App)

const pinia = createPinia()
app.use(pinia)

if (useEnvStore().environment !== 'local') {
  Sentry.init({
    app,
    dsn: 'https://76a9c39bed65893ce8a4eab5fb575972@o4507153118199808.ingest.de.sentry.io/4507247918448720',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 10% des transactions en production
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^https:\/\/staging-gastronomia-frontend.jcloud.ik-server.com/,
      /^http:\/\/dev-app-gastronomia.backtik.ch/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.05, // Capture 5% des sessions en production
    replaysOnErrorSampleRate: 1.0, // Capturer 100% des sessions avec erreurs
  })
}

app.use(router)

// Add Google Analytics
if (useEnvStore().environment === 'production') {
  app.use(VueGtag, {
    config: {
      id: useEnvStore().googleAnalyticsMeasurementId,
    },
    router
  })
}

app.use(vue3GoogleLogin, {
  clientId: useEnvStore().googleClientId,
})

app.use(PrimeVue, {
  unstyled: true,
  pt: Preset,
})
app.use(ConfirmationService)
app.use(i18n)
app.use(ToastService)

app.mount('#app')

// After app creation, use the store
setTimeout(() => {
  const appStore = useAppStore()
  if (window.__initialLanguage) {
    appStore.setLanguage(window.__initialLanguage as 'en' | 'fr' | 'de' | 'it')
  }
}, 0)
