<script setup lang="ts">
import ChevronLeftIcon from '@/assets/icons/Planning/ChevronLeftIcon.vue';
import ChevronRightIcon from '@/assets/icons/Planning/ChevronRightIcon.vue';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Sidebar from 'primevue/sidebar';
import type { ModelRef, Ref } from 'vue';
import { computed, onMounted, reactive, ref } from 'vue';
import Checkbox from 'primevue/checkbox';
import { useFetch } from '@/composables/useFetch';
import { useEnvStore } from '@/stores/envStore';
import { useUserStore } from '@/stores/useUserStore';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import type { OrderPlannedMenus } from '@/types/order';
import { useOrderList } from '@/stores/orderStore';
import Button from 'primevue/button';
import Skeleton from 'primevue/skeleton';
import SelectIngredient from '@/components/Orders/SelectIngredient.vue';
import { adjustUnitsForQuantity, calculateIngredientPrice } from '@/utils/prices';

const { fetchData } = useFetch();
const user = ref(useUserStore().user)
const restaurantId = ref<string | null>(null)
const toast = useToast()
const { t } = useI18n({ useScope: 'global' })
const plannedMenusByDay: Ref<Record<string, OrderPlannedMenus>> = ref<Record<string, OrderPlannedMenus>>({})

const orderListStore = useOrderList()
const listSelectedIngredients = ref<any[]>([])

const currentDay = ref<string>(new Date().toISOString().split('T')[0])

const showSideBar: ModelRef<boolean | undefined> = defineModel('visible', { required: true })

// Add reactive state for each dish's open/closed state
const openDishes = reactive<Record<number, boolean>>({});
const isLoading = ref(false)

onMounted(() => {
  console.log("Side bar mounted ")

  if (!user.value) return
  if (user.value.restaurants.length == 0) {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('auth.user.noRestaurantConnected'),
      life: 3000,
    })
    return
  }
  restaurantId.value = user.value.restaurants[0].id

  listSelectedIngredients.value = orderListStore.ingredientsList.map(ingredient => {
    const adjustedUnitAndQty = adjustUnitsForQuantity(ingredient.quantity, ingredient.unit);
    const adjustedPrice = calculateIngredientPrice(ingredient.price)
    return {
      ...ingredient,
      quantity: adjustedUnitAndQty.quantity,
      unit: adjustedUnitAndQty.unit,
      price: adjustedPrice,
      id: ingredient.original_ingredient_id
    };
  });
  
  // Get the current day in the format 'YYYY-MM-DD'
  currentDay.value = new Date().toISOString().split('T')[0]

  fetchPlannedMenus(currentDay.value)
});

function fetchPlannedMenus(date: string) {
  if (plannedMenusByDay.value[date]) return;

  isLoading.value = true
  fetchData(
    `${useEnvStore().apiUrl}/restaurants/${restaurantId.value}/planned-menus/${date}/ingredients`,
    'GET'
  ).then(({ data, error }) => {
    isLoading.value = false
    if (data) {
      addToPlannedMenusByDay(date, data);
    }
    if (error) {
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('common.errorDetail'),
        life: 3000,
      });
    }
  });
}

function addToPlannedMenusByDay(date: string, data: OrderPlannedMenus) {
  // orderListStore.ingredientsList;
  // console.log("INGREDIENTS LIST", orderListStore.ingredientsList)
  // data.menuCourses.forEach(course => {
  // console.log("course", course)
  // console.log("orderListStore.ingredientsList", orderListStore.ingredientsList)
  // course.dish.ingredients.forEach(ingredient => {
  //   console.log("ingredDFDient", ingredient)
  //   ingredient.is_selected = orderListStore.ingredientsList.some((ing: any) => ing.original_ingredient_id === ingredient.id)
  // });
  // });
  data.menuCourses.forEach(course => {
    course.dish.ingredients.forEach(ingredient => {
      if (ingredient.quantity) {
        const adjustedUnitAndQty = adjustUnitsForQuantity(ingredient.quantity, ingredient.unit, course.dish.servings);
        ingredient.quantity = adjustedUnitAndQty.quantity;
        ingredient.unit = adjustedUnitAndQty.unit;
        const adjustedPrice = calculateIngredientPrice(ingredient.price, course.dish.servings);
        ingredient.price = adjustedPrice;
      }

    });
  });
  plannedMenusByDay.value[date] = data;
  // console.log("DATA", data)
}

function incrementDay() {
  const next = new Date(new Date(currentDay.value).getTime() + 86400000);
  currentDay.value = next.toISOString().split('T')[0];
  fetchPlannedMenus(currentDay.value)
}

function decrementDay() {
  const prev = new Date(new Date(currentDay.value).getTime() - 86400000);
  currentDay.value = prev.toISOString().split('T')[0];
  fetchPlannedMenus(currentDay.value)
}

const sortedPlannedMenus = computed(() => {
  if (!plannedMenusByDay.value || !plannedMenusByDay.value[currentDay.value]) return {}

  // const date = plannedMenusByDay.value.currentDay;
  const grouped = {
    [currentDay.value]: {
      starter: [] as any[],
      main: [] as any[],
      dessert: [] as any[]
    }
  };
  // console.log("MY PLANNED", plannedMenusByDay.value[currentDay.value].menuCourses)
  plannedMenusByDay.value[currentDay.value].menuCourses.forEach(course => {
    // Map course.order to course type. Adjust as needed.
    // console.log("course", course)
    let type: 'starter' | 'main' | 'dessert';
    if (course.order === 1) {
      type = 'starter';
    } else if (course.order === 2) {
      type = 'main';
    } else if (course.order === 3) {
      type = 'dessert';
    } else {
      type = 'main';
    }

    if (course.dish) {
      const dishId = course.dish.id;
      // search for an existing dish with the same id
      const existingDish = grouped[currentDay.value][type].find((dish: any) => dish.id === dishId);
      if (!existingDish) {
        // add new dish with its ingredients mapped for is_selected flag
        grouped[currentDay.value][type].push({
          id: dishId,
          name: course.dish.name,
          // ingredients: course.dish.ingredients,
          ingredients: (course.dish.ingredients || []).map(ingredient => {
            const selected = listSelectedIngredients.value.find((ing: any) => ing.id === ingredient.id)
            return selected ? { ...selected, is_selected: true } : { ...ingredient, is_selected: false }
          })
        });
      } else {
        // merge ingredients from the current course
        // existingDish.ingredients = existingDish.ingredients.concat(course.dish.ingredients || []);
      }
    }
  });

  return grouped;
});

function toggleIngredientSelection(ingredient) {
  // console.log("INGREDIENT", ingredient)
  const index = listSelectedIngredients.value.findIndex((ing: any) => ing.id === ingredient.id)
  if (index === -1) {
    listSelectedIngredients.value.push(ingredient)
  } else {
    listSelectedIngredients.value.splice(index, 1)
  }
}

function handleAddIngredients() {
  console.log("ADD INGREDIENTS", listSelectedIngredients.value)
  orderListStore.addIngredients(listSelectedIngredients.value, restaurantId.value).then(
    () => showSideBar.value = false
  )
}

function updateIngredient(ingredient) {
  const index = listSelectedIngredients.value.findIndex((ing: any) => ing.id === ingredient.id)
  if (index !== -1) {
    if (ingredient.is_selected) {
      listSelectedIngredients.value[index] = ingredient
    } else {
      listSelectedIngredients.value.splice(index, 1)
    }
  } else {
    if (ingredient.is_selected) {
      listSelectedIngredients.value.push(ingredient)
    }
  }
}

function toggleAllIngredients(dish) {
  const allSelected = dish.ingredients.every(ing => ing.is_selected)
  dish.ingredients.forEach(ing => {
    ing.is_selected = !allSelected
    const idx = listSelectedIngredients.value.findIndex(item => item.id === ing.id)
    if (!allSelected) {
      if (idx === -1) listSelectedIngredients.value.push(ing)
    } else {
      if (idx !== -1) listSelectedIngredients.value.splice(idx, 1)
    }
  })
}

function isAllSelected(dish) {
  if (!dish.ingredients) return false
  return dish.ingredients.length > 0 && dish.ingredients.every(ing => ing.is_selected)
}
const dishSelection = computed(() => {
  const selection = {}
  for (const [day, dishGroups] of Object.entries(sortedPlannedMenus.value)) {
    Object.values(dishGroups).forEach(dishList => {
      dishList.forEach(dish => {
        selection[dish.id] = isAllSelected(dish)
      })
    })
  }
  return selection
})
</script>
<template>
  <!-- {{ plannedMenus }} -->
  <!-- <pre>{{ sortedPlannedMenus }}</pre> -->
  <!-- {{ plannedMenus }} -->
  <Sidebar v-model:visible="showSideBar" :header="t('orders.addProductFromDish')" position="bottom" style="height: 90%"
    block-scroll class="w-full pb-8 md:w-3/4 lg:w-1/2">

    <!-- NAVIGATION -->
    <div class="flex items-center justify-center">
      <!-- Previous day button -->
      <div class="w-12 h-12">
        <button @click="decrementDay" class="rounded-l-lg" v-if="currentDay > new Date().toISOString().split('T')[0]">
          <ChevronLeftIcon class="w-12 h-12 text-primary-500" />
        </button>
      </div>
      <!-- Display the current day -->
      <div class="px-4 text-center w-28">
        <div>{{ t(`common.dayShortToLong.${new Date(currentDay).toLocaleDateString('en-US', { weekday: 'short' })}`)
          }}</div>
        <div class="text-sm">
          {{ `${('0' + new Date(currentDay).getDate()).slice(-2)}.${('0' + (new Date(currentDay).getMonth() +
            1)).slice(-2)}.${new Date(currentDay).getFullYear()}` }}
        </div>
      </div>
      <!-- Next day button -->
      <div class="w-12 h-12">
        <button @click="incrementDay" class="rounded-r-lg">
          <ChevronRightIcon class="w-12 h-12 text-primary-500" />
        </button>
        <!-- <button @click="null" v-if="!plannedMenusByDay[currentDay]">
          <ChevronRightIcon class="w-12 h-12 text-primary-500" />
        </button> -->
      </div>
    </div>

    <!-- CONTENT -->
    <div class="py-10" v-if="!isLoading">
      <Accordion class="flex flex-col gap-2" multiple>
        <AccordionTab v-for="(dishes, type) in sortedPlannedMenus[currentDay]" :key="type"
          :header="`${t('creationFilters.dishType.' + type)} (${dishes.length})`">
          <div v-if="!dishes.length" class="my-2">
            <p class="text-sm italic">{{ t('orders.noDishes') }}</p>
          </div>
          <div v-for="dish in dishes" :key="dish.id" class="-ml-2">
            <div class="flex items-center justify-between gap-2 my-2 font-semibold cursor-pointer text-primary-950">
              <div class="flex items-center" @click="toggleAllIngredients(dish)">
                <Checkbox :model-value="dishSelection[dish.id]" binary />
              </div>
              <div @click="openDishes[dish.id] = !openDishes[dish.id]"
                class="flex items-center justify-between flex-1 my-2">
                <span>{{ dish.name }}</span>
                <span class="pi pi-angle-down" v-if="openDishes[dish.id]"></span>
                <span class="pi pi-angle-right" v-else></span>
              </div>
            </div>
            <div v-if="openDishes[dish.id]">
              <div v-for="(ingredient, index) in dish.ingredients" :key="index">
                <div v-if="!ingredient.hide" class="flex items-center my-1">
                  <SelectIngredient :ingredient="dish.ingredients[index]" @update-ingredient="updateIngredient"
                    @toggle="toggleIngredientSelection" />
                </div>
              </div>
            </div>
          </div>
        </AccordionTab>
      </Accordion>
    </div>
    <div v-if="isLoading" class="flex flex-col justify-center gap-2 py-10">
      <Skeleton height="4.4rem" class="bg-primary-100" />
      <Skeleton height="4.4rem" class="bg-primary-100" />
      <Skeleton height="4.4rem" class="bg-primary-100" />
    </div>
    <div class="absolute bottom-0 left-0 z-50 flex w-full gap-3 px-10 py-6 bg-white">
      <Button type="button" outlined class="flex-1 text-center" :disabled="isLoading"
        @click="showSideBar = false">
        {{ t('common.cancel') }}
      </Button>
      <Button class="flex-1 text-center" :disabled="!listSelectedIngredients.length" @click="handleAddIngredients">
        {{ t('menuDishView.addToIngredientsList') }}
      </Button>
    </div>
    <div v-if="!isLoading && !sortedPlannedMenus[currentDay]" class="flex justify-center mx-auto mt-6">
      <p>{{ t('common.errorDetail') }}</p>
    </div>
  </Sidebar>
</template>
