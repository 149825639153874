<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n/index.js'
import { computed, ref, watchEffect } from 'vue'
import { useToast } from 'primevue/usetoast'
import type { AttributesList, Restaurant } from '@/types/restaurants.js'
import { useProfileRestaurant } from '@/composables/useProfileRestaurant.js'
import { watch } from 'vue'
import { useClipboard } from '@vueuse/core'
import { useRoute, useRouter } from 'vue-router'
import type { Ref } from 'vue'
import { useEnvStore } from '@/stores/envStore'
import { useFetch } from '@/composables/useFetch'
import RestaurantTab from './RestaurantTab.vue'
import ToolingView from './ToolingView.vue'
import { useUserStore } from '@/stores/useUserStore'

const user = ref(useUserStore().user)
const route = useRoute()
const restaurant: Ref<Restaurant | null> = ref(null);
const attributes: Ref<AttributesList | null> = ref(null)
const toolingViewDisplayed = computed(() => route.query.showTooling === 'true')


const { fetchData } = useFetch()

watchEffect(async () => {
  if (user.value?.restaurants[0].id && !restaurant.value) {
    const { data: dbRestaurant, error: dbRestaurantError } = await fetchData(
      useEnvStore().apiUrl + '/restaurants/' + user.value?.restaurants[0].id,
      'GET'
    )
    if (dbRestaurantError) console.log(dbRestaurantError)
    else {
      initializeRestaurantFields();
      restaurant.value = dbRestaurant
    }

    const { data: dbAttributes, error: dbAttributesError } = await fetchData(
      useEnvStore().apiUrl + '/restaurants/attributes',
      'GET'
    )
    if (dbAttributesError) console.log(dbAttributesError)
    else attributes.value = dbAttributes
  }
})

const emit = defineEmits<{
  (e: 'updateRestaurant', restaurant: Restaurant): void
  (e: 'goToTooling'): void
}>()

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const { copy } = useClipboard()
const toast = useToast()

const router = useRouter()

// const restaurantWebsiteUrl = ref(restaurant.value?.website_url)
const restaurantName = ref<string>('')
const restaurantWebsiteUrl = ref<string>('')
const restaurantPhoneNumber = ref<string>('')
const restaurantLogoUrl = ref<string>('')
const restaurantDescription = ref<string>('')
const restaurationType = ref<number | null>(null)
const restaurantCoverCount = ref<number>(1)
const restaurantCooks = ref<number>(1)

// Initialize local state variables from the fetched restaurant data
function initializeRestaurantFields() {
  if (!restaurant.value) return

  restaurantName.value = restaurant.value.name
  restaurantWebsiteUrl.value = stripProtocol(restaurant.value.website_url)
  restaurantPhoneNumber.value = restaurant.value.phone_number
  restaurantLogoUrl.value = restaurant.value.logo_url || ''
  restaurantDescription.value = restaurant.value.description || ''
  restaurationType.value = restaurant.value.attributes.restaurationType[0]?.id || null
  restaurantCoverCount.value = restaurant.value.cover_count || 1
  restaurantCooks.value = restaurant.value.cooks_per_service || 1
}

const { updateRestaurantAttributes } = useProfileRestaurant(
  toast,
  restaurant.value?.id ?? ''
)


watch(restaurationType, async () => {
  if (restaurationType.value === null) return
  const updateResponse = await updateRestaurantAttributes({
    attributeIds: [restaurationType.value],
    isRestaurationType: true,
  })
  if (updateResponse !== null) emit('updateRestaurant', updateResponse)
})

watchEffect(() => {
  if (restaurantCoverCount.value === null) restaurantCoverCount.value = 1
  if (restaurantCooks.value === null) restaurantCooks.value = 1
})

function stripProtocol(url: string | null | undefined): string {
  if (!url) return ''
  return url.replace(/^(https?:\/\/)/, '')
}

watch(restaurantWebsiteUrl, (newValue) => {
  restaurantWebsiteUrl.value = stripProtocol(newValue)
})

function updateRestaurant (updatedRestaurant) {
  if (user.value) {
    restaurant.value = updatedRestaurant
    if (restaurant.value) {
      user.value.restaurants[0].name = restaurant.value.name
      user.value.restaurants[0].restauration_type = restaurant.value.attributes.restaurationType[0].name
    }
  }
}

</script>

<template>
  <RestaurantTab v-if="restaurant && attributes && user && !toolingViewDisplayed" :restaurant :attributes @update-restaurant="updateRestaurant" @go-to-tooling="() => {
                router.push({ name: 'my-restaurant', query: { showTooling: 'true' } })
              }
              " />
  <ToolingView v-if="toolingViewDisplayed && attributes && restaurant" :attributes :restaurant @update-restaurant="(updatedRestaurant) => {
      restaurant = updatedRestaurant
    }
    " />
</template>
