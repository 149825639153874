<script setup lang="ts">
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import { ref, onMounted, computed, type Ref, reactive, watch, markRaw } from 'vue'
import { useOrderList } from '@/stores/orderStore'
import { useEnvStore } from '@/stores/envStore'
import { useFetch } from '@/composables/useFetch'
import { useToast } from 'primevue/usetoast'
import InlineIngredient from '@/components/Orders/InlineIngredient.vue'
import type { Ingredient } from '@/types/dish'
import LoadingView from '../LoadingView.vue'
import EditIngredient from '@/components/Orders/EditIngredient.vue'
import { useDishesStore } from '@/stores/dishesStore'
import Button from 'primevue/button'
import { useConfirm } from 'primevue/useconfirm'
import { aggregateGroupInfo, groupIngredientsInCategory } from '@/utils/orders'
import Checkbox from 'primevue/checkbox'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/useUserStore'
import TheCategoryList from '@/components/Orders/TheCategoryList.vue'
import TheMenuList from '@/components/Orders/TheMenuList.vue'
import SelectButton from 'primevue/selectbutton'
import DiningIcon from '@/assets/icons/Menus/DiningIcon.vue'
import TagIcon from '@/assets/icons/Menus/TagIcon.vue'
import GeneratedTitlesIcon from '@/assets/icons/Planning/GeneratedTitlesIcon.vue'
import SpeedDial from 'primevue/speeddial'
import ButtonMenu from '@/assets/icons/Buttons/ButtonMenu.vue'
import Menu from 'primevue/menu'
import DishIcon from '@/assets/icons/Orders/DishIcon.vue'
import IngredientIcon from '@/assets/icons/Orders/IngredientIcon.vue'
import AddIngredientFromPlanningSidebar from '@/components/Orders/AddIngredientFromPlanningSidebar.vue'

const orderListStore = useOrderList()
const { t, te } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const toast = useToast()
const dishesStore = useDishesStore()
const ingredientUnits = ref(dishesStore.units)
const confirm = useConfirm()
const router = useRouter()

const viewOptions = [
  { name: t('orders.menuView'), value: 'category', icon: GeneratedTitlesIcon },
  { name: t('orders.categoryView'), value: 'menu', icon: DiningIcon },
]
const selectedView = ref('category')

const restaurantId = ref()
const user = ref(useUserStore().user)
const envStore = useEnvStore()
const { isFetching, fetchData } = useFetch()
const ingredientToEdit: Ref<Ingredient> = ref({
  id: -1,
  category: 'other',
  name: '',
  quantity: 0,
  unit: '',
  price: 0,
})
const ingredientToEditIndex = ref(-1)
const showSideBar: Ref<boolean> = ref(false)

// This is now the single source of truth for selectedIngredients
const selectedIngredients = ref<Ingredient[]>([])

onMounted(async () => {
  //TODO : use real restaurantId
  if (!user.value) return
  if (user.value.restaurants.length == 0) {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('auth.user.noRestaurantConnected'),
      life: 3000,
    })
    return
  }

  if (!ingredientUnits.value.length) {
    const { data, error } = await fetchData(envStore.apiUrl + `/units/default`, 'GET')
    if (error) {
      console.log('error', error)
    } else {
      ingredientUnits.value = data
    }
  }

  // TODO: handle if user is in many restaurants
  restaurantId.value = user.value.restaurants[0].id
  console.log('Test, orderliststore: ', orderListStore.ingredientsByCategory)
  if (!orderListStore.ingredientsList.length || orderListStore.shouldRefresh) {
    await orderListStore.fetchIngredients(restaurantId.value)
    console.log('Ingredients List:', orderListStore.ingredientsList)

    console.log(orderListStore.ingredientsByCategory)
  }
  // fetchIngredients(restaurantId.value);
})

const categoriesWithIngredients = computed(() => {
  return groupIngredientsInCategory(orderListStore.ingredientsByCategory, orderListStore)
})

function addOrUpdateIngredient({
  ingredient = null,
  index = null,
  category = null,
}: {
  ingredient?: Ingredient | null
  index?: number | null
  category: string | null
}) {
  if (ingredient && index !== null) {
    console.log('open sidebar - edit ingredient', ingredient, index)
    ingredientToEdit.value = { ...ingredient }
    ingredientToEditIndex.value = index
  } else {
    category = category ? category : 'other'
    ingredientToEdit.value = {
      id: -1,
      category: category,
      name: '',
      quantity: 0,
      unit: '',
      price: 0,
    }
    ingredientToEditIndex.value = -1
    console.log('open sidebar -   update ingredient', ingredient, index)
  }
  showSideBar.value = true
}

const deleteIngredient = async () => {
  orderListStore.removeIngredients([ingredientToEdit.value.id], restaurantId.value).then(() => {
    ingredientToEdit.value = {
      id: -1,
      category: 'other',
      name: '',
      quantity: 0,
      unit: 'g',
      price: 0,
    }
  })
}

const updateIngredient = async () => {
  console.log('Update ingredient')
  console.log(ingredientToEdit.value)
  orderListStore.updateIngredient(ingredientToEdit.value, restaurantId.value).then(() => {
    ingredientToEdit.value = {
      id: -1,
      category: 'other',
      name: '',
      quantity: 0,
      unit: 'g',
      price: 0,
    }
  })
}

const addIngredient = async () => {
  console.log('add ingredient')
  orderListStore.addIngredients([ingredientToEdit.value], restaurantId.value).then(() => {
    ingredientToEdit.value = {
      id: -1,
      category: 'other',
      name: '',
      quantity: 0,
      unit: '',
      price: 0,
    }
  })
}

const expandedGroups = reactive(new Set())

function toggleGroup(groupName: string) {
  if (expandedGroups.has(groupName)) {
    expandedGroups.delete(groupName)
  } else {
    expandedGroups.add(groupName)
  }
}

const confirmDeleteSelectedIngredients = (event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t('ingredients.deleteSelectedIngredients.popupLabel'),
    icon: 'pi pi-exclamation-triangle',
    acceptLabel: t('ingredients.deleteSelectedIngredients.confirm'),
    rejectLabel: t('ingredients.deleteSelectedIngredients.cancel'),
    accept: () => {
      if (!user.value || user.value.restaurants.length === 0) {
        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: t('auth.user.noRestaurantConnected'),
          life: 3000,
        })
        return
      }
      deleteSelectedIngredients(selectedIngredients.value)
    },
    reject: () => {
      console.log('reject')
    },
  })
}

function deleteSelectedIngredients(listIngredientsToDelete) {
  console.log('Delete ingredients', listIngredientsToDelete)

  const ingredientIds = listIngredientsToDelete.map((ingredient) => ingredient.id)

  if (user.value && user.value.restaurants.length > 0) {
    const restaurantId = user.value.restaurants[0].id
    orderListStore.removeIngredients(ingredientIds, restaurantId)
    selectedIngredients.value = []
  } else {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('auth.user.noRestaurantConnected'),
      life: 3000,
    })
  }
}

function compareIngredients() {
  console.log('should compare Ingredients. To add in a next phase.')
  orderListStore.addIngredientsToOrder(selectedIngredients.value, true);
  router.push({ name: 'order-form' })
}

const categorySelections = reactive({})

categoriesWithIngredients.value.forEach((category) => {
  categorySelections[category.name] = false
})

function toggleCategory(categoryName) {
  // console.log('toggle category ', categoryName)
  // const isSelected = categorySelections[categoryName]
  // const category = categoriesWithIngredients.value.find((c) => c.name === categoryName)
  // console.log('category ', category)
  // category?.ingredientGroups.forEach((group) => {
  //   group.ingredients?.forEach((ingredient) => {
  //     const ingredientIndex = selectedIngredients.value.findIndex((i) => i.id === ingredient.id)
  //     if (isSelected && ingredientIndex === -1) {
  //       selectedIngredients.value.push(ingredient)
  //     } else if (!isSelected && ingredientIndex !== -1) {
  //       selectedIngredients.value.splice(ingredientIndex, 1)
  //     }
  //   })
  // })
}

// watch(
//   selectedIngredients,
//   () => {
//     console.log('watch selectedIngredients', selectedIngredients.value)
//     Object.keys(categorySelections).forEach((categoryName) => {
//       const category = categoriesWithIngredients.value.find((c) => c.name === categoryName)
//       categorySelections[categoryName] = category?.ingredientGroups.every((group) =>
//         group.ingredients?.every((ingredient) =>
//           selectedIngredients.value.some((i) => i.id === ingredient.id)
//         )
//       )
//     })

//     categoriesWithIngredients.value.forEach((category) => {
//       category.ingredientGroups.forEach((group) => {
//         groupSelections[group.group] = group.ingredients?.every((ingredient) =>
//           isSelected(ingredient)
//         )
//       })
//     })
//   },
//   { deep: true }
// )

function isSelected(ingredient) {
  return selectedIngredients.value.some((i) => i.id === ingredient.id)
}

const groupSelections = reactive({})
const showAddIngredientFromPlanning = ref(false);
const unMountIngredientFromPlanningSideBar = ref(false);

// debounce the unmounting of the sidebar, according to the showAddIngredientFromPlanning value
watch(showAddIngredientFromPlanning, (value) => {
  setTimeout(() => {
    unMountIngredientFromPlanningSideBar.value = value;
  }, 300);
});

// categoriesWithIngredients.value.forEach((category) => {
//   category.ingredientGroups.forEach((group) => {
//     groupSelections[group.group] = group.ingredients?.every((ingredient) => isSelected(ingredient))
//   })
// })

// function toggleGroupSelection(groupName) {
//   const group = categoriesWithIngredients.value
//     .flatMap((category) => category.ingredientGroups)
//     .find((g) => g.group === groupName)
//   if (!group) return
//   if (groupSelections[groupName]) {
//     group.ingredients?.forEach((ingredient) => {
//       if (!isSelected(ingredient)) {
//         selectedIngredients.value.push(ingredient)
//       }
//     })
//   } else {
//     group.ingredients?.forEach((ingredient) => {
//       const index = selectedIngredients.value.findIndex((i) => i.id === ingredient.id)
//       if (index !== -1) {
//         selectedIngredients.value.splice(index, 1)
//       }
//     })
//   }
// }

// Update the groupSelections initialization
categoriesWithIngredients.value.forEach(category => {
  category.ingredientGroups.groups?.forEach(group => {
    groupSelections[group.name] = group.ingredients.every(ingredient => isSelected(ingredient));
  });
});

// Add a watch to keep group selections in sync
watch(selectedIngredients, () => {
  categoriesWithIngredients.value.forEach(category => {
    category.ingredientGroups.groups?.forEach(group => {
      groupSelections[group.name] = group.ingredients.every(ingredient => isSelected(ingredient));
    });
  });
}, { deep: true });

function toggleGroupSelection(categoryName, groupName) {
  // Find the category and group
  const category = categoriesWithIngredients.value.find(c => c.name === categoryName);
  const group = category?.ingredientGroups.groups?.find(g => g.name === groupName);
  console.log("category: ", category, "group: ", group);
  if (!group) return;

  console.log("Group selections: ", groupSelections);
  const isCurrentlySelected = groupSelections[groupName];

  console.log("IS CURRENTLY SELECTED: ", isCurrentlySelected);

  // Toggle all ingredients in the group
  group.ingredients.forEach(ingredient => {
    const index = selectedIngredients.value.findIndex(i => i.id === ingredient.id);
    console.log("INDEX : ", index);
    if (!isCurrentlySelected && index === -1) {
      console.log("ADDING INGREDIENT");
      // Add ingredient if group is being selected
      selectedIngredients.value.push(ingredient);
    } else if (isCurrentlySelected && index !== -1) {
      console.log("REMOVING INGREDIENT");
      // Remove ingredient if group is being deselected
      selectedIngredients.value.splice(index, 1);
    }
    console.log("SELECTED INGREDIENTS: ", selectedIngredients.value);
  });

  // Update group selection state
  groupSelections[groupName] = !isCurrentlySelected;
}

function importFromDish() {
  console.log('Import from dish clicked')
  showAddIngredientFromPlanning.value = true;
  // e.g., navigate to a dish import route or open a modal
  // router.push({ name: 'import-dish' })
}

const popupMenuItems = ref([
  {
    items: [
      {
        label: t('orders.addProduct'),
        customIcon: markRaw(IngredientIcon),
        command: () => addOrUpdateIngredient({ ingredient: null, index: null, category: 'other' })
      },
      {
        label: t('orders.addProductFromDish'),
        customIcon: markRaw(DiningIcon),
        command: () => importFromDish(),
      },
    ]
  }
])

const popupMenu = ref()

function togglePopUpMenu(event: MouseEvent) {
  popupMenu.value.toggle(event)
}

</script>

<template>
  <main>
    <div class="mb-4 text-right">
      <!-- <SelectButton v-model="selectedView" :options="viewOptions" optionLabel="name" optionValue="value"
        :allow-empty="false" /> -->
      <div class="flex justify-between">
        <div>
          <SelectButton v-model="selectedView" :options="viewOptions" optionLabel="name" optionValue="value"
            :allow-empty="false" style="box-shadow: none !important;">
            <template #option="slotProps">
              <component :is="slotProps.option.icon" class="w-5 h-5 text-primary-950" />
            </template>
          </SelectButton>
        </div>
        <div>
          <!-- <Button class="flex-1 text-center" size="small" :disabled="!selectedIngredients.length"
            @click="compareIngredients">
            <div class="flex items-center gap-2">
              <span>{{ t('orders.purchaseOrder') }}</span>
              <span class="pi pi-arrow-right"></span>
        </div>
        </Button> -->
          <Button rounded icon="pi pi-plus" :pt="{ root: { class: '!p-1.5 !w-8 !h-8 !bg-accent !ring-1 !ring-accent' } }" :pt-options="{ mergeProps: true }"
            @click="togglePopUpMenu" aria-controls="overlay_menu" />
          <!-- <Button link rounded :pt="{ root: { class: '!p-1.5 !w-8 !h-8' } }" :pt-options="{ mergeProps: true }"
            @click="togglePopUpMenu" aria-controls="overlay_menu">
            <template #icon>
              <ButtonMenu />
          </Button> -->

          <!-- Popup Menu Component -->
          <Menu ref="popupMenu" id="overlay_menu" :model="popupMenuItems" popup
            :pt="{ submenuHeader: { class: '!p-0' } }" :pt-options="{ mergeProps: true }">
            <template #item="{ item }">
              <div class="flex items-center p-2 cursor-pointer" @touch="item.command">
                <div class="flex items-center justify-center w-5 h-5">
                  <component :is="item.customIcon" class="w-4 h-4 text-primary-950" />
                </div>
                <span class="ml-2 text-sm font-semibold">{{ item.label }}</span>
              </div>
            </template>
          </Menu>
        </div>
      </div>
      <!-- <Button outlined @click="selectedView = selectedView === 'category' ? 'menu' : 'category'"
        aria-label="Toggle view">
        <div v-if="selectedView === 'menu'" class="flex flex-row items-center justify-between w-full gap-2">
          <p>Trier par catégorie</p>
          <span class="pi pi-list"></span>
        </div>
        <div v-else class="flex flex-row items-center justify-between w-full gap-2">
          <p>Trier par menu</p>
          <DiningIcon class="w-5" />
        </div>
      </Button> -->
    </div>
    <div>
      <TheCategoryList v-if="selectedView === 'category'" @add-or-update-ingredient="addOrUpdateIngredient"
        v-model:selectedIngredients="selectedIngredients" />

      <TheMenuList v-if="selectedView === 'menu'" v-model:selectedIngredients="selectedIngredients"
        @add-or-update-ingredient="addOrUpdateIngredient" />

      <EditIngredient v-model:ingredient="ingredientToEdit" :index="ingredientToEditIndex" v-model:visible="showSideBar"
        @add-ingredient="addIngredient" @edit-ingredient="updateIngredient" @delete-ingredient="deleteIngredient" />

      <div class="sticky z-50 flex gap-3 py-2 bg-white bottom-16"
        v-if="!isFetching || categoriesWithIngredients.length">
        <Button icon="pi pi-trash" aria-label="Delete" class="text-center" size="small"
          :disabled="!selectedIngredients.length"
          @click="!selectedIngredients.length ? null : confirmDeleteSelectedIngredients($event)" severity="danger" />
        <!-- <div class="hover:cursor-pointer"
          @click="!selectedIngredients.length ? null : confirmDeleteSelectedIngredients($event)">
            <div class="flex items-center gap-2" :class="!selectedIngredients.length ? 'text-gray-400' : 'text-red-700'">
            <span class="pi pi-trash"></span>
            <p>{{ t('common.delete') }}</p>
            </div>
        </div> -->
        <Button class="flex-1 text-center" size="small" :disabled="!selectedIngredients.length"
          @click="compareIngredients">
          {{ t('orders.createPurchaseOrder') }}
        </Button>
        <!-- <Button icon="pi pi-plus" aria-label="Delete" class="text-center" size="small"
          @click="addOrUpdateIngredient({ ingredient: null, index: null, category: 'other' })" /> -->
        <!-- <Button aria-label="Add" class="text-center" size="small"
          @click="addOrUpdateIngredient({ ingredient: null, index: null, category: 'other' })">
          <div class="w-4 aspect-square">
            <TabVegetables/>
          </div>
        </Button>
        <Button aria-label="Add" class="text-center" size="small"
          @click="importFromDish">
          <div class="w-4 aspect-square">
            <DiningIcon/>
          </div>
        </Button> -->
      </div>
    </div>
    <!-- SpeedDial for quick actions -->
    <!-- <SpeedDial :model="speedDialItems" buttonIcon="pi pi-plus" :radius="120" direction="up"
      class="!fixed bottom-24 right-8 z-50">
      <template #item="{ item, onClick:toggleCallback }">
        <div
          class="flex flex-col items-center justify-between w-10 align-middle border rounded-full cursor-pointer border-primary-500 bg-primary-500 aspect-square"
          @click="toggleCallback">
          <div class="flex items-center justify-center w-full h-full">
            <component v-if="typeof item.icon !== 'string'" :is="item.icon" class="w-4 h-4 text-white" />
            <span v-else :class="item.icon" class="text-sm text-white" />
          </div>
        </div>
      </template>
    </SpeedDial> -->
    <AddIngredientFromPlanningSidebar v-model:visible="showAddIngredientFromPlanning" v-if="unMountIngredientFromPlanningSideBar"/>
  </main>
</template>
