import TimeClockView from '@/views/Employee/TimeClockView.vue'

const employeeRoutes = [
  {
    path: '/timeclock',
    name: 'timeclock',
    meta: {
      headerConfig: {
        headerLabel: 'timeclock',
        hide: false,
        showBack: false,
        showProfile: true,
      },
      footerConfig: {
        hide: false,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    component: TimeClockView,
  },
]

export default employeeRoutes
