import TabMeats from '@/assets/icons/Orders/TabMeats.vue'
import { levenshteinDistance } from './functions'
import { convertUnit } from './prices'
import TabFishes from '@/assets/icons/Orders/TabFishes.vue'
import TabVegetables from '@/assets/icons/Orders/TabVegetables.vue'
import TabDry from '@/assets/icons/Orders/TabDry.vue'
import TabDairy from '@/assets/icons/Orders/TabDairy.vue'
import TabFrozen from '@/assets/icons/Orders/TabFrozen.vue'
import TabAlcohols from '@/assets/icons/Orders/TabAlcohols.vue'
import TabDrinks from '@/assets/icons/Orders/TabDrinks.vue'
import type { Ingredient, IngredientsToOrder } from '@/types/dish'
import TabOthers from '@/assets/icons/Orders/TabOthers.vue'
interface IngredientGroup {
  group: string
  ingredients: Ingredient[] | null
}

export function aggregateGroupInfo(group) {
  // console.log("AGGREGATE GROUP INFO Group:", group);
  let totalQuantity = 0
  let baseUnit

  // Determine a common base unit based on the first ingredient
  if (group.ingredients[0].unit === 'kg' || group.ingredients[0].unit === 'g') {
    baseUnit = 'g'
  } else if (
    group.ingredients[0].unit === 'l' ||
    group.ingredients[0].unit === 'ml' ||
    group.ingredients[0].unit === 'dl'
  ) {
    baseUnit = 'ml'
  } else {
    baseUnit = group.ingredients[0].unit // For non-convertible units
  }

  let isUnitConsistent = true
  const mixedUnits = new Set()
  for (const ingredient of group.ingredients) {
    mixedUnits.add(ingredient.unit)

    let convertedQuantity = parseFloat(ingredient.quantity)
    if (ingredient.unit === 'kg') {
      convertedQuantity = convertUnit(convertedQuantity, ingredient.unit, 'g')
    } else if (ingredient.unit === 'cl' || ingredient.unit === 'dl' || ingredient.unit === 'l') {
      convertedQuantity = convertUnit(convertedQuantity, ingredient.unit, 'ml')
    }

    totalQuantity += convertedQuantity

    // Check if current unit matches the base unit (after conversion)
    if (
      (baseUnit === 'g' && !['kg', 'g'].includes(ingredient.unit)) ||
      (baseUnit === 'ml' && !['l', 'ml', 'dl'].includes(ingredient.unit)) ||
      (!['kg', 'g', 'ml', 'l', 'dl'].includes(baseUnit) && baseUnit != ingredient.unit)
    ) {
      isUnitConsistent = false
    }
  }
  if (mixedUnits.size > 1 && !isUnitConsistent) {
    return {
      totalQuantity: undefined,
      unit: 'mix',
    }
  }

  if (baseUnit === 'g' && totalQuantity >= 1000) {
    return {
      totalQuantity: totalQuantity / 1000,
      unit: 'kg',
    }
  } else if (baseUnit === 'ml' && totalQuantity >= 1000) {
    return {
      totalQuantity: totalQuantity / 1000,
      unit: 'l',
    }
  }

  return {
    totalQuantity,
    unit: baseUnit,
  }
}

export function areWordsSimilar(word1: string, word2: string, threshold: number = 0.77): boolean {
  const normalized1 = normalizeString(word1)
  const normalized2 = normalizeString(word2)
  const distance = levenshteinDistance(normalized1, normalized2)
  const longest = Math.max(normalized1.length, normalized2.length)
  const similarity = 1 - distance / longest

  // console.log("Normalized names:", normalized1, normalized2);
  // console.log("Distance:", distance);
  // console.log("Similarity:", similarity, "Threshold:", threshold);

  return similarity >= threshold
}

const normalizeString = (str) => {
  const replacements: Record<string, string> = {
    // French
    'œ': 'oe',
    'Œ': 'oe',
    'æ': 'ae',
    'Æ': 'ae',
    'ç': 'c',
    // Spain
    'ñ': 'n',
    '¡': 'i',
    // German
    'ß': 'ss',
    // Nordic characters
    'ø': 'o',
    'å': 'a',
  }
  const normalized = str
    .split('')
    .map((char) => replacements[char] || char)
    .join('')
  return normalized
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
}

export function groupIngredientsInCategory(ingredientsByCategory, orderListStore) {
  // console.log("GROUP INGREDIENTS IN CATEGORY ingredientsByCategory:", ingredientsByCategory);
  return Object.keys(ingredientsByCategory).map((category) => {
    const allIngredients: Ingredient[] = orderListStore.getIngredientsByCategory(category).value
    const ingredientGroups = groupIngredients(allIngredients)
    return {
      name: category,
      id: ingredientsByCategory[category].id,
      ingredientGroups,
      icon: getIconForCategory(category),
    }
  })
}

export function groupIngredientsInDishes(ingredientsByDish, orderListStore) {
  // console.log("GROUP INGREDIENTS IN DISH ingredientsByDish:", ingredientsByDish);
  return Object.keys(ingredientsByDish).map((category) => {
    // console.log("category:", category);
    // console.log("ingredientsByDish[category]:", ingredientsByDish[category]);
    // const allIngredients: Ingredient[] = orderListStore.getIngredientsByCategory(category).value
    const ingredientGroups = groupIngredients(ingredientsByDish[category].ingredients)
    return {
      name: category,
      id: ingredientsByDish[category].dish_id ?? -1,
      ingredientGroups,
    }
  })
}

// export function groupIngredients(allIngredients: Ingredient[]): IngredientGroup[] {
//   const groupedByName: Record<string, IngredientGroup> = {}

//   allIngredients.forEach((ingredient) => {
//     const normalized = normalizeString(ingredient.name)

//     let foundGroup: IngredientGroup = { group: '', ingredients: null }

//     // Search for an existing group with sufficient similarity
//     Object.entries(groupedByName).some(([key, group]) => {
//       if (areWordsSimilar(group.group, ingredient.name)) {
//         foundGroup = group
//         return true
//       }
//       return false
//     })

//     if (foundGroup && foundGroup.ingredients) {
//       foundGroup.ingredients.push(ingredient)
//     } else {
//       groupedByName[normalized] = {
//         group: ingredient.name,
//         ingredients: [ingredient],
//       }
//     }
//   })

//   // Process groups to finalize structure
//   return Object.values(groupedByName).map((group) => ({
//     group: group.group,
//     ingredients: group.ingredients,
//   }))
// }

function areUnitsCompatible(unit1: string, unit2: string): boolean {
  const weightUnits = ['g', 'kg'];
  const volumeUnits = ['ml', 'l', 'cl', 'dl'];
  const pceUnits = ['pce', 'pces', 'pcs', 'piece', 'pieces'];
  
  if (unit1 === unit2) return true;
  
  // Check if both units are weight units
  if (weightUnits.includes(unit1) && weightUnits.includes(unit2)) return true;
  
  // Check if both units are volume units
  if (volumeUnits.includes(unit1) && volumeUnits.includes(unit2)) return true;

  // Check if both units are pieces
  if (pceUnits.includes(unit1) && pceUnits.includes(unit2)) return true;
  
  return false;
}

export function groupIngredients(allIngredients: Ingredient[] = [], mixUnits = true): IngredientsToOrder {
  console.log("function groupIngredients allIngredients:", allIngredients);
  const groupedByName: Record<string, Ingredient[]> = {}
  const ungroupedIngredients: Ingredient[] = []
  let nextGroupId = -(Date.now())

  allIngredients.forEach((ingredient) => {
    const normalized = normalizeString(ingredient.name)
    let foundGroup = false

    // Search for an existing group with sufficient similarity
    Object.entries(groupedByName).some(([key, ingredients]) => {
      const nameSimilar = areWordsSimilar(ingredients[0].name, ingredient.name);
      const unitsCompatible = mixUnits || areUnitsCompatible(ingredients[0].unit, ingredient.unit);
      
      if (nameSimilar && unitsCompatible) {
        groupedByName[key].push(ingredient)
        foundGroup = true
        return true
      }
      return false
    })

    if (!foundGroup) {
      const similarIngredients = allIngredients.filter(ing => {
        const nameSimilar = areWordsSimilar(ing.name, ingredient.name);
        const unitsCompatible = mixUnits || areUnitsCompatible(ing.unit, ingredient.unit);
        return nameSimilar && unitsCompatible;
      });

      if (similarIngredients.length > 1) {
        // Create new group if there are similar ingredients
        groupedByName[normalized] = [ingredient]
      } else {
        // Add to ungrouped if no similar ingredients exist
        ungroupedIngredients.push(ingredient)
      }
    }
  })

  // console.log("GROUPED BY NAME:", groupedByName);
  // Convert groups to the required format
  const groups = Object.entries(groupedByName).map(([key, ingredients]) => {
    const aggregatedInfo = aggregateGroupInfo({ ingredients })
    nextGroupId --
    return {
      id: nextGroupId,
      category: ingredients[0].category,
      name: ingredients[0].name,
      quantity: aggregatedInfo.totalQuantity,
      unit: aggregatedInfo.unit,
      price: ingredients[0].price,
      comment: ingredients[0].comment,
      // dish_name: ingredients[0].dish_name,
      // dish_id: ingredients[0].dish_id,
      ingredients: ingredients
    }
  })
  // console.log("GROUPS:", groups);

  return {
    ingredients: ungroupedIngredients,
    groups: groups.length > 0 ? groups : undefined
  }
}

// export function groupIngredientsInCategory(ingredientsByCategory: Record<string, Ingredient[]>, orderListStore: any): IngredientTreeNode[] {
//   return Object.keys(ingredientsByCategory).map(category => {
//     const allIngredients = orderListStore.getIngredientsByCategory(category).value;
//     const groupedByName: Record<string, TreeNode> = {};

//     allIngredients.forEach((ingredient: Ingredient) => {
//       const normalized = normalizeString(ingredient.name);
//       if (!groupedByName[normalized]) {
//         groupedByName[normalized] = {
//           key: ingredient.id.toString(),
//           data: { name: ingredient.name },
//           children: []
//         };
//       }
//       if (groupedByName[normalized].children) {
//         groupedByName[normalized].children.push({
//           key: `${ingredient.id}-${ingredient.name}`,
//           data: {
//             name: ingredient.name,
//             quantity: ingredient.quantity,
//             price: ingredient.price,
//             unit: ingredient.unit
//           }
//         });
//       }
//     });

//     const ingredientGroups: TreeNode[] = Object.values(groupedByName).map(group => ({
//       key: group.key,
//       data: { name: group.data.name },
//       children: group.children
//     }));

//     return {
//       key: category,
//       data: { name: category },
//       children: ingredientGroups,
//       icon: getIconForCategory(category)
//     };
//   });
// }
function getIconForCategory(category) {
  switch (category) {
    case 'meat':
      return TabMeats
    case 'fish':
      return TabFishes
    case 'fruits_veggies':
      return TabVegetables
    case 'dried_economat':
      return TabDry
    case 'dairy':
      return TabDairy
    case 'frozen':
      return TabFrozen
    case 'alcohol':
      return TabAlcohols
    case 'beverage':
      return TabDrinks
    case 'other':
      return TabOthers
    default:
      return null // Add a default icon or null if none
  }
}
