<script setup lang="ts">
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import Button from 'primevue/button'
import ButtonProfile from '@/assets/icons/Buttons/ButtonProfile.vue'
import { watchEffect } from 'vue'
import { useLayoutStore } from '@/stores/layoutStore'
import { useEnvStore } from '@/stores/envStore'
import { useUserStore } from '@/stores/useUserStore'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const route = useRoute()

watchEffect(() => {
  if (route.meta.headerConfig) {
    useLayoutStore().pageMeta.headerConfig = { ...route.meta.headerConfig }
  }
})
</script>

<template>
  <div class="container relative flex items-center justify-between h-24 print:hidden">
    <div v-if="useLayoutStore().pageMeta.headerConfig.showBack" class="z-10 flex items-center justify-start grow">
      <Button rounded link icon="pi pi-chevron-left" class="text-xl" :pt="{ root: { class: ' !w-8 !h-8' } }"
        :pt-options="{ mergeProps: true }" @click="$router.go(-1)" />
    </div>
    <h2 class="absolute left-0 right-0 w-full text-center">
      {{ t(`pageTitle.${useLayoutStore().pageMeta.headerConfig.headerLabel}`) }}
    </h2>
    <div v-if="useLayoutStore().pageMeta.headerConfig.showProfile" class="flex items-center justify-end grow">
      <Button rounded link :pt="{ root: { class: '!p-1.5 !w-10 !h-10' } }" :pt-options="{ mergeProps: true }"
        @click="$router.push({ name: 'profile' })">
        <template #icon>
          <ButtonProfile />
        </template>
      </Button>
    </div>
  </div>
</template>

<style scoped></style>
