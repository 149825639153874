import SupplierForm from '@/views/Suppliers/SupplierForm.vue'
import SupplierView from '@/views/Suppliers/SupplierView.vue'
import type { RouteRecordRaw } from 'vue-router'

const suppliersRoutes: RouteRecordRaw[] = [
  {
    path: '/suppliers/:uuid',
    name: 'supplier',
    meta: {
      headerConfig: {
        headerLabel: 'suppliers',
        hide: false,
        showBack: true,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    component: SupplierView,
  },
  {
    path: '/suppliers/:uuid/edit',
    name: 'edit-supplier',
    meta: {
      headerConfig: {
        headerLabel: 'suppliers',
        hide: false,
        showBack: true,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
        restaurantRoleAllowed: ['admin', 'manager'],
      },
      containerMargins: true,
    },
    props: true,
    component: SupplierForm,
  },
  {
    path: '/suppliers/create',
    name: 'create-supplier',
    meta: {
      headerConfig: {
        headerLabel: 'suppliers',
        hide: false,
        showBack: true,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
        restaurantRoleAllowed: ['admin', 'manager'],
      },
      containerMargins: true,
    },
    component: SupplierForm,
  },
]

export default suppliersRoutes
