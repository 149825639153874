<script setup lang="ts">
import { ref, nextTick, watch, computed } from 'vue';
import html2canvas from 'html2canvas-pro';
import { useUserStore } from '@/stores/useUserStore'
import type { ShareData } from '@/types/share';

const { user } = useUserStore()

const restaurant = computed(() => user?.restaurants[0])

const props = defineProps<{
  shareData: ShareData;
  format: string; // e.g., 'storyMainDish', 'postFull'
  nutritionalData?: {
    calories_kcal: number;
    protein_g: number;
    fat_g: number;
    saturated_fat_g: number;
    carbohydrates_g: number;
    sugars_g: number;
    dietary_fiber_g: number;
    salt_sodium_g: number;
  } | null;
}>();

const emit = defineEmits<{
  (e: 'imageGenerated', imageDataUrl: string): void;
}>();

const imageDataUrl = ref('');
const loadingShare = ref(false);

const shareFormats = [
  { name: 'storyStarter', id: 'share-story', width: 1080, height: 1920 },
  { name: 'storyMainDish', id: 'share-story', width: 1080, height: 1920 },
  { name: 'storyDessert', id: 'share-story', width: 1080, height: 1920 },
  { name: 'storyFull', id: 'share-story', width: 1080, height: 1920 },
  { name: 'postMainDish', id: 'share-post', width: 1080, height: 1080 },
  { name: 'postFull', id: 'share-post', width: 1080, height: 1080 },
  { name: 'postDessert', id: 'share-post', width: 1080, height: 1080 },
  { name: 'postStarter', id: 'share-post', width: 1080, height: 1080 },
];

async function generateImage() {
  loadingShare.value = true;
  try {
    await nextTick();

    if (props.shareData.imageSrc) {
      await new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(true);
        img.onerror = () => reject(new Error('Image failed to load'));
        img.src = props.shareData.imageSrc;
      });
    }

    const selectedFormat = shareFormats.find((format) => format.name === props.format);
    if (!selectedFormat) return;

    const element = document.getElementById(selectedFormat.id);
    if (!element) return;

    element.style.display = 'block';
    const canvas = await html2canvas(element, { scale: 1, useCORS: true, width: selectedFormat.width, height: selectedFormat.height });
    element.style.display = 'none';
    imageDataUrl.value = canvas.toDataURL('image/png');
    emit('imageGenerated', imageDataUrl.value);
  } catch (error) {
    console.error('Error generating image:', error);
  } finally {
    loadingShare.value = false;
  }
}

watch(() => props.format, () => {
  if (props.format) generateImage();
}, { immediate: true });

const formattedUrl = (url: string) => url.replace(/^https?:\/\//, '');

const getDishNameSizeClass = (text: string) => {
  const length = text.length;
  console.log("Length: ", length);
  if (length > 61) return 'text-5xl';
  return 'text-6xl';
};
</script>

<template>
  <div>

    <div id="share-story"
      class="relative menu-card w-[1080px] h-[1920px] border-2 border-slay-200 hidden absolute -left-[5000px] top-0">
      <div v-if="shareData.title" class="h-[150px] mt-[135px] px-24 w-[1080px] flex flex-col justify-center">
        <h1 class="leading-tight text-7xl text-primary-900 line-clamp-1">{{ shareData.title }}</h1>
        <h2 v-if="shareData.subtitle" class="text-4xl text-primary-900">{{ shareData.subtitle }}</h2>
      </div>
      <div class="w-[900px] mx-auto" :class="{ 'mt-[135px]': !shareData.title }">
        <div class="relative w-full mx-auto mb-8 rounded-3xl">
          <div
            class="rounded-full bg-primary-100 absolute -top-16 -right-[4.5rem] w-[180px] aspect-square flex justify-center align-middle">
          </div>
          <div
            class="absolute flex justify-center align-middle bg-white rounded-full -top-16 -right-16 w-44 aspect-square">
            <img v-if="restaurant?.logo" :src="restaurant.logo" class="object-contain w-32 h-32 p-2 m-auto" />
          </div>
          <img v-if="shareData.imageSrc" :src="shareData.imageSrc" class="object-cover w-full h-[780px] rounded-3xl" />
        </div>
        <div v-if="shareData.items.length === 1"
          class="flex max-w-[900px] h-[430px] mx-auto flex-col items-center justify-center gap-11 self-stretch mb-20">
          <p class="w-11/12 mx-auto text-6xl italic font-bold leading-normal text-center font-garamond line-clamp-3">
            {{ shareData.items[0].name }}
          </p>
          <!-- <div v-if="shareData.price" class="h-0.5 w-14 bg-gray-300 my-2 mx-auto"></div> -->
          <p v-if="shareData.price" class="w-full text-6xl italic font-bold text-center font-garamond">{{
            shareData.price }}</p>
        </div>
        <div v-else
          class="flex max-w-[900px] h-[600px] mx-auto flex-col items-center justify-center gap-5 self-stretch mb-10">
          <div class="flex flex-col justify-between w-full gap-4">
            <p class="w-11/12 mx-auto text-5xl italic font-bold leading-tight text-center font-garamond line-clamp-2">
              {{shareData.items.find(item => item.order === 1)?.name || '***' }}
            </p>
            <div class="h-0.5 w-10 bg-gray-300 my-2 mx-auto"></div>
            <p class="w-11/12 mx-auto italic font-bold leading-tight text-center font-garamond line-clamp-3"
            :class="getDishNameSizeClass(shareData.items.find(item => item.order === 2)?.name || '***')">
              {{shareData.items.find(item => item.order === 2)?.name || '***'}}
            </p>
            <div class="h-0.5 w-10 bg-gray-300 my-2 mx-auto"></div>
            <p class="w-11/12 mx-auto text-5xl italic font-bold leading-tight text-center font-garamond line-clamp-2">
              {{shareData.items.find(item => item.order === 3)?.name || '***'}}
            </p>
          </div>
          <div v-if="shareData.price" class="mt-2">
            <!-- <div class="h-0.5 w-14 bg-gray-300 mx-auto my-1"></div> -->
            <p class="w-full text-6xl italic font-bold text-center font-garamond">{{ shareData.price }}</p>
          </div>
        </div>
        <!-- <div v-if="nutritionalData">
          <div class="h-0.5 w-14 bg-gray-300 mx-auto my-1"></div>
          <div class="flex flex-col items-center gap-2">
            <p class="text-4xl font-bold text-primary-900">Nutritional Information</p>
            <div class="flex flex-col items-center gap-1">
              <p class="text-3xl">
                <span class="font-bold">Calories:</span> {{ nutritionalData.calories_kcal }} kcal
              </p>
              <p class="text-3xl">
                <span class="font-bold">Protein:</span> {{ nutritionalData.protein_g }} g
              </p>
              <p class="text-3xl">
                <span class="font-bold">Fat:</span> {{ nutritionalData.fat_g }} g
              </p>
              <p class="text-3xl">
                <span class="font-bold">Saturated Fat:</span> {{ nutritionalData.saturated_fat_g }} g
              </p>
              <p class="text-3xl">
                <span class="font-bold">Carbohydrates:</span> {{ nutritionalData.carbohydrates_g }} g
              </p>
              <p class="text-3xl">
                <span class="font-bold">Sugars:</span> {{ nutritionalData.sugars_g }} g
              </p>
              <p class="text-3xl">
                <span class="font-bold">Dietary Fiber:</span> {{ nutritionalData.dietary_fiber_g }} g
              </p>
              <p class="text-3xl">
                <span class="font-bold">Salt/Sodium:</span> {{ nutritionalData.salt_sodium_g }} g
              </p>
             </div> 
          </div>
        </div> -->
        <footer class="absolute left-0 flex flex-row items-end justify-end w-full mx-auto gap-7 bottom-16">
          <div class="absolute bottom-0 flex flex-col items-center left-12 h-28">
            <img src="@/assets/images/avatar-head-transparent.svg" alt="Logo restaurant"
              class="object-contain w-20 h-20 p-1" />
            <p class="w-full text-2xl leading-tight text-center text-primary-700">Powered by</p>
            <p class="w-full text-2xl font-bold text-center text-primary-700">GastronomIA</p>
          </div>
          <div class="flex flex-col items-end text-2xl text-right shrink-0">
            <p class="w-full text-4xl italic font-garamond">{{ restaurant?.name }}</p>
            <p v-if="restaurant?.phone_number">{{ restaurant.phone_number }}</p>
            <p v-if="restaurant?.website_url">{{ formattedUrl(restaurant.website_url) }}</p>
          </div>
          <div v-if="restaurant?.logo" class="mr-12 w-28 h-28">
            <img :src="restaurant.logo" alt="Logo restaurant" class="object-contain w-full h-full p-1" />
          </div>
        </footer>
      </div>
    </div>


    <div id="share-post"
      class="relative menu-card w-[1080px] h-[1080px] border-2 border-slay-200 hidden absolute -left-[5000px] top-0">
      <div v-if="shareData.title" class="h-[150px] mt-[50px] px-24 w-[1080px] flex flex-col justify-center">
        <h1 class="leading-tight text-7xl text-primary-900 line-clamp-1">{{ shareData.title }}</h1>
        <h2 v-if="shareData.subtitle" class="text-4xl text-primary-900">{{ shareData.subtitle }}</h2>
      </div>
      <div class="w-[900px] mx-auto" :class="{ 'mt-[50px]': !shareData.title }">
        <div :class="{ 'h-[830px]': !shareData.title, 'h-[700px]': shareData.title }"
          class="flex max-w-[900px] mx-auto flex-col items-center justify-center gap-11 self-stretch mb-20">
          <p v-if="shareData.items.length === 1"
            class="w-11/12 mx-auto text-6xl italic font-bold leading-normal text-center font-garamond line-clamp-3">
            {{ shareData.items[0].name }}
          </p>
          <div v-else class="flex flex-col justify-between w-full gap-4">
            <p class="w-11/12 mx-auto text-5xl italic font-bold leading-tight text-center font-garamond line-clamp-2">
              {{shareData.items.find(item => item.order === 1)?.name || '***' }}
            </p>
            <div class="h-0.5 w-10 bg-gray-300 my-2 mx-auto"></div>
            <p class="w-11/12 mx-auto italic font-bold leading-tight text-center font-garamond line-clamp-3"
            :class="getDishNameSizeClass(shareData.items.find(item => item.order === 2)?.name || '***')">
              {{ shareData.items.find(item => item.order === 2)?.name || '***' }}
            </p>
            <div class="h-0.5 w-10 bg-gray-300 my-2 mx-auto"></div>
            <p class="w-11/12 mx-auto text-5xl italic font-bold leading-tight text-center font-garamond line-clamp-2">
              {{ shareData.items.find(item => item.order === 3)?.name || '***' }}
            </p>
          </div>
          <div v-if="shareData.price !== ''">
            <!-- <div class="h-0.5 w-14 bg-gray-300 my-2 mx-auto"></div> -->
            <p class="w-full text-6xl italic font-bold text-center font-garamond">{{ shareData.price }}</p>
          </div>
        </div>
        <footer class="absolute left-0 flex flex-row items-end justify-end w-full mx-auto gap-7 bottom-16">
          <div class="absolute bottom-0 flex flex-col items-center left-12 h-28">
            <img src="@/assets/images/avatar-head-transparent.svg" alt="Logo restaurant"
              class="object-contain w-20 h-20 p-1" />
            <p class="w-full text-2xl leading-tight text-center text-primary-700">Powered by</p>
            <p class="w-full text-2xl font-bold text-center text-primary-700">GastronomIA</p>
          </div>
          <div class="flex flex-col items-end text-2xl text-right shrink-0">
            <p class="w-full text-4xl italic font-garamond">{{ restaurant?.name }}</p>
            <p v-if="restaurant?.phone_number">{{ restaurant.phone_number }}</p>
            <p v-if="restaurant?.website_url">{{ formattedUrl(restaurant.website_url) }}</p>
          </div>
          <div v-if="restaurant?.logo" class="mr-12 w-28 h-28">
            <img :src="restaurant.logo" alt="Logo restaurant" class="object-contain w-full h-full p-1" />
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>