import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(duration)
dayjs.extend(utc)
dayjs.extend(timezone)

// Took from timbreuse project
function formatTime(time: number): string {
  // console.log("time: ", time)
  time = Math.abs(time)
  const duration = dayjs.duration(time, 'seconds')
  const hoursDigits = duration.days() * 24 + duration.hours() >= 99 ? 3 : 2
  const hours = String(duration.hours() + duration.days() * 24).padStart(hoursDigits, '0')
  const minutes = String(duration.minutes()).padStart(2, '0')
  const seconds = String(duration.seconds()).padStart(2, '0')

  return `${hours}:${minutes}:${seconds}`
}

function formatBalance(balance: number) {
  const sign = balance < 0 ? '-' : '+'
  return sign + formatTime(balance)
}

/**
 * Convert UTC date string to user's local timezone
 * @param dateString Date string in UTC format
 * @returns Date string in local timezone
 */
function toLocalTime(dateString: string): Date {
  if (!dateString) return new Date()
  return dayjs.utc(dateString).local().toDate()
}

/**
 * Convert local date to UTC string format for API requests
 * @param date Date object or string in local timezone
 * @returns ISO string in UTC timezone
 */
function toUTCString(date: Date | string): string {
  return dayjs(date).utc().format()
}

/**
 * Get current time in UTC format for API requests
 * @returns ISO string in UTC timezone
 */
function getCurrentUTCTime(): string {
  return dayjs().utc().format()
}

/**
 * Format a date object or string for display in local timezone
 * @param date Date object or string
 * @param format Format string (defaults to 'YYYY-MM-DD HH:mm:ss')
 * @returns Formatted date string in local timezone
 */
function formatLocalDateTime(date: Date | string, format: string = 'YYYY-MM-DD HH:mm:ss'): string {
  return dayjs(date).format(format)
}

/**
 * Calculate time difference in seconds between two date strings
 * @param startTimeString UTC start time string
 * @param endTimeString UTC end time string (defaults to now if not provided)
 * @returns Time difference in seconds
 */
function getTimeDifferenceInSeconds(startTimeString: string, endTimeString?: string): number {
  const startTime = dayjs.utc(startTimeString).local()
  const endTime = endTimeString
    ? dayjs.utc(endTimeString).local()
    : dayjs().local()

    console.log("startTime: ", startTime)
    console.log("endTime: ", endTime)

  return endTime.diff(startTime, 'second')
}

/**
 * Format date to YYYY-MM-DD format in local timezone
 * @param date Date object or string (defaults to current date)
 * @returns Date string in YYYY-MM-DD format
 */
function formatLocalDate(date?: Date | string): string {
  if (!date) {
    return dayjs().format('YYYY-MM-DD')
  }
  return dayjs(date).format('YYYY-MM-DD')
}

export {
  formatTime,
  formatBalance, 
  toLocalTime, 
  getTimeDifferenceInSeconds, 
  formatLocalDate,
  formatLocalDateTime,
  toUTCString,
  getCurrentUTCTime
}