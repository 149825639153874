<script setup lang="ts">
import LoadingView from '../LoadingView.vue'
import { useFetch } from '@/composables/useFetch.js'
import { useEnvStore } from '@/stores/envStore.js'
import { useUserStore } from '@/stores/useUserStore'
import type { OrderHistoryResponse } from '@/types/api.js'
import type { Ingredient } from '@/types/dish'
import type { Order } from '@/types/order.js'
import { onMounted, onBeforeUnmount, nextTick, ref, type Ref } from 'vue'
import Button from 'primevue/button'
import type { OrderHistoryState } from '@/types/history'
import OrderCard from '@/components/Orders/OrderCard.vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const { fetchData } = useFetch()
const apiUrl = useEnvStore().apiUrl
const userStore = useUserStore()
const restaurantId = userStore.user?.restaurants[0].id

const orders: Ref<Order[]> = ref([])
const isLoading = ref(false)
const nextPageUrl = ref(`${apiUrl}/restaurants/${restaurantId}/orders`)
const loadedOrders = ref(0)
const totalOrders = ref(0)

const { t } = useI18n()
const router = useRouter()

async function fetchOrders() {
  if (!restaurantId) {
    console.error('No restaurant ID found')
    return
  }

  isLoading.value = true

  try {
    const { data, error } = await fetchData(nextPageUrl.value, 'GET')
    if (error) {
      console.error('Error fetching orders:', error)
    } else {
      const response = data as OrderHistoryResponse
      console.log('Orders response:', response)
      orders.value = [...orders.value, ...response.data]
      loadedOrders.value = response.to
      totalOrders.value = response.total
      nextPageUrl.value = response.next_page_url || ''
    }
  } catch (e) {
    console.error('Exception while fetching orders:', e)
  } finally {
    isLoading.value = false
  }
}

function saveState() {
  const stateToSave: OrderHistoryState = {
    orders: orders.value,
    loadedOrders: loadedOrders.value,
    totalOrders: totalOrders.value,
    nextPageUrl: nextPageUrl.value,
    scrollPosition: window.scrollY,
  }
  sessionStorage.setItem('historyOrderState', JSON.stringify(stateToSave))
}

function restoreState() {
  orders.value = []
  const savedState = sessionStorage.getItem('historyOrderState')
  if (savedState) {
    const state: OrderHistoryState = JSON.parse(savedState)
    orders.value = state.orders
    loadedOrders.value = state.loadedOrders
    totalOrders.value = state.totalOrders
    nextPageUrl.value = state.nextPageUrl
    nextTick(() => {
      window.scrollTo(0, state.scrollPosition)
    })
    sessionStorage.removeItem('historyOrderState')
  } else {
    fetchOrders()
  }
}

onMounted(() => {
  fetchOrders()
  // if (userStore.shouldRefetchOrders) {
  //   sessionStorage.removeItem('historyOrderState')
  //   userStore.shouldRefetchOrders = false
  //   orders.value = []
  //   fetchOrders()
  // } else {
  //   restoreState()
  // }
})

onBeforeUnmount(() => {
  saveState()
})

// Date format : dd.mm.yyyy
function formatDate(dateString: string) {
  const date = new Date(dateString)
  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
}

function calculateOrderTotal(ingredients: Ingredient[]) {
  return ingredients.reduce((sum, ing) => sum + ing.price, 0).toFixed(2)
}

function navigateToOrderDetail(orderId: number) {
  router.push(`/profile/orders/${orderId}`)
}

</script>

<template>
  <main class="p-4">
    <LoadingView v-if="isLoading && orders.length === 0" />
    <div v-else-if="orders.length === 0">
      <h1 class="mb-4 text-2xl font-bold">{{ t('orders.ordersHistory') }}</h1>
      <p>{{ t('orders.noOrders.label') }}</p>
      <p>{{ t('orders.noOrders.orderToStart') }}</p>
    </div>
    <div v-else>
      <h1 class="mb-4 text-2xl font-bold">{{ t('orders.ordersHistory') }}</h1>
      <div class="grid gap-4">
        <OrderCard
          v-for="order in orders"
          :key="order.id"
          :title="order.supplier? order.supplier.name : t('orders.noSupplier')"
          :subtitle="t('orders.orderNb', {orderNb: order.id})"
          :comment="`${t('orders.orderForm.wantedDate')} ${formatDate(order.wanted_date)}`"
          @click="navigateToOrderDetail(order.id)"
        />
      </div>
      <div class="mt-4 text-center">
        <Button v-if="loadedOrders < totalOrders" @click="fetchOrders" :loading="isLoading" label="Load More" />
      </div>
    </div>
  </main>
</template>

<style scoped>
</style>
