import { setLocale } from '@/i18n'
import { defineStore } from 'pinia'
import { ref, type Ref, onMounted } from 'vue'

export const useAppStore = defineStore('app', () => {
  const showPrompt = ref(false);


  // Try to get initial language from different sources in this priority:
  // 1. localStorage (returning user preference)
  // 2. Browser language if supported
  // 3. Default to 'en'
  function getInitialLanguage(): 'fr' | 'en' | 'de' | 'it' {
    const savedLanguage = localStorage.getItem('language') as 'fr' | 'en' | 'de' | 'it' | null
    if (savedLanguage && ['fr', 'en', 'de', 'it'].includes(savedLanguage)) {
      return savedLanguage
    }

    
    const browserLanguage = navigator.language || navigator.languages[0]
    const languageCode = browserLanguage?.split('-')[0]
    if (['fr', 'en', 'de', 'it'].includes(languageCode)) {
      return languageCode as 'fr' | 'en' | 'de' | 'it'
    }
    
    return 'en'
  }
  
  const appReady: Ref<boolean> = ref(false)
  const language: Ref<'fr' | 'en' | 'de' | 'it'> = ref(getInitialLanguage())
  
  // Apply initial language
  setLocale(language.value)
  document.documentElement.lang = language.value
  localStorage.setItem('language', language.value)

  function setLanguage(newLanguage: 'fr' | 'en' | 'de' | 'it') {
    setLocale(newLanguage);
    language.value = newLanguage
    localStorage.setItem('language', newLanguage)

    document.documentElement.lang = newLanguage
  }

  /// PWA

  const deferredPrompt = ref<Event | null>(null)
  const isPwaInstalled = ref(false)

  // Detect if device is on iOS
  function isIOS() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }

  // Detect if device is in standalone mode
  function isInStandaloneMode() {
    return 'standalone' in window.navigator && (window.navigator as any).standalone === true;
  }

  function checkIfInstalled() {
    // Check if running in standalone mode (installed PWA)
    const isInStandaloneMode = window.matchMedia('(display-mode: standalone)').matches
    // Check for iOS standalone mode
    const isInIOSStandaloneMode = 'standalone' in window.navigator && window.navigator.standalone === true

    isPwaInstalled.value = isInStandaloneMode || isInIOSStandaloneMode
    return isPwaInstalled.value
  }

  function shouldShowIOSInstructions() {
    // Show iOS instructions if:
    // 1. User is on iOS
    // 2. App is not in standalone mode
    // 3. User hasn't dismissed the instructions
    const hasSeenInstructions = localStorage.getItem('pwaPromptDismissed') === 'true';
    return isIOS() && !isInStandaloneMode() && !hasSeenInstructions;
  }

  async function installPwa() {
    if (!deferredPrompt.value) return false

    // Show the install prompt
    await (deferredPrompt.value as any).prompt()
    const { outcome } = await (deferredPrompt.value as any).userChoice
    console.log(`User response: ${outcome}`)

    deferredPrompt.value = null
    return outcome === 'accepted'
  }

  return { 
    appReady, 
    language, 
    setLanguage, 
    deferredPrompt, 
    isPwaInstalled, 
    isIOS,
    isInStandaloneMode,
    checkIfInstalled,
    installPwa,
    showPrompt,
    shouldShowIOSInstructions
  }
})
