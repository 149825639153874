<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import type { Dish } from '@/types/dish'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { computed } from 'vue';

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const props = defineProps<{
  dish: Dish | null,
  small?: boolean
}>()

const cardClasses = computed(() => ({
  'p-4': !props.small,
  'p-0': props.small,
  'border': !props.small,
  'border-gray-200': !props.small,
  'rounded-md': !props.small,
  'shadow-md': !props.small,
  'max-w-md': !props.small,
  'max-w-xs': props.small,
  'mx-auto': !props.small
}))

const textClasses = computed(() => ({
  'text-lg': !props.small,
  'text-sm': props.small,
  'font-bold': true,
  'mb-4': !props.small,
  'mb-0.5': props.small
}))

const tableClasses = computed(() => ({
  'w-full': true,
  'text-sm': !props.small,
  'text-[0.7rem]': props.small,
  'space-y-2': !props.small,
  'space-y-0': props.small
}))

const cellClasses = computed(() => ({
  'py-2': !props.small,
  'py-0': props.small
}))
</script>

<template>
  <div v-if="dish" :class="cardClasses">
    <div class="p-3 mb-3 border rounded bg-primary-100 border-primary-400 text-primary-700 print:hidden">
      <p><i class="pi pi-info-circle"></i> {{ t('dish.nutritional.aiGeneratedNotice') }}</p>
    </div>
    <h1 :class="textClasses">{{ t('dish.nutritional.forOneServing') }}</h1>

    <table :class="tableClasses">
      <tbody>
        <tr class="border-b border-gray-300">
          <td :class="[cellClasses, 'font-medium']">{{ t('dish.nutritional.calories') }}</td>
          <td class="text-right">{{ dish.calories_kcal }} kcal</td>
        </tr>
        <tr class="border-b border-gray-300">
          <td :class="[cellClasses, 'font-medium']">{{ t('dish.nutritional.protein') }}</td>
          <td class="text-right">{{ dish.protein_g }} g</td>
        </tr>
        <tr class="border-b border-gray-300">
          <td :class="[cellClasses, 'font-medium']">{{ t('dish.nutritional.fat') }}</td>
          <td class="text-right">{{ dish.fat_g }} g</td>
        </tr>
        <tr class="border-b border-gray-300">
          <td :class="[cellClasses, 'font-medium']">{{ t('dish.nutritional.saturatedFat') }}</td>
          <td class="text-right">{{ dish.saturated_fat_g }} g</td>
        </tr>
        <tr class="border-b border-gray-300">
          <td :class="[cellClasses, 'font-medium']">{{ t('dish.nutritional.carbohydrates') }}</td>
          <td class="text-right">{{ dish.carbohydrates_g }} g</td>
        </tr>
        <tr class="border-b border-gray-300">
          <td :class="[cellClasses, 'font-medium']">{{ t('dish.nutritional.sugars') }}</td>
          <td class="text-right">{{ dish.sugars_g }} g</td>
        </tr>
        <tr class="border-b border-gray-300">
          <td :class="[cellClasses, 'font-medium']">{{ t('dish.nutritional.dietaryFiber') }}</td>
          <td class="text-right">{{ dish.dietary_fiber_g }} g</td>
        </tr>
        <tr>
          <td :class="[cellClasses, 'font-medium']">{{ t('dish.nutritional.salt') }}</td>
          <td class="text-right">{{ dish.salt_sodium_g }} g</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div :class="{ 'mt-6 mb-4': !small, 'mt-1 mb-1': small }">
    <h1 :class="textClasses">{{ t('dish.allergens.title') }}</h1>
    <p v-if="!dish?.allergens" :class="{ 'text-sm': !small, 'text-xs': small }">{{ t('dish.allergens.noAllergens') }}
    </p>
    <p v-else :class="{ 'text-sm': !small, 'text-xs': small }">{{ dish?.allergens }}</p>
  </div>
</template>

<style scoped></style>
