<script setup lang="ts">
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import Button from 'primevue/button'
import { useRouter } from 'vue-router'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const router = useRouter()
const route = useRoute()

// useRoute().meta.headerConfig.headerLabel = t('pageTitle.email-verified')
</script>

<template>
  <main>
    <div class="flex flex-col items-center justify-center space-y-4">
      <img src="@/assets/images/avatar-rounded.webp" :alt="t('homeView.altImageAvatar')" class="w-1/4 max-w-32 mx-auto">
      <div v-if="route.query.verified === '1'" class="text-center">
        <p>{{ t('auth.emailVerification.success') }}</p>
        <Button :label="t('auth.emailVerification.button')" class="mt-4"
          @click="router.push({ name: 'home' })"></Button>
      </div>
      <div v-else-if="route.query.alreadyVerified === '1'" class="text-center">
        <p>{{ t('auth.emailVerification.alreadyVerified') }}</p>
        <Button :label="t('auth.emailVerification.button')" class="mt-4"
          @click="router.push({ name: 'home' })"></Button>
      </div>
      <div v-else class="text-center">
        <p>{{ t('auth.emailVerification.error') }}</p>
        <Button :label="t('auth.emailVerification.button')" class="mt-4"
          @click="router.push({ name: 'home' })"></Button>
      </div>
    </div>
  </main>
</template>
