<script setup lang="ts">
import Sidebar from 'primevue/sidebar'
import Button from 'primevue/button'
import { ref } from 'vue'
import type { ModelRef } from 'vue';
import { useI18n } from 'vue-i18n';

const visible: ModelRef<boolean | undefined> = defineModel('visible', { required: true })
const { t } = useI18n();
const props = defineProps<{
  header: string,
  cameraText?: string,
  galleryText?: string
}>()

const emit = defineEmits<{
  (e: 'update:visible', value: boolean): void,
  (e: 'file-uploaded', payload: { type: 'camera' | 'gallery', file: File }): void,
}>()

const cameraInputRef = ref<HTMLInputElement | null>(null)
const galleryInputRef = ref<HTMLInputElement | null>(null)

const openCameraInput = () => {
  cameraInputRef.value?.click()
}

const openGalleryInput = () => {
  galleryInputRef.value?.click()
}

const onCameraFileChange = (event: Event) => {
  const input = event.target as HTMLInputElement
  if (!input.files || !input.files.length) return
  const file = input.files[0]
  emit('file-uploaded', { type: 'camera', file })
  emit('update:visible', false)
  input.value = ''
}

const onGalleryFileChange = (event: Event) => {
  const input = event.target as HTMLInputElement
  if (!input.files || !input.files.length) return
  const file = input.files[0]
  emit('file-uploaded', { type: 'gallery', file })
  emit('update:visible', false)
  input.value = ''
}

const onCameraClick = () => {
  openCameraInput()
}

const onGalleryClick = () => {
  openGalleryInput()
}
</script>

<template>
  <Sidebar v-model:visible="visible" :header="header" position="bottom" style="height: auto" :block-scroll="true" class="w-full md:w-3/4 lg:w-1/2">
    <div class="flex items-center gap-2 my-2 justify-evenly">
      <div class="flex flex-col items-center gap-2">
        <Button rounded @click="onCameraClick" style="width: 60px; height: 60px;">
          <span class="text-xl pi pi-camera"></span>
        </Button>
        <p class="text-xs text-center">{{ props.cameraText || t('common.camera') }}</p>
      </div>
      <div class="flex flex-col items-center gap-2">
        <Button rounded @click="onGalleryClick" style="width: 60px; height: 60px;">
          <span class="text-xl pi pi-images"></span>
        </Button>
        <p class="text-xs text-center">{{ props.galleryText || t('common.gallery') }}</p>
      </div>
    </div>
    <!-- Hidden file inputs -->
    <input ref="cameraInputRef" type="file" accept="image/*" capture="environment" class="hidden" @change="onCameraFileChange" />
    <input ref="galleryInputRef" type="file" accept="image/*" class="hidden" @change="onGalleryFileChange" />
  </Sidebar>
</template>
