export type PermissionCategory =
  'dishes' |
  'orders' |
  'users' |
  'restaurants' |
  'roles' |
  'permissions';

export type Permission = {
  [key in PermissionCategory]: string[];
};

// Define all possible permissions by category
export const PERMISSIONS: Permission = {
  dishes: ['dishes_view', 'dishes_create', 'dishes_update', 'dishes_delete'],
  orders: ['orders_view', 'orders_create', 'orders_update', 'orders_delete'],
  users: ['users_view', 'users_create', 'users_update', 'users_delete'],
  restaurants: ['restaurants_view', 'restaurants_create', 'restaurants_update', 'restaurants_delete'],
  roles: ['roles_view', 'roles_create', 'roles_update', 'roles_delete'],
  permissions: ['permissions_view', 'permissions_create', 'permissions_update', 'permissions_delete'],
};

// Helper functions
export function getPermission(category: PermissionCategory, action: string): string {
  const permissionName = `${category}_${action}`;
  if (PERMISSIONS[category]?.includes(permissionName)) {
    return permissionName;
  }
  throw new Error(`Permission ${permissionName} does not exist`);
}

export function getCategoryPermissions(category: PermissionCategory): string[] {
  return PERMISSIONS[category] || [];
}