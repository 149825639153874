import { useEnvStore } from '@/stores/envStore'
import { useFetch } from './useFetch'
import i18n from '@/i18n'
import type { Dish } from '@/types/dish'
import { useDishesStore } from '@/stores/dishesStore'

const { fetchData } = useFetch()

const { t } = i18n.global

export const useDishes = () => {
  async function deleteDish(dishId: number): Promise<boolean> {
    const url = useEnvStore().apiUrl + '/dishes/' + dishId + '/delete'
    const { error } = await fetchData(url, 'DELETE')
    if (error) {
      console.log(error)
      return false
    }
    useDishesStore().shouldRefetch = true;
    return true
  }

  /**
   * 
   * @param dishId id of the dish to duplicate
   * @param nameSuffixTranslation suffix to add to the dish name (e.g. 'copy'), only the "translated" version. If not provided, no suffix will be added.
   * @returns 
   */
  async function duplicateDish(dishId: number, nameSuffixTranslation: string = ''): Promise<null | Dish> {
    const url = useEnvStore().apiUrl + '/dishes/' + dishId + '/duplicate'
    const name_suffix = nameSuffixTranslation ? t(`common.${nameSuffixTranslation}`) : ''
    const { data, error } = await fetchData(url, 'POST', { name_suffix })
    if (error) {
      console.log(error)
      return null
    }
    return data as Dish
  }

  async function toggleFavorite(dishId: number): Promise<null | boolean> {
    const url = useEnvStore().apiUrl + '/dishes/' + dishId + '/favorite'
    const { data, error } = await fetchData(url, 'PATCH')
    if (error) {
      console.log(error)
      return null
    }
    return data.is_favorite
  }

  async function setDishTemplate(dishId: number, toast: any): Promise<boolean> {
    const url = useEnvStore().apiUrl + '/admin/dish/' + dishId + '/template'
    const { error } = await fetchData(url, 'PATCH')
    if (error) {
      console.log(error)
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: 'Une erreur est survenue lors de la mise à jour du plat en tant que modèle',
        life: 3000
      })
      return false
    }
    toast.add({
      severity: 'success',
      summary: t('common.success'),
      detail: 'Le plat a été mis à jour en tant que modèle',
      life: 3000
    })
    return true
  }

  async function setDishTagTemplate(tagId: number, toast: any): Promise<boolean> {
    const url = useEnvStore().apiUrl + '/admin/dish-tag/' + tagId + '/template'
    const { error } = await fetchData(url, 'PATCH')
    if (error) {
      console.log(error)
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: 'Une erreur est survenue lors de la mise à jour du classeur en tant que modèle',
        life: 3000
      })
      return false
    }
    toast.add({
      severity: 'success',
      summary: t('common.success'),
      detail: 'Le classeur a été mis à jour en tant que modèle',
      life: 3000
    })
    return true
  }

  return { deleteDish, duplicateDish, toggleFavorite, setDishTemplate, setDishTagTemplate }
}
