<script setup lang="ts">
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import { ref, onMounted, computed, type Ref, reactive, watch, type ModelRef } from 'vue'
import { useOrderList } from '@/stores/orderStore'
import { useEnvStore } from '@/stores/envStore'
import { useFetch } from '@/composables/useFetch'
import { useToast } from 'primevue/usetoast'
import InlineIngredient from '@/components/Orders/InlineIngredient.vue'
import type { Ingredient } from '@/types/dish'
import EditIngredient from '@/components/Orders/EditIngredient.vue'
import { useDishesStore } from '@/stores/dishesStore'
import Button from 'primevue/button'
import { useConfirm } from 'primevue/useconfirm'
import { aggregateGroupInfo, groupIngredientsInCategory } from '@/utils/orders'
import Checkbox from 'primevue/checkbox'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/useUserStore'
import LoadingView from '@/views/LoadingView.vue'

const orderListStore = useOrderList()
const { t, te } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const toast = useToast()
const dishesStore = useDishesStore()
const ingredientUnits = ref(dishesStore.units)
const confirm = useConfirm()
const router = useRouter()

const selectedIngredients = defineModel<Ingredient[]>('selectedIngredients', { required: true })
// const props = defineProps<{
//   selectedIngredients: Ingredient[]
// }>()

const emit = defineEmits<{
  (e: 'add-or-update-ingredient', data: { ingredient: Ingredient | null, index: number | null, category: string | null }): void
  // (e: 'update:selectedIngredients', ingredients: Ingredient[]): void
}>()

// const restaurantId = ref()
// const user = ref(useUserStore().user)
// const envStore = useEnvStore()
const { isFetching, fetchData } = useFetch()
const ingredientToEdit: Ref<Ingredient> = ref({
  id: -1,
  category: 'other',
  name: '',
  quantity: 0,
  unit: '',
  price: 0,
})
const ingredientToEditIndex = ref(-1)
// const showSideBar: Ref<boolean> = ref(false)

// onMounted(async () => {
//   //TODO : use real restaurantId
//   if (!user.value) return
//   if (user.value.restaurants.length == 0) {
//     toast.add({
//       severity: 'error',
//       summary: t('common.error'),
//       detail: t('auth.user.noRestaurantConnected'),
//       life: 3000,
//     })
//     return
//   }

//   if (!ingredientUnits.value.length) {
//     const { data, error } = await fetchData(envStore.apiUrl + `/units/default`, 'GET')
//     if (error) {
//       console.log('error', error)
//     } else {
//       ingredientUnits.value = data
//     }
//   }

//   // TODO: handle if user is in many restaurants
//   restaurantId.value = user.value.restaurants[0].id
//   console.log('Test, orderliststore: ', orderListStore.ingredientsByCategory)
//   if (!orderListStore.ingredientsList.length) {
//     await orderListStore.fetchIngredients(restaurantId.value)
//     console.log('Ingredients List:', orderListStore.ingredientsList)

//     console.log(orderListStore.ingredientsByCategory)
//   }
//   // fetchIngredients(restaurantId.value);
// })

const handleUpdateSelection = ({ ingredient, isChecked }) => {
  const currentSelected = [...selectedIngredients.value];
  const index = currentSelected.findIndex((i) => i.id === ingredient.id)
  if (isChecked && index === -1) {
    currentSelected.push(ingredient)
  } else if (!isChecked && index !== -1) {
    currentSelected.splice(index, 1)
  }
  // emit('update:selectedIngredients', currentSelected)
  selectedIngredients.value = currentSelected
}

const categoriesWithIngredients = computed(() => {
  return groupIngredientsInCategory(orderListStore.ingredientsByCategory, orderListStore)
})

function addOrUpdateIngredient({
  ingredient = null,
  index = null,
  category = null,
}: {
  ingredient?: Ingredient | null
  index?: number | null
  category: string | null
}) {
  if (ingredient && index !== null) {
    console.log('open sidebar - edit ingredient', ingredient, index)
    ingredientToEdit.value = { ...ingredient }
    ingredientToEditIndex.value = index
  } else {
    category = category ? category : 'other'
    ingredientToEdit.value = {
      id: -1,
      category: category,
      name: '',
      quantity: 0,
      unit: '',
      price: 0,
    }
    ingredientToEditIndex.value = -1
    console.log('open sidebar -   update ingredient', ingredient, index)
  }
  emit('add-or-update-ingredient', { ingredient, index, category })
}

// const deleteIngredient = async () => {
//   orderListStore.removeIngredients([ingredientToEdit.value.id], restaurantId.value).then(() => {
//     ingredientToEdit.value = {
//       id: -1,
//       category: 'other',
//       name: '',
//       quantity: 0,
//       unit: 'g',
//       price: 0,
//     }
//   })
// }

// const updateIngredient = async () => {
//   console.log('Update ingredient')
//   console.log(ingredientToEdit.value)
//   orderListStore.updateIngredient(ingredientToEdit.value, restaurantId.value).then(() => {
//     ingredientToEdit.value = {
//       id: -1,
//       category: 'other',
//       name: '',
//       quantity: 0,
//       unit: 'g',
//       price: 0,
//     }
//   })
// }

// const addIngredient = async () => {
//   console.log('add ingredient')
//   orderListStore.addIngredients([ingredientToEdit.value], restaurantId.value).then(() => {
//     ingredientToEdit.value = {
//       id: -1,
//       category: 'other',
//       name: '',
//       quantity: 0,
//       unit: '',
//       price: 0,
//     }
//   })
// }

const expandedGroups = reactive(new Set())

function toggleGroup(groupName: string) {
  if (expandedGroups.has(groupName)) {
    expandedGroups.delete(groupName)
  } else {
    expandedGroups.add(groupName)
  }
}

// const confirmDeleteSelectedIngredients = (event) => {
//   confirm.require({
//     target: event.currentTarget as HTMLElement,
//     message: t('ingredients.deleteSelectedIngredients.popupLabel'),
//     icon: 'pi pi-exclamation-triangle',
//     acceptLabel: t('ingredients.deleteSelectedIngredients.confirm'),
//     rejectLabel: t('ingredients.deleteSelectedIngredients.cancel'),
//     accept: () => {
//       if (!user.value || user.value.restaurants.length === 0) {
//         toast.add({
//           severity: 'error',
//           summary: t('common.error'),
//           detail: t('auth.user.noRestaurantConnected'),
//           life: 3000,
//         })
//         return
//       }
//       deleteSelectedIngredients(selectedIngredients.value)
//     },
//     reject: () => {
//       console.log('reject')
//     },
//   })
// }

// function deleteSelectedIngredients(listIngredientsToDelete) {
//   console.log('Delete ingredients', listIngredientsToDelete)

//   const ingredientIds = listIngredientsToDelete.map((ingredient) => ingredient.id)

//   if (user.value && user.value.restaurants.length > 0) {
//     const restaurantId = user.value.restaurants[0].id
//     orderListStore.removeIngredients(ingredientIds, restaurantId)
//     selectedIngredients.value = []
//   } else {
//     toast.add({
//       severity: 'error',
//       summary: t('common.error'),
//       detail: t('auth.user.noRestaurantConnected'),
//       life: 3000,
//     })
//   }
// }

// function compareIngredients() {
//   console.log('should compare Ingredients. To add in a next phase.')
//   orderListStore.addIngredientsToOrder(selectedIngredients.value, true);
//   router.push({ name: 'order-form' })
// }

const categorySelections = reactive({})

categoriesWithIngredients.value.forEach((category) => {
  categorySelections[category.name] = false
})


function isSelected(ingredient) {
  return selectedIngredients.value.some((i) => i.id === ingredient.id)
}

const groupSelections = reactive({})

// Update the groupSelections initialization
categoriesWithIngredients.value.forEach(category => {
  category.ingredientGroups.groups?.forEach(group => {
    groupSelections[group.name] = group.ingredients.every(ingredient => isSelected(ingredient));
  });
});

// Add a watch to keep group selections in sync
watch(
  () => selectedIngredients.value,
  () => {
    console.log('watch selectedIngredients', selectedIngredients.value)
    // Update categorySelections: check for both direct and grouped ingredients.
    Object.keys(categorySelections).forEach((categoryName) => {
      const category = categoriesWithIngredients.value.find((c) => c.name === categoryName)
      if (category) {
        const directSelected = category.ingredientGroups.ingredients
          ? category.ingredientGroups.ingredients.every(ingredient =>
              selectedIngredients.value.some(i => i.id === ingredient.id)
            )
          : true
        const groupsSelected = category.ingredientGroups.groups
          ? category.ingredientGroups.groups.every(group =>
              group.ingredients.every(ingredient =>
                selectedIngredients.value.some(i => i.id === ingredient.id)
              )
            )
          : true
        categorySelections[categoryName] = directSelected && groupsSelected
      }
    })

    // Update groupSelections: check each group's ingredients.
    categoriesWithIngredients.value.forEach((category) => {
      category.ingredientGroups.groups?.forEach((group) => {
        groupSelections[group.name] = group.ingredients.every((ingredient) =>
          isSelected(ingredient)
        )
      })
    })
  },
  { deep: true }
)


function toggleGroupSelection(categoryName, groupName) {
  // Find the category and group
  const category = categoriesWithIngredients.value.find(c => c.name === categoryName);
  const group = category?.ingredientGroups.groups?.find(g => g.name === groupName);
  console.log("category: ", category, "group: ", group);
  if (!group) return;

  console.log("Group selections: ", groupSelections);
  const isCurrentlySelected = groupSelections[groupName];

  console.log("IS CURRENTLY SELECTED: ", isCurrentlySelected);

  // Toggle all ingredients in the group
  group.ingredients.forEach(ingredient => {
    const index = selectedIngredients.value.findIndex(i => i.id === ingredient.id);
    console.log("INDEX : ", index);
    if (!isCurrentlySelected && index === -1) {
      console.log("ADDING INGREDIENT");
      // Add ingredient if group is being selected
      selectedIngredients.value.push(ingredient);
    } else if (isCurrentlySelected && index !== -1) {
      console.log("REMOVING INGREDIENT");
      // Remove ingredient if group is being deselected
      selectedIngredients.value.splice(index, 1);
    }
    console.log("SELECTED INGREDIENTS: ", selectedIngredients.value);
  });

  // Update group selection state
  groupSelections[groupName] = !isCurrentlySelected;
}


function toggleCategory(categoryName) {
  console.log('toggle category ', categoryName)
  const isSelected = categorySelections[categoryName]
  const category = categoriesWithIngredients.value.find((c) => c.name === categoryName)
  console.log('category ', category)
  if (!category) return

  // Toggle selection for direct ingredients
  category.ingredientGroups.ingredients?.forEach((ingredient) => {
    const ingredientIndex = selectedIngredients.value.findIndex((i) => i.id === ingredient.id)
    if (isSelected && ingredientIndex === -1) {
      selectedIngredients.value.push(ingredient)
    } else if (!isSelected && ingredientIndex !== -1) {
      selectedIngredients.value.splice(ingredientIndex, 1)
    }
  })

  // Toggle selection for grouped ingredients
  category.ingredientGroups.groups?.forEach((group) => {
    group.ingredients?.forEach((ingredient) => {
      const ingredientIndex = selectedIngredients.value.findIndex((i) => i.id === ingredient.id)
      if (isSelected && ingredientIndex === -1) {
        selectedIngredients.value.push(ingredient)
      } else if (!isSelected && ingredientIndex !== -1) {
        selectedIngredients.value.splice(ingredientIndex, 1)
      }
    })
  })
}

</script>

<template>
  <main>
    <div v-if="isFetching && !categoriesWithIngredients.length">
      <LoadingView :message="t('orders.loadingLabel')" :generation="false" />
    </div>
    <div v-else>
      <Accordion :activeIndex="0" :multiple="true">
        <AccordionTab v-for="category in categoriesWithIngredients" :key="category.name">
          <template #header>
            <div class="flex items-center gap-3">
              <component :is="category.icon" class="w-5 h-5" />
              <span class="text-sm leading-5">
                {{ t(`orders.ingredientsCategories.${category.name}`) }}
                <span class="font-normal">
                  ({{ (category.ingredientGroups.ingredients?.length || 0) +
                    (category.ingredientGroups.groups?.reduce((sum, group) => sum + (group.ingredients?.length || 0), 0)
                      || 0) }})
                </span>
              </span>
            </div>
          </template>
          <div class="flex items-center justify-end gap-3 my-2 italic" v-if="category.ingredientGroups.groups?.length || category.ingredientGroups.ingredients?.length">
            <label :for="`select-all-${category.name}`" class="ml-2 text-sm hover:cursor-pointer text-primary-950">{{
              t('common.selectAll') }}</label>
            <div class="flex items-center justify-center flex-none w-6 py-2">
              <Checkbox v-model="categorySelections[category.name]" @change="toggleCategory(category.name)"
                :binary="true" :inputId="`select-all-${category.name}`" />
            </div>
          </div>

          <!-- Individual ingredients section -->
          <div v-if="category.ingredientGroups.ingredients?.length">
            <div v-for="(ingredient, index) in category.ingredientGroups.ingredients" :key="ingredient.id">
              <InlineIngredient :ingredient="ingredient" :index="index"
                :description="ingredient.dish_name || ingredient.comment || ''"
                @update-selection="handleUpdateSelection"
                @edit-ingredient="addOrUpdateIngredient({ ingredient, index, category: category.name })"
                :selected-ingredients="selectedIngredients" />
            </div>
          </div>

          <!-- Grouped ingredients section -->
          <div v-if="category.ingredientGroups.groups?.length">
            <div v-for="group in category.ingredientGroups.groups" :key="group.name">
              <div
                class="relative flex items-center py-2 text-sm border-b border-gray-100 hover:cursor-pointer text-primary-950 min-w-52">
                <div class="flex items-center flex-1 min-w-0" @click="toggleGroup(group.name)">
                  <i :class="{
                    'pi pi-chevron-down': expandedGroups.has(group.name),
                    'pi pi-chevron-right': !expandedGroups.has(group.name),
                    'w-5': true,
                  }"></i>
                  <div class="flex flex-1 min-w-0">
                    <div class="flex-1 min-w-0">
                      <p class="min-w-0 text-sm truncate text-primary-950">
                        {{ group.name }}
                      </p>
                    </div>
                    <div class="flex flex-none gap-1 w-28">
                      <p class="flex-none w-16 text-right">
                        {{ group.quantity }}
                      </p>
                      <span class="text-right truncate w-7">{{
                        te(`ingredients.unit.symbol.${group.unit}`)
                          ? t(`ingredients.unit.symbol.${group.unit}`)
                          : group.unit
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="flex items-center justify-center flex-none w-6 py-2">
                  <Checkbox :model-value="groupSelections[group.name]"
                    @change="toggleGroupSelection(category.name, group.name)" :binary="true" />
                </div>
              </div>

              <div v-if="expandedGroups.has(group.name)">
                <div v-for="(ingredient, index) in group.ingredients" :key="ingredient.id">
                  <InlineIngredient :ingredient="ingredient" :index="index"
                    :description="ingredient.dish_name || ingredient.comment || ''"
                    @update-selection="handleUpdateSelection"
                    @edit-ingredient="addOrUpdateIngredient({ ingredient, index, category: category.name })"
                    class="ml-10" :selected-ingredients="selectedIngredients" />
                </div>
              </div>
            </div>
          </div>

          <div v-if="!category.ingredientGroups.ingredients?.length && !category.ingredientGroups.groups?.length">
            <p class="text-sm italic">{{ t('orders.noProduct') }}</p>
          </div>

          <!-- <div class="hover:cursor-pointer"
            @click="addOrUpdateIngredient({ ingredient: null, index: null, category: category.name })">
            <p>+ {{ t('orders.addProduct') }}</p>
          </div> -->
        </AccordionTab>
      </Accordion>
      <!-- <EditIngredient v-model:ingredient="ingredientToEdit" :index="ingredientToEditIndex" v-model:visible="showSideBar"
        @add-ingredient="addIngredient" @edit-ingredient="updateIngredient" @delete-ingredient="deleteIngredient" /> -->

      <!-- <div class="sticky z-50 flex gap-3 px-4 py-2 bg-white bottom-16"
        v-if="!isFetching || categoriesWithIngredients.length">
        <Button icon="pi pi-trash" aria-label="Delete" class="text-center" size="small"
          :disabled="!selectedIngredients.length"
          @click="!selectedIngredients.length ? null : confirmDeleteSelectedIngredients($event)" severity="danger" />
        <Button class="flex-1 text-center" size="small" :disabled="!selectedIngredients.length"
          @click="compareIngredients">
          {{ t('orders.createPurchaseOrder') }}
        </Button>
      </div> -->
    </div>
  </main>
</template>
