import { ref } from 'vue'
import axios from 'axios'
import { useFetch } from './useFetch'
import { useEnvStore } from '@/stores/envStore'
import { formatLocalDate, toUTCString } from '@/utils/time'

export function useEmployee() {
  const { fetchData } = useFetch()
  const isLoading = ref(false)
  const error = ref(null)
  const envStore = useEnvStore();

  /**
   * Start a new working period for the employee
   * @param restaurant_user_id The ID of the employee
   * @returns Object containing the created working period data or error
   */
  async function startWorkingPeriod (restaurant_user_id: number, options?: { start_time ?: Date | string }) {
    isLoading.value = true
    error.value = null

    try {

      const payload: any = { restaurant_user_id}

      if (options && options.start_time) {
        payload.start_time = toUTCString(options.start_time)
      }

      const { data, error: fetchError } = await fetchData(
        `${envStore.apiUrl}/working-periods/${restaurant_user_id}/start`,
        'POST',
        payload
      )

      isLoading.value = false

      if (fetchError) {
        return {
          data: null,
          error: fetchError
        }
      }

      return {
        data: data?.working_period || data,
        error: null
      }
    } catch (err: any) {
      isLoading.value = false
      error.value = err.message || 'An error occurred'

      return {
        data: null,
        error: error.value
      }
    }
  }

  /**
   * End a working period for the employee
   * @param restaurant_user_id The ID of the employee
   * @param options Additional options like end_time or last_period_of_day
   * @returns Object containing the ended working period data or error
   */
  const endWorkingPeriod = async (
    restaurant_user_id: number,
    options?: { end_time?: string, last_period_of_day?: boolean }
  ) => {
    isLoading.value = true
    error.value = null

    try {
      // Build the payload with restaurant_user_id
      const payload: any = { restaurant_user_id }

      // If there's an end_time, convert it to UTC
      if (options?.end_time) {
        payload.end_time = toUTCString(options.end_time)
      }

      // Add any additional options
      if (options?.last_period_of_day !== undefined) {
        payload.last_period_of_day = options.last_period_of_day
      }

      const { data, error: fetchError } = await fetchData(
        `${envStore.apiUrl}/working-periods/${restaurant_user_id}/end`,
        'POST',
        payload
      )

      isLoading.value = false

      if (fetchError) {
        return {
          data: null,
          error: fetchError
        }
      }

      return {
        data: data?.working_period || data,
        error: null
      }
    } catch (err: any) {
      isLoading.value = false
      error.value = err.message || 'An error occurred'

      return {
        data: null,
        error: error.value
      }
    }
  }

  /**
   * Get working periods for an employee
   * @param restaurant_user_id The ID of the employee
   * @param options Optional date filters
   * @returns Object containing the working periods or error
   */
  const getWorkingPeriods = async (
    restaurant_user_id: number,
    options?: { start_date?: string, end_date?: string }
  ) => {
    isLoading.value = true
    error.value = null

    try {
      let url = `${envStore.apiUrl}/working-periods/${restaurant_user_id}`

      // Add query parameters if provided
      if (options) {
        const params = new URLSearchParams()
        if (options.start_date) params.append('start_date', formatLocalDate(new Date(options.start_date)))
        if (options.end_date) params.append('end_date', formatLocalDate(new Date(options.end_date)))

        if (params.toString()) {
          url += `?${params.toString()}`
        }
      }

      const { data, error: fetchError } = await fetchData(url, 'GET')

      isLoading.value = false

      if (fetchError) {
        return {
          data: null,
          error: fetchError
        }
      }

      return {
        data,
        error: null
      }
    } catch (err: any) {
      isLoading.value = false
      error.value = err.message || 'An error occurred'

      return {
        data: null,
        error: error.value
      }
    }
  }

  /**
   * 
   * @param restaurant_user_id restaurant_user id - connection between the user and the restaurant
   * @returns balance of the users (how many hours they worked)
   */
  async function getBalance(restaurant_user_id: number) {
    isLoading.value = true
    error.value = null

    try {
      const { data, error: fetchError } = await fetchData(
        `${envStore.apiUrl}/working-periods/${restaurant_user_id}/balance`,
        'GET'
      )

      isLoading.value = false

      if (fetchError) {
        return {
          data: null,
          error: fetchError
        }
      }

      return {
        data,
        error: null
      }
    } catch (err: any) {
      isLoading.value = false
      error.value = err.message || 'An error occurred'

      return {
        data: null,
        error: error.value
      }
    }
  }

  async function getLastWorkingPeriod(restaurant_user_id: number) {
    isLoading.value = true
    error.value = null

    try {
      const { data, error: fetchError } = await fetchData(
        `${envStore.apiUrl}/working-periods/${restaurant_user_id}/last`,
        'GET'
      )

      isLoading.value = false

      if (fetchError) {
        return {
          data: null,
          error: fetchError
        }
      }

      return {
        data,
        error: null
      }
    } catch (err: any) {
      isLoading.value = false
      error.value = err.message || 'An error occurred'

      return {
        data: null,
        error: error.value
      }
    }
  }

  return {
    isLoading,
    error,
    startWorkingPeriod,
    endWorkingPeriod,
    getWorkingPeriods,
    getBalance,
    getLastWorkingPeriod
  }
}