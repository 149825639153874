<script setup lang="ts">
import LoadingView from '../LoadingView.vue'
import { useFetch } from '@/composables/useFetch.js'
import { useEnvStore } from '@/stores/envStore.js'
import type { DishHistoryResponse, DishTag } from '@/types/api.js'
import Button from 'primevue/button'
import { computed, nextTick, onMounted, ref, watch, watchEffect, type Ref } from 'vue'
import AutoComplete from 'primevue/autocomplete'
import Checkbox from 'primevue/checkbox'
import type { MessageSchema } from '@/i18n'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import type { Dish } from '@/types/dish.js'
import type { DishHistoryState } from '@/types/history.js'
import { useToast } from 'primevue/usetoast'
import HistoryCard from '@/components/Profile/HistoryCard.vue'
import { useProfileDishLists } from '@/composables/useProfileDishLists.js'
import { useDishesStore } from '@/stores/dishesStore'
import { usePlanningStore } from '@/stores/planningStore'
import Menu from 'primevue/menu'
import ButtonMenu from '@/assets/icons/Buttons/ButtonMenu.vue'
import { useConfirm } from 'primevue/useconfirm'
import Textarea from 'primevue/textarea'
import ButtonClose from '@/assets/icons/Buttons/ButtonClose.vue'
import ButtonConfirm from '@/assets/icons/Buttons/ButtonConfirm.vue'
import { useUserStore } from '@/stores/useUserStore'

const { t, te } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

// add props when it's needed, with tagId as a prop
const props = defineProps({
  tagId: {
    type: Number,
    required: true,
  },
})

const parentTag = computed(() => {
  if (!tag.value || !tag.value.parent_dish_tag_id) return ''

  const parent = dishesStore.value.tags.find(
    (t: DishTag) => t.id === tag.value?.parent_dish_tag_id
  )

  if (!parent) return ''

  // Translate if a translation is available, otherwise use raw name
  let parentName = parent.name
  if (te(`dishTag.tagNames.${parentName}`)) {
    parentName = t(`dishTag.tagNames.${parentName}`)
  }

  return parentName
})

const router = useRouter()
const route = useRoute();
const toast = useToast()
const user = ref(useUserStore().user);

const confirmDialog = useConfirm();

const { fetchData } = useFetch()

const apiUrl = useEnvStore().apiUrl

const searchQuery = ref('')
const isSearching = ref(false)
const dishesStore = ref(useDishesStore())
const tagId = computed(() => props.tagId ?? parseInt(route.params.tagId.toString()))
const tag: Ref<DishTag | null> = ref(null);
const restaurantId = user.value?.restaurants[0]?.id || null
const newTagName = ref('')
const isEditingTag = ref(false)


const createdUsing: Ref<string[]> = ref(['ocr', 'ia'])

const isFetchingList = ref(false)

const nextPageUrl = ref(`${apiUrl}/dishes/tags/${restaurantId}/${tagId.value}`)

const loadedDishes = ref(0)
const totalDishes = ref(0)
const planningStore = usePlanningStore()

const { dishes, handleDuplication, handleDeletion, handleFavoriteChange } =
  useProfileDishLists(toast)

function handleCardClick(id: number) {
  if (planningStore.choosingDishToPlan) {
    emit('addDishToPlan', dishes.value.find((dish) => dish.id === id) as Dish)
    return
  } else {
    saveState()
    router.push(`/menu/dishes/${id}`)
  }
}

// When the users wants to add a dish from the planning.
const emit = defineEmits<{
  (e: 'addDishToPlan', dish: Dish): void
}>()

watchEffect(() => console.log(dishes.value.length))

async function getDishes() {
  // This var is needed because the isFetching var from composable
  // Makes a conflict between the getDishes and the searchDishes methods
  isFetchingList.value = true

  const { data, error } = await fetchData(nextPageUrl.value, 'GET')
  if (error) {
    // console.log('error', error)
  } else {
    const response = data.dishes as DishHistoryResponse
    tag.value = data.tag as DishTag
    // console.log('response', response)
    dishes.value = [...dishes.value, ...response.data]
    loadedDishes.value = response.to
    totalDishes.value = response.total
    nextPageUrl.value = response.next_page_url ?? ''
  }

  isFetchingList.value = false
}

// This method is only triggered when we type in the serach bar or when the checkboxes are clicked.
// The pagination is the handled by getDishes
async function filterDishes() {
  // isSearching is needed to display search icon when the method is triggered with the watcher
  isSearching.value = true

  // const searchUrl = `${apiUrl}/dishes/search?name=${encodeURIComponent(searchQuery.value)}`
  const searchUrl = `${apiUrl}/dishes/tags/${restaurantId}/${tagId.value}/search?${new URLSearchParams({
    name: searchQuery.value,
    created_using: createdUsing.value.join(','),
    is_planned: 'false',
  })}`

  const { data, error } = await fetchData(searchUrl, 'GET')

  if (error) {
    console.log('error', error)
  } else {
    const response = data.dishes as DishHistoryResponse
    tag.value = data.tag
    dishes.value = response.data
    loadedDishes.value = response.to
    totalDishes.value = response.total
    nextPageUrl.value = response.next_page_url ?? ''
  }
  isSearching.value = false
}

const scrollPosition = ref(0)

function saveState() {
  const stateToSave: DishHistoryState = {
    dishes: dishes.value,
    loadedDishes: loadedDishes.value,
    totalDishes: totalDishes.value,
    nextPageUrl: nextPageUrl.value,
    scrollPosition: window.scrollY,
    searchQuery: searchQuery.value,
    createdUsing: createdUsing.value,
  }
  sessionStorage.setItem('dishesTagState', JSON.stringify(stateToSave))
  sessionStorage.setItem('savedTagSelected', JSON.stringify(tag.value))
}

function restoreState() {
  dishes.value = []
  const savedState = sessionStorage.getItem('dishesTagState')
  const savedTag = sessionStorage.getItem('savedTagSelected');
  if (savedState) {
    const state: DishHistoryState = JSON.parse(savedState)
    dishes.value = state.dishes
    console.log(dishes.value)
    loadedDishes.value = state.loadedDishes
    totalDishes.value = state.totalDishes
    nextPageUrl.value = state.nextPageUrl
    scrollPosition.value = state.scrollPosition
    searchQuery.value = state.searchQuery
    createdUsing.value = state.createdUsing || [] // Provide default empty array if not found
    console.log(scrollPosition.value)
    nextTick(() => {
      window.scrollTo(0, scrollPosition.value)
    })
    sessionStorage.removeItem('dishesTagState')
    sessionStorage.removeItem('savedTagSelected')

    tag.value = JSON.parse(savedTag as string)
  } else {
    getDishes()
  }
}

const tagOptions = ref()
const toggleTagOptions = (event: any) => {
  tagOptions.value.toggle(event)
}

const isUncategorizedTag = computed(() => {
  return tag.value?.name === 'uncategorized';
});

const tagName = computed(() => {
  return te(`dishTag.tagNames.${tag.value?.name}`) ? t(`dishTag.tagNames.${tag.value?.name}`) : tag.value?.name;
  // if (tag.value?.name === 'uncategorized') {
  //   return t('dishTag.uncategorized');
  // }
  // return tag.value?.name || '';
});

const tagOptionsItem = ref([
  {
    items: [
      {
        label: t('menuDishView.topMenu.rename'),
        icon: 'pi pi-pencil',
        command: () => {
          newTagName.value = tag.value?.name ?? ''
          isEditingTag.value = true
        },
        disabled: isUncategorizedTag.value,
      },
      {
        label: t('menuDishView.topMenu.delete'),
        icon: 'pi pi-trash',
        command: () => confirmDeleteTag(),
        disabled: isUncategorizedTag.value,
      },
    ],
  },
])

const confirmDeleteTag = () => {
  confirmDialog.require({
    message: t('dishTag.delete.confirmDelete'),
    header: t('dishTag.delete.confirmDelete'),
    icon: 'pi pi-exclamation-triangle',
    rejectClass: 'p-button-secondary p-button-outlined',
    rejectLabel: t('common.cancel'),
    acceptLabel: t('common.delete'),
    accept: () => {
      deleteTag();
    },
    reject: () => { }
  });
};

async function deleteTag() {
  if (!tag.value || tag.value.name === 'uncategorized') return;

  await dishesStore.value.deleteTag(tag.value.id, restaurantId);
  router.go(-1);
}

async function editTag() {
  if (!tag.value || tag.value.name === 'uncategorized') return;

  tag.value.name = newTagName.value;
  await dishesStore.value.editTag(tag.value, restaurantId);
  isEditingTag.value = false;

}

watch(searchQuery, () => {
  if (searchQuery.value === '') {
    filterDishes()
  }
})

onMounted(() => {
  if (dishesStore.value.shouldRefetch) {
    // console.log('Should refetch !')
    sessionStorage.removeItem('dishesTagState');
    sessionStorage.removeItem('savedTagSelected');
    dishesStore.value.shouldRefetch = false
    dishes.value = []
    getDishes()
  } else {
    console.log('restoreState')
    restoreState()
  }
})
</script>

<template>
  <main>
    <div v-if="!isEditingTag" class="flex justify-between gap-2 items-center">
      <div v-if="tag" class="flex flex-row gap-2 items-center">
        <!-- <p class="font-bold my-4"> {{ t('dishTag.label') }} :</p> -->
        <h1 class="text-2xl my-2">
          <span v-if=" parentTag" class="bold">{{ parentTag }} | </span>
          <span class="font-semibold">{{ tagName }}</span>
        </h1>
      </div>
      <Menu ref="tagOptions" id="overlay_menu" :model="tagOptionsItem" :popup="true"
        :pt="{ submenuHeader: { class: '!p-0' } }" :pt-options="{ mergeProps: true }" />
      <Button link rounded :pt="{ root: { class: '!p-1.5 !w-8 !h-8' } }" :pt-options="{ mergeProps: true }"
        @click="toggleTagOptions" :disabled="!tag || tag.name === 'uncategorized'">
        <template #icon>
          <ButtonMenu />
        </template>
      </Button>
    </div>
    <div v-else class="flex justify-between gap-2 items-top">
      <Textarea class="w-full" row="2" v-model="newTagName" />
      <Button link rounded :pt="{ root: { class: '!p-1.5 !w-8 !h-8' } }" :pt-options="{ mergeProps: true }"
        @click="isEditingTag = false">
        <template #icon>
          <ButtonClose />
        </template>
      </Button>
      <Button link rounded :pt="{ root: { class: '!py-1.5 !px-0 !w-12 !h-8' } }" :pt-options="{ mergeProps: true }"
        @click="editTag">
        <template #icon>
          <ButtonConfirm />
        </template>
      </Button>
    </div>
    <div class="flex flex-col gap-2 mb-6">
      <AutoComplete v-model="searchQuery" :suggestions="dishes" :loading="isSearching" @complete="filterDishes"
        panel-class="hidden" :placeholder="t('common.searchPlaceHolder')" />
      <div class="flex flex-row gap-6">
        <div class="flex flex-row items-center gap-2">
          <Checkbox v-model="createdUsing" inputId="ia" name="ia" value="ia" @change="filterDishes" />
          <label for="ia">{{ t('profile.dishLists.iaGenerated') }}</label>
        </div>
        <div class="flex flex-row items-center gap-2">
          <Checkbox v-model="createdUsing" inputId="ocr" name="ocr" value="ocr" @change="filterDishes" />
          <label for="ocr">{{ t('profile.dishLists.ocrGenerated') }}</label>
        </div>
      </div>
    </div>
    <HistoryCard v-for="dish in dishes" :key="dish.id" :dish="dish" @card-clicked="handleCardClick(dish.id)"
      @handle-deletion="(wasDeleted: boolean) => handleDeletion(wasDeleted, dish.id)" @handle-duplication="(duplicationResponse: null | Dish) => handleDuplication(duplicationResponse, dish.id)
        " @handle-favorite-change="(favoriteResponse: boolean | null) => handleFavoriteChange(favoriteResponse, dish.id)
          " />
    <Button v-if="loadedDishes < totalDishes" @click="getDishes()" :loading="isFetchingList && dishes.length !== 0"
      :label="t('common.seeMore')" />
    <LoadingView v-if="isFetchingList && dishes.length === 0" />
  </main>
</template>
