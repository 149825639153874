<script setup lang="ts">
import { RouterView, useRoute } from 'vue-router'
import TheNavBar from '@/components/NavBar/TheNavBar.vue'
import TheHeader from './components/Header/TheHeader.vue'
import Toast from 'primevue/toast'
import { computed, onMounted, ref } from 'vue'
// import { useI18n } from 'vue-i18n'
import { watchEffect } from 'vue'
import ConfirmPopup from 'primevue/confirmpopup'
import { useAppStore } from './stores/appStore.js'
import BaseLoader from '@/components/BaseComponents/BaseLoader.vue'
import { useUserStore } from '@/stores/useUserStore'
import CreditWarningDialog from './components/Dialog/CreditWarningDialog.vue'
import NoMoreCreditDialog from './components/Dialog/NoMoreCreditDialog.vue'
import PWAInstall from '@/components/Dialog/PWAInstall.vue';

// const { locale } = useI18n()

const isAppReady = computed(() => useAppStore().appReady)

watchEffect(() => {
  // console.log(locale.value)
  // console.log(isAppReady.value)
})

const route = ref(useRoute())

const toastPosition = computed(() => {
  return window.innerWidth < 768 ? 'top-right' : 'top-center'
})

const containerClass = computed(() => ({
  'mb-4': route.value.meta.footerConfig?.hide,
  'mb-20': !route.value.meta.footerConfig?.hide,
  'container': route.value.meta.containerMargins, // Apply 'container' class conditionally
}))

const userStore = useUserStore()

onMounted(() => {
  // Add event listener for beforeinstallprompt event
  useAppStore().checkIfInstalled()

  // Listen for installation ability
  window.addEventListener('beforeinstallprompt', (e) => {
    console.log("PWA can be installed - event captured at App level")
    e.preventDefault()
    useAppStore().deferredPrompt = e
  })

  // Listen for successful installation
  window.addEventListener('appinstalled', () => {
    console.log('PWA was installed successfully')
    useAppStore().deferredPrompt = null
    useAppStore().isPwaInstalled = true
  })
})
</script>

<template>
  <PWAInstall />
  <Toast :position="toastPosition" />
  <ConfirmPopup />
  <div class="max-w-[40rem] mx-auto">
    <TheHeader v-if="!route.meta.headerConfig?.hide && isAppReady" />
    <div class="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" v-if="!isAppReady">
      <BaseLoader />
    </div>
    <div v-else :class="containerClass">
      <RouterView />
    </div>
    <TheNavBar v-if="!route.meta.footerConfig?.hide && isAppReady" />
    <CreditWarningDialog 
      v-model:visible="userStore.showCreditWarning" 
      :credits-left="userStore.remainingCredits" 
    />
    <NoMoreCreditDialog
      v-model:visible="userStore.showNoMoreCreditDialog"
    />
  </div>
</template>
