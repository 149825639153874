import type { PermissionCategory } from "@/constants/permissions";
import { useUserStore } from "@/stores/useUserStore";

export function usePermissions() {
  const userStore = useUserStore();
  function can(category: PermissionCategory, action: string): boolean {
    return userStore.hasPermission(category, action);
  }

  function canAny(permissions: Array<{ category: PermissionCategory, action: string }>): boolean {
    return permissions.some(({ category, action }) => can(category, action));
  }

  function canAll(permissions: Array<{ category: PermissionCategory, action: string }>): boolean {
    return permissions.every(({ category, action }) => can(category, action));
  }

  return {
    can,
    canAny,
    canAll
  };
}