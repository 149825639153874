<script setup lang="ts">
import { defineProps, defineEmits, type ModelRef, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import Checkbox from 'primevue/checkbox'
import InputNumber from '@/components/BaseComponents/InputNumber.vue'
import type { Ingredient } from '@/types/dish'
import { watch } from 'vue'


const isSelected = ref(false);
const quantity = ref(0);

const props = defineProps<{
  ingredient: Ingredient
}>()

const theIngredient = ref(props.ingredient)

const emit = defineEmits(['toggle', 'update-ingredient'])
const { t, te } = useI18n()

const toggleIngredientSelection = (ingredient) => {
  emit('toggle', theIngredient.value)
  // emit('update-ingredient', ingredient)
}

const onQuantityChange = () => {
  theIngredient.value.is_selected = true;
  console.log("Update quantity of ingredient", theIngredient.value)
  emit('update-ingredient', theIngredient.value)
}

watch(() => props.ingredient, (newIngredient) => {
  theIngredient.value = newIngredient;
})
</script>

<template>
  <div class="flex w-full gap-3 pb-1 mb-4 text-sm border-b text-primary-950 border-primary-500">
    <div class="flex items-center flex-none w-3">
      <Checkbox v-model="theIngredient.is_selected" @click.prevent="() => toggleIngredientSelection(theIngredient)"
        :inputId="`checkbox-${theIngredient.id}`" :binary="true" />
    </div>
    <label :for="`checkbox-${theIngredient.id}`" class="flex flex-1 min-w-0 gap-1">
      <div class="flex-none w-24">
        <div class="flex items-center gap-1 text-left">
          <InputNumber v-model="theIngredient.quantity" @input="onQuantityChange"
            :placeholder="t('profileFavorites.creationForm.quantityPlaceholder')" class="flex-none w-16" />
          <span class="flex-1">
            {{ te(`ingredients.unit.symbol.${theIngredient.unit}`) ? t(`ingredients.unit.symbol.${theIngredient.unit}`)
              : ''
            }}
          </span>
        </div>
      </div>
      <div class="flex items-center flex-1 h-full min-w-0">
        <span class="truncate">{{ theIngredient.name }}</span>
      </div>
      <div class="flex items-center flex-none w-10">
        <span>{{ theIngredient.price }}</span>
      </div>
    </label>
  </div>
</template>
