<script setup lang="ts">
import { useOrderList } from '@/stores/orderStore';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import { useI18n } from 'vue-i18n';
import type { MessageSchema } from '@/i18n';
import InlineIngredient from '@/components/Orders/InlineIngredient.vue';
import type { Ingredient, IngredientShopping } from '@/types/dish';
import { computed, onMounted, reactive, ref, watch, type Ref } from 'vue';
import { groupIngredients, groupIngredientsInDishes } from '@/utils/orders';
import Checkbox from 'primevue/checkbox';

const orderListStore = useOrderList();
const { t, te } = useI18n<{ message: MessageSchema }>({ useScope: 'global' });

const selectedIngredients = defineModel<Ingredient[]>('selectedIngredients', { required: true });

// const expandedGroups = reactive(new Set());

const emit = defineEmits<{
  (e: 'add-or-update-ingredient', data: { ingredient: Ingredient | null, index: number | null, category: string | null }): void
}>()

const ingredientToEdit: Ref<Ingredient> = ref({
  id: -1,
  category: 'other',
  name: '',
  quantity: 0,
  unit: '',
  price: 0,
})


const ingredientToEditIndex = ref(-1)

// function toggleGroup(groupName: string) {
//   if (expandedGroups.has(groupName)) {
//     expandedGroups.delete(groupName);
//   } else {
//     expandedGroups.add(groupName);
//   }
// }

const groupedNoDishesIngredients = computed(() => {
  // console.log("groupedIngredients", orderListStore.ingredientsByDishes)
  const grouped = groupIngredients(orderListStore.ingredientsByDishes.noDish, true);
  return grouped;
});


const groupedPlannedDishesIngredients = computed(() => {

  // console.log("groupedPlannedDishesIngredients", orderListStore.ingredientsByDishes.plannedDishes)
  const grouped = groupIngredientsInDishes(orderListStore.ingredientsByDishes.plannedDishes, true);
  return grouped;
});

const groupedNotPlannedDishesIngredients = computed(() => {
  // console.log("groupedNotPlannedDishesIngredients", orderListStore.ingredientsByDishes.notPlannedDishes)
  const grouped = groupIngredientsInDishes(orderListStore.ingredientsByDishes.notPlannedDishes, true);
  return grouped;
});



const handleUpdateSelection = ({ ingredient, isChecked }) => {
  const currentSelected = [...selectedIngredients.value];
  const index = currentSelected.findIndex((i) => i.id === ingredient.id);
  if (isChecked && index === -1) {
    currentSelected.push(ingredient);
  } else if (!isChecked && index !== -1) {
    currentSelected.splice(index, 1);
  }
  selectedIngredients.value = currentSelected;
};


function addOrUpdateIngredient({
  ingredient = null,
  index = null,
  category = null,
}: {
  ingredient?: Ingredient | null
  index?: number | null
  category: string | null
}) {
  if (ingredient && index !== null) {
    console.log('open sidebar - edit ingredient', ingredient, index)
    ingredientToEdit.value = { ...ingredient }
    ingredientToEditIndex.value = index
  } else {
    category = category ? category : 'other'
    ingredientToEdit.value = {
      id: -1,
      category: category,
      name: '',
      quantity: 0,
      unit: '',
      price: 0,
    }
    ingredientToEditIndex.value = -1
    console.log('open sidebar -   update ingredient', ingredient, index)
  }
  emit('add-or-update-ingredient', { ingredient, index, category })
}


function isSelected(ingredient) {
  return selectedIngredients.value.some((i) => i.id === ingredient.id)
}
const groupPlannedDishesSelections = reactive({})
const groupNotPlannedDishesSelections = reactive({})
const groupNoDishesSelections = reactive({})

const noDishSelection = ref(false);
const dishSelections = reactive({});

function toggleDishSelection(dishName: string, dishId: number, isPlanned: boolean) {
  const dish = isPlanned
    ? groupedPlannedDishesIngredients.value.find(d => d.name === dishName)
    : groupedNotPlannedDishesIngredients.value.find(d => d.name === dishName);

  if (!dish) return;

  const newSelectionState = !dishSelections[dishId];
  dishSelections[dishId] = newSelectionState;

  // Get all ingredients from both individual and grouped ingredients
  const allIngredients = [
    ...(dish.ingredientGroups.ingredients || []),
    ...(dish.ingredientGroups.groups?.flatMap(group => group.ingredients) || [])
  ];

  allIngredients.forEach(ingredient => {
    const index = selectedIngredients.value.findIndex(i => i.id === ingredient.id);
    if (newSelectionState && index === -1) {
      // Add ingredient if selecting
      selectedIngredients.value.push(ingredient);
    } else if (!newSelectionState && index !== -1) {
      // Remove ingredient if deselecting
      selectedIngredients.value.splice(index, 1);
    }
  });

  // Update group selections based on the new state
  if (isPlanned) {
    dish.ingredientGroups.groups?.forEach(group => {
      groupPlannedDishesSelections[group.name] = newSelectionState;
    });
  } else {
    dish.ingredientGroups.groups?.forEach(group => {
      groupNotPlannedDishesSelections[group.name] = newSelectionState;
    });
  }
}

function toggleNoDishSelection() {
  const newSelectionState = !noDishSelection.value;
  noDishSelection.value = newSelectionState;

  const allNoDishIngredients = [
    ...(groupedNoDishesIngredients.value.ingredients || []),
    ...(groupedNoDishesIngredients.value.groups?.flatMap(group => group.ingredients) || [])
  ];

  allNoDishIngredients.forEach(ingredient => {
    const index = selectedIngredients.value.findIndex(i => i.id === ingredient.id);
    if (newSelectionState && index === -1) {
      selectedIngredients.value.push(ingredient);
    } else if (!newSelectionState && index !== -1) {
      selectedIngredients.value.splice(index, 1);
    }
  });

  // Update group selections
  groupedNoDishesIngredients.value.groups?.forEach(group => {
    groupNoDishesSelections[group.name] = newSelectionState;
  });
}

watch(() => selectedIngredients.value, () => {
  groupedPlannedDishesIngredients.value.forEach(dish => {
    dish.ingredientGroups.groups?.forEach(group => {
      groupPlannedDishesSelections[group.name] = group.ingredients.every(ingredient => isSelected(ingredient));
    });
    const allDishIngredients = [
      ...(dish.ingredientGroups.ingredients || []),
      ...(dish.ingredientGroups.groups?.flatMap(group => group.ingredients) || [])
    ];
    dishSelections[dish.id] = allDishIngredients.every(ingredient => isSelected(ingredient));
  });

  groupedNotPlannedDishesIngredients.value.forEach(dish => {
    dish.ingredientGroups.groups?.forEach(group => {
      groupNotPlannedDishesSelections[group.name] = group.ingredients.every(ingredient => isSelected(ingredient));
    });
    const allDishIngredients = [
      ...(dish.ingredientGroups.ingredients || []),
      ...(dish.ingredientGroups.groups?.flatMap(group => group.ingredients) || [])
    ];
    dishSelections[dish.id] = allDishIngredients.every(ingredient => isSelected(ingredient));
  });

  groupedNoDishesIngredients.value.groups?.forEach(group => {
    groupNoDishesSelections[group.name] = group.ingredients.every(ingredient => isSelected(ingredient));
  });

  // Update noDishSelection state
  const allNoDishIngredients = [
    ...(groupedNoDishesIngredients.value.ingredients || []),
    ...(groupedNoDishesIngredients.value.groups?.flatMap(group => group.ingredients) || [])
  ];
  noDishSelection.value = allNoDishIngredients.length > 0 &&
    allNoDishIngredients.every(ingredient => isSelected(ingredient));
}, { deep: true });

function toggleDishGroupSelection(dishName: string, groupName: string, isPlanned: boolean) {
  // Find the dish by name
  let dish;
  if (isPlanned) {
    dish = groupedPlannedDishesIngredients.value.find(d => d.name === dishName);
  } else {
    dish = groupedNotPlannedDishesIngredients.value.find(d => d.name === dishName);
  }
  if (!dish) return;

  // Find the group within the dish's ingredient groups
  const group = dish.ingredientGroups.groups?.find(g => g.name === groupName);
  if (!group) return;

  let isCurrentlySelected;
  if (isPlanned) {
    isCurrentlySelected = groupPlannedDishesSelections[groupName];
  } else {
    isCurrentlySelected = groupNotPlannedDishesSelections[groupName];
  }

  // Toggle all ingredients in the group
  group.ingredients.forEach(ingredient => {
    const index = selectedIngredients.value.findIndex(i => i.id === ingredient.id);
    if (!isCurrentlySelected && index === -1) {
      selectedIngredients.value.push(ingredient);
    } else if (isCurrentlySelected && index !== -1) {
      selectedIngredients.value.splice(index, 1);
    }
  });

  // Update the group selection state
  if (isPlanned) {
    groupPlannedDishesSelections[groupName] = !isCurrentlySelected;
  } else {
    groupNotPlannedDishesSelections[groupName] = !isCurrentlySelected;
  }
}

function toggleNoDishGroupSelection(groupName) {
  // Find the category and group
  const group = groupedNoDishesIngredients.value.groups?.find(g => g.name === groupName);
  if (!group) return;

  console.log("Group selections: ", groupNoDishesSelections);
  const isCurrentlySelected = groupNoDishesSelections[groupName];

  // console.log("IS CURRENTLY SELECTED: ", isCurrentlySelected);

  // Toggle all ingredients in the group
  group.ingredients.forEach(ingredient => {
    const index = selectedIngredients.value.findIndex(i => i.id === ingredient.id);
    // console.log("INDEX : ", index);
    if (!isCurrentlySelected && index === -1) {
      // console.log("ADDING INGREDIENT");
      // Add ingredient if group is being selected
      selectedIngredients.value.push(ingredient);
    } else if (isCurrentlySelected && index !== -1) {
      // console.log("REMOVING INGREDIENT");
      // Remove ingredient if group is being deselected
      selectedIngredients.value.splice(index, 1);
    }
    // console.log("SELECTED INGREDIENTS: ", selectedIngredients.value);
  });

  // Update group selection state
  groupNoDishesSelections[groupName] = !isCurrentlySelected;
}

const expandedGroups = reactive(new Set())

function toggleGroup(groupName: string) {
  if (expandedGroups.has(groupName)) {
    expandedGroups.delete(groupName)
  } else {
    expandedGroups.add(groupName)
  }
}
</script>

<template>
  <main class="flex flex-col gap-8 my-10">
    <!-- Planned Dishes Section -->
    <div>
      <h1 class="py-4 text-lg">{{ t('orders.plannedDishes') }} </h1>
      <div v-if="groupedPlannedDishesIngredients.length">
        <Accordion :activeIndex="0" :multiple="true">
          <!-- Dishes Sections -->
          <AccordionTab v-for="dish in groupedPlannedDishesIngredients" :key="dish.name">
            <template #header>
              <div class="flex items-center gap-3">
                <div class="text-sm leading-5">
                  <span v-if="dish.name !== 'noDish'">
                    {{ dish.name }}
                  </span>
                  <span v-else>
                    {{ t('orders.noDish') }}
                  </span>
                  <span class="font-normal">
                    ({{(dish.ingredientGroups.ingredients?.length || 0) +
                      (dish.ingredientGroups.groups?.reduce((sum, group) => sum + (group.ingredients?.length || 0), 0)
                        || 0)}})
                  </span>
                </div>
              </div>
            </template>
            <div class="flex items-center justify-end gap-3 my-2 italic">
              <label :for="`select-all-${dish.id}`" class="ml-2 text-sm hover:cursor-pointer text-primary-950">{{
                t('common.selectAll') }}</label>
              <div class="flex items-center justify-center flex-none w-6 py-2">
                <Checkbox :model-value="dishSelections[dish.id]" @change="toggleDishSelection(dish.name, dish.id, true)"
                  :binary="true" :inputId="`select-all-${dish.id}`" />
              </div>
            </div>
            <!-- Individual ingredients section -->
            <div v-if="dish.ingredientGroups.ingredients?.length">
              <div v-for="(ingredient, index) in dish.ingredientGroups.ingredients" :key="ingredient.id">
                <InlineIngredient :ingredient="ingredient" :index="index"
                  :description="t(`orders.ingredientsCategories.${ingredient.category}`)"
                  @update-selection="handleUpdateSelection"
                  @edit-ingredient="addOrUpdateIngredient({ ingredient, index, category: dish.name })"
                  :selected-ingredients="selectedIngredients" />
              </div>
            </div>

            <!-- Grouped ingredients section -->
            <div v-if="dish.ingredientGroups.groups?.length">
              <div v-for="group in dish.ingredientGroups.groups" :key="group.name">
                <div
                  class="relative flex items-center py-2 text-sm border-b border-gray-100 hover:cursor-pointer text-primary-950 min-w-52">
                  <div class="flex items-center flex-1 min-w-0" @click="toggleGroup(group.name)">
                    <i :class="{
                      'pi pi-chevron-down': expandedGroups.has(group.name),
                      'pi pi-chevron-right': !expandedGroups.has(group.name),
                      'w-5': true,
                    }"></i>
                    <div class="flex flex-1 min-w-0">
                      <div class="flex-1 min-w-0">
                        <p class="min-w-0 text-sm truncate text-primary-950">
                          {{ group.name }}
                        </p>
                      </div>
                      <div class="flex flex-none gap-1 w-28">
                        <p class="flex-none w-16 text-right">
                          {{ group.quantity }}
                        </p>
                        <span class="text-right truncate w-7">{{
                          te(`ingredients.unit.symbol.${group.unit}`)
                            ? t(`ingredients.unit.symbol.${group.unit}`)
                            : group.unit
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="flex items-center justify-center flex-none w-6 py-2">
                    <Checkbox :model-value="groupPlannedDishesSelections[group.name]"
                      @change="toggleDishGroupSelection(dish.name, group.name, true)" :binary="true" />
                  </div>
                </div>

                <div v-if="expandedGroups.has(group.name)">
                  <div v-for="(ingredient, index) in group.ingredients" :key="ingredient.id">
                    <InlineIngredient :ingredient="ingredient" :index="index"
                      :description="t(`orders.ingredientsCategories.${ingredient.category}`)"
                      @update-selection="handleUpdateSelection"
                      @edit-ingredient="addOrUpdateIngredient({ ingredient, index, category: dish.name })" class="ml-10"
                      :selected-ingredients="selectedIngredients" />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!dish.ingredientGroups.ingredients?.length && !dish.ingredientGroups.groups?.length">
              <p class="text-sm italic">{{ t('orders.noProduct') }}</p>
            </div>
          </AccordionTab>
        </Accordion>
      </div>
      <p v-else>{{ t('orders.noProduct') }}</p>
    </div>
    <div>

      <h1 class="py-4 text-lg">{{ t('orders.notPlannedDishes') }}</h1>
      <div v-if="groupedNotPlannedDishesIngredients.length">

        <Accordion :activeIndex="0" :multiple="true">
          <!-- Dishes Sections -->
          <AccordionTab v-for="dish in groupedNotPlannedDishesIngredients" :key="dish.name">
            <template #header>
              <div class="flex items-center gap-3">
                <div class="text-sm leading-5">
                  <span v-if="dish.name !== 'noDish'">
                    {{ dish.name }}
                  </span>
                  <span v-else>
                    {{ t('orders.noDish') }}
                  </span>
                  <span class="font-normal">
                    ({{(dish.ingredientGroups.ingredients?.length || 0) +
                      (dish.ingredientGroups.groups?.reduce((sum, group) => sum + (group.ingredients?.length || 0), 0)
                        || 0)}})
                  </span>
                </div>
              </div>
            </template>

            <div class="flex items-center justify-end gap-3 my-2 italic">
              <label :for="`select-all-${dish.id}`" class="ml-2 text-sm hover:cursor-pointer text-primary-950">{{
                t('common.selectAll') }}</label>
              <div class="flex items-center justify-center flex-none w-6 py-2">
                <Checkbox :model-value="dishSelections[dish.id]"
                  @change="toggleDishSelection(dish.name, dish.id, false)" :binary="true"
                  :inputId="`select-all-${dish.id}`" />
              </div>
            </div>
            <!-- Individual ingredients section -->
            <div v-if="dish.ingredientGroups.ingredients?.length">
              <div v-for="(ingredient, index) in dish.ingredientGroups.ingredients" :key="ingredient.id">
                <InlineIngredient :ingredient="ingredient" :index="index"
                  :description="t(`orders.ingredientsCategories.${ingredient.category}`)"
                  @update-selection="handleUpdateSelection"
                  @edit-ingredient="addOrUpdateIngredient({ ingredient, index, category: dish.name })"
                  :selected-ingredients="selectedIngredients" />
              </div>
            </div>

            <!-- Grouped ingredients section -->
            <div v-if="dish.ingredientGroups.groups?.length">
              <div v-for="group in dish.ingredientGroups.groups" :key="group.name">
                <div
                  class="relative flex items-center py-2 text-sm border-b border-gray-100 hover:cursor-pointer text-primary-950 min-w-52">
                  <div class="flex items-center flex-1 min-w-0" @click="toggleGroup(group.name)">
                    <i :class="{
                      'pi pi-chevron-down': expandedGroups.has(group.name),
                      'pi pi-chevron-right': !expandedGroups.has(group.name),
                      'w-5': true,
                    }"></i>
                    <div class="flex flex-1 min-w-0">
                      <div class="flex-1 min-w-0">
                        <p class="min-w-0 text-sm truncate text-primary-950">
                          {{ group.name }}
                        </p>
                      </div>
                      <div class="flex flex-none gap-1 w-28">
                        <p class="flex-none w-16 text-right">
                          {{ group.quantity }}
                        </p>
                        <span class="text-right truncate w-7">{{
                          te(`ingredients.unit.symbol.${group.unit}`)
                            ? t(`ingredients.unit.symbol.${group.unit}`)
                            : group.unit
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="flex items-center justify-center flex-none w-6 py-2">
                    <Checkbox :model-value="groupNotPlannedDishesSelections[group.name]"
                      @change="toggleDishGroupSelection(dish.name, group.name, false)" :binary="true" />
                  </div>
                </div>

                <div v-if="expandedGroups.has(group.name)">
                  <div v-for="(ingredient, index) in group.ingredients" :key="ingredient.id">
                    <InlineIngredient :ingredient="ingredient" :index="index"
                      :description="t(`orders.ingredientsCategories.${ingredient.category}`)"
                      @update-selection="handleUpdateSelection"
                      @edit-ingredient="addOrUpdateIngredient({ ingredient, index, category: dish.name })" class="ml-10"
                      :selected-ingredients="selectedIngredients" />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!dish.ingredientGroups.ingredients?.length && !dish.ingredientGroups.groups?.length">
              <p class="text-sm italic">{{ t('orders.noProduct') }}</p>
            </div>
          </AccordionTab>
        </Accordion>
      </div>
      <p v-else>{{ t('orders.noProduct') }}</p>
    </div>

    <div>

      <h1 class="py-4 text-lg">{{ t('orders.noDish') }}</h1>
      <div v-if="groupedNoDishesIngredients.ingredients?.length || groupedNoDishesIngredients.groups?.length">


        <Accordion :activeIndex="0" :multiple="true">
          <!-- Dishes Sections -->
          <AccordionTab :key="'noDish'">
            <template #header>
              <div class="flex items-center gap-3">
                <div class="text-sm leading-5">
                  <span>
                    {{ t('orders.noDish') }}
                  </span>
                  <span class="font-normal">
                    ({{(groupedNoDishesIngredients.ingredients?.length || 0) +
                      (groupedNoDishesIngredients.groups?.reduce((sum, group) => sum + (group.ingredients?.length || 0),
                        0)
                        || 0)}})
                  </span>
                </div>
              </div>
            </template>

            <div class="flex items-center justify-end gap-3 my-2 italic">
              <label for="select-all-no-dish" class="ml-2 text-sm hover:cursor-pointer text-primary-950">{{
                t('common.selectAll') }}</label>
              <div class="flex items-center justify-center flex-none w-6 py-2">
                <Checkbox :model-value="noDishSelection" @change="toggleNoDishSelection" :binary="true"
                  inputId="select-all-no-dish" />
              </div>
            </div>

            <!-- Individual ingredients section -->
            <div v-if="groupedNoDishesIngredients.ingredients?.length">
              <div v-for="(ingredient, index) in groupedNoDishesIngredients.ingredients" :key="ingredient.id">
                <InlineIngredient :ingredient="ingredient" :index="index"
                  :description="ingredient.dish_name || ingredient.comment || ''"
                  @update-selection="handleUpdateSelection"
                  @edit-ingredient="addOrUpdateIngredient({ ingredient, index, category: ingredient.category })"
                  :selected-ingredients="selectedIngredients" />
              </div>
            </div>

            <!-- Grouped ingredients section -->
            <div v-if="groupedNoDishesIngredients.groups?.length">
              <div v-for="group in groupedNoDishesIngredients.groups" :key="group.name">
                <div
                  class="relative flex items-center py-2 text-sm border-b border-gray-100 hover:cursor-pointer text-primary-950 min-w-52">
                  <div class="flex items-center flex-1 min-w-0" @click="toggleGroup(group.name)">
                    <i :class="{
                      'pi pi-chevron-down': expandedGroups.has(group.name),
                      'pi pi-chevron-right': !expandedGroups.has(group.name),
                      'w-5': true,
                    }"></i>
                    <div class="flex flex-1 min-w-0">
                      <div class="flex-1 min-w-0">
                        <p class="min-w-0 text-sm truncate text-primary-950">
                          {{ group.name }}
                        </p>
                      </div>
                      <div class="flex flex-none gap-1 w-28">
                        <p class="flex-none w-16 text-right">
                          {{ group.quantity }}
                        </p>
                        <span class="text-right truncate w-7">{{
                          te(`ingredients.unit.symbol.${group.unit}`)
                            ? t(`ingredients.unit.symbol.${group.unit}`)
                            : group.unit
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="flex items-center justify-center flex-none w-6 py-2">
                    <Checkbox :model-value="groupNoDishesSelections[group.name]"
                      @change="toggleNoDishGroupSelection(group.name)" :binary="true" />
                  </div>
                </div>

                <div v-if="expandedGroups.has(group.name)">
                  <div v-for="(ingredient, index) in group.ingredients" :key="ingredient.id">
                    <InlineIngredient :ingredient="ingredient" :index="index"
                      :description="ingredient.dish_name || ingredient.comment || ''"
                      @update-selection="handleUpdateSelection"
                      @edit-ingredient="addOrUpdateIngredient({ ingredient, index, category: ingredient.name })"
                      class="ml-10" :selected-ingredients="selectedIngredients" />
                  </div>
                </div>
              </div>
            </div>

            <div v-if="!groupedNoDishesIngredients.ingredients?.length && !groupedNoDishesIngredients.groups?.length">
              <p class="text-sm italic">{{ t('orders.noProduct') }}</p>
            </div>
          </AccordionTab>
        </Accordion>
      </div>
      <p v-else>{{ t('orders.noProduct') }}</p>
    </div>
  </main>
</template>