<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n'
import { useFetch } from '@/composables/useFetch'
import { useEnvStore } from '@/stores/envStore'
import { useToast } from 'primevue/usetoast'
import Skeleton from 'primevue/skeleton'
import { watch } from 'vue'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

</script>

<template>
  <div class="relative flex items-center justify-center w-60 h-60 rounded-3xl bg-primary-100">
    <div class="text-center">
      <div class="relative inline-block my-2">
        <i class="pi pi-image text-primary-300" style="font-size: 4rem"></i>
        <div class="absolute flex items-center justify-center text-white rounded-full -bottom-2 -right-3 bg-accent aspect-square w-7">
          <i class="w-3 text-xs pi pi-plus"></i>
        </div>
      </div>
      <div class="w-24 font-bold text-center text-primary-500">{{ t('planning.plannedMenu.uploadImage')}}</div>
      <!-- <div class="w-20 font-bold text-center text-primary-500">Add an image</div> -->
      <!-- <div class="w-20 font-bold text-center text-primary-500">Aggiungi un'immagine</div> -->
      <!-- <div class="w-20 font-bold text-center text-primary-500">Bild hinzufügen</div> -->
    </div>
  </div>
  <!-- <i class="pi pi-image text-primary-500" style="font-size: 0.5rem"></i>
  <button class="flex items-center justify-center w-10 p-2 text-white rounded-full bg-accent aspect-square">
    <i class="pi pi-plus" style="font-size: 1rem"></i>
  </button> -->
</template>
