<script setup lang="ts">
import { defineProps, defineEmits, type ModelRef } from 'vue'
import { useI18n } from 'vue-i18n'
import Checkbox from 'primevue/checkbox'
import InputNumber from '@/components/BaseComponents/InputNumber.vue'
import type { Ingredient } from '@/types/dish'

const ingredient: ModelRef<Ingredient> = defineModel('ingredient', { required: true })
const emit = defineEmits(['toggle'])
const { t, te } = useI18n()

const toggleIngredientSelection = (ingredient) => {
  emit('toggle', ingredient)
}
</script>

<template>
  <div class="flex gap-3 pb-1 mb-4 text-sm border-b w-full text-primary-950 border-primary-500">
    <div class="flex items-center flex-none w-3">
      <Checkbox v-model="ingredient.is_selected" @click.prevent="() => toggleIngredientSelection(ingredient)"
        :inputId="`checkbox-${ingredient.id}`" :binary="true" />
    </div>
    <label :for="`checkbox-${ingredient.id}`" class="flex flex-1 gap-1 min-w-0">
      <div class="flex-none w-24">
        <div class="flex items-center gap-1 text-left">
          <InputNumber v-model="ingredient.quantity"
            :placeholder="t('profileFavorites.creationForm.quantityPlaceholder')" class="flex-none w-16" />
          <span class="flex-1">
            {{ te(`ingredients.unit.symbol.${ingredient.unit}`) ? t(`ingredients.unit.symbol.${ingredient.unit}`) : ''
            }}
          </span>
        </div>
      </div>
      <div class="flex items-center flex-1 h-full min-w-0">
        <span class="truncate">{{ ingredient.name }}</span>
      </div>
      <div class="flex items-center flex-none w-10">
        <span>{{ ingredient.price }}</span>
      </div>
    </label>
  </div>
</template>
