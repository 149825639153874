<script setup lang="ts">
import type { User } from '@/types/api';
import { useRouter } from 'vue-router';

const props = defineProps<{
  user: User
}>()

const router = useRouter()

function navigateToUserDetail() {
  router.push(`/profile/team/${props.user.id}`)
}
</script>
<template>
  <div @click="navigateToUserDetail" class="flex items-center justify-between p-4 rounded-lg shadow-md">
    <div>{{ user.first_name }} {{ user.last_name }}</div>
    <i class="text-lg pi pi-angle-right"></i>
  </div>
</template>