<script setup lang="ts">
import { useManager } from '@/composables/useManager';
import { useToast } from 'primevue/usetoast';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import type { User } from '@/types/api';
import { useUserStore } from '@/stores/useUserStore';
import Button from 'primevue/button';
import { useI18n } from 'vue-i18n';

const route = useRoute();
const toast = useToast();
const { getUserDetails, exportWorkerHours } = useManager(); // Assuming this function exists

const { t } = useI18n();
const userId = ref(route.params.userId as string);
const userStore = useUserStore();
const user = ref(userStore.user)
const worker = ref<User | null>(null);
const loading = ref(true);


async function fetchUserDetails() {
  loading.value = true;
  if (!user.value?.active_restaurant) return;
  try {
    // Adjust this according to your API implementation
    const { data, error } = await getUserDetails(user.value.active_restaurant?.restaurant_user_id, userId.value);

    console.log("FETCHING USER DETAILS 3")
    if (error) {
      console.error(error);
      toast.add({
        severity: 'error',
        summary: 'Erreur',
        detail: 'Une erreur est survenue lors de la récupération des détails de l\'utilisateur',
        life: 3000
      });
      return;
    }

    worker.value = data.user;

  } catch (err) {
    console.error('Error fetching user details:', err);
  } finally {
    loading.value = false;
  }
}

const exportHours = () => {
  if (!worker.value) return;
  exportWorkerHours(worker.value?.active_restaurant?.restaurant_user_id, worker.value.id);
}


const copyInvitationLink = () => {
  if (!worker.value?.registration_token?.registration_url) {
    toast.add({
      severity: 'error',
      summary: 'Erreur',
      detail: 'Aucun lien d\'invitation disponible',
      life: 3000,
    })
    return
  }
  const invitationText = t('invitation.text')
  navigator.clipboard.writeText(`${invitationText} ${worker.value.registration_token.registration_url}`)
    .then(() => {
      toast.add({
        severity: 'info',
        summary: 'Succès',
        detail: 'Lien d\'invitation copié dans le presse-papier',
        life: 3000,
      })
    })
    .catch(err => {
      console.error('Impossible de copier le texte: ', err)
      toast.add({
        severity: 'error',
        summary: 'Erreur',
        detail: 'Impossible de copier le lien',
        life: 3000,
      })
    })
}
onMounted(() => {
  fetchUserDetails();
});
</script>

<template>
  <main>
    <div v-if="loading" class="loading-state">
      Chargement des informations...
    </div>
    <div v-else-if="!worker" class="error-state">
      Utilisateur non trouvé
    </div>
    <div v-else class="user-details">
      <h1>{{ worker.first_name }} {{ worker.last_name }}</h1>

      <div v-if="!worker.registration_completed" class="pending-status">
        <div class="alert alert-info">
          <i class="pi pi-info-circle"></i>
          <span>Cet employé n'a pas encore complété son inscription</span>
        </div>

        <div class="info-card mb-4">
          <h2>Informations de base</h2>
          <div class="info-row">
            <span class="label">Email:</span>
            <span class="value">{{ worker.email }}</span>
          </div>
        </div>

        <div class="invitation-section">
          <h3>Lien d'invitation</h3>
          <p class="text-sm text-gray-600 mb-2">Vous pouvez partager ce lien avec l'employé pour qu'il puisse finaliser
            son inscription</p>
          <!-- <div class="bg-gray-100 p-3 rounded-lg text-sm mb-4 break-all">
            {{ worker.registration_token.registration_url }}
          </div> -->
          <Button label="Copier le lien d'invitation" icon="pi pi-copy" @click="copyInvitationLink" />
        </div>
      </div>
      <div v-else>
        <div class="info-card">
          <h2>Informations personnelles</h2>
          <div class="info-row">
            <span class="label">Email:</span>
            <span class="value">{{ worker.email }}</span>
          </div>
          <div class="info-row" v-if="worker.avs_number">
            <span class="label">Numéro AVS:</span>
            <span class="value">{{ worker.avs_number }}</span>
          </div>
          <div class="info-row" v-if="worker.address">
            <span class="label">Adresse:</span>
            <span class="value">{{ worker.address }}</span>
          </div>
          <!-- Add more user details as needed -->
        </div>
        <Button label="Exporter heures en Excel" @click="exportHours" class="mt-4" />
      </div>

      <!-- You can add more sections like permissions, working hours, etc. -->
    </div>
  </main>
</template>