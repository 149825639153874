<script setup lang="ts">
import UserCard from '@/components/Manager/UserCard.vue';
import { useManager } from '@/composables/useManager';
import { useUserStore } from '@/stores/useUserStore';
import type { User } from '@/types/api';
import Button from 'primevue/button';
import { useToast } from 'primevue/usetoast';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const toast = useToast()
const userStore = useUserStore()
const user = ref(userStore.user)
const { getTeam } = useManager()
const { t } = useI18n()
const router = useRouter()


const team = ref<User[]>([])
const loading = ref(true) // Add loading state

const activeTeam = computed(() => {
  return team.value.filter((user) => user.registration_completed)
})

const pendingTeam = computed(() => {
  return team.value.filter((user) => !user.registration_completed)
})

async function fetchTeam() {
  if (!user.value || !user.value.active_restaurant) return

  loading.value = true
  try {
    const { data, error } = await getTeam(
      user.value.active_restaurant.restaurant_user_id
    )

    if (error) {
      console.error(error)
      toast.add({
        severity: 'error',
        summary: 'Erreur',
        detail: 'Une erreur est survenue lors de la récupération de l\'équipe',
        life: 3000
      })
      return
    }

    team.value = data.users;
  } catch (err) {
    console.error('Error fetching team:', err)
  } finally {
    loading.value = false
  }
}

function addEmployee() {
  router.push({name: 'add-employee'})
}

// Fetch team data when component is mounted
onMounted(() => {
  fetchTeam()
})
</script>

<template>
  <main>
    <div id="team">

      <h1>Team</h1>
      <div v-if="loading">
        Chargement de l'équipe...
      </div>
      <div v-else-if="activeTeam.length === 0">
        Aucun membre d'équipe trouvé
      </div>
      <div v-else class="user-grid">
        <UserCard v-for="user in activeTeam" :key="user.id" :user="user" />
      </div>
      <div class="mt-4">
        <!-- TODO : Translate -->
        <p @click="addEmployee" class="mb-10 inline-button">
          + Ajouter employé
        </p>
      </div>
    </div>
    <div id="pending">
      <h1>Pending</h1>
      <div v-if="loading">
        Chargement des demandes...
      </div>
      <div v-else-if="pendingTeam.length === 0">
        Aucun membre d'équipe trouvé
      </div>
      <div v-else class="user-grid">
        <UserCard v-for="user in pendingTeam" :key="user.id" :user="user" />
      </div>
    </div>


  </main>
</template>
<style scoped></style>