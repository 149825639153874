<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed } from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { useI18n } from 'vue-i18n';
import type { MessageSchema } from '@/i18n';
import Avatar from '@/assets/icons/Avatars/Avatar.vue';
import Checkbox from 'primevue/checkbox';
import { useAppStore } from '@/stores/appStore';
import IosShare from '@/assets/icons/Share/IosShare.vue';
import IosAddHomeScreenIcon from '@/assets/icons/Share/IosAddHomeScreenIcon.vue';

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' });
const appStore = useAppStore();

// const showPrompt = ref(false);
const doNotShowAgain = ref(false);

// Local storage key for saving user's choice
const PWA_PROMPT_DISMISSED_KEY = 'pwaPromptDismissed';

// Computed property to determine which type of prompt to show
const isIOSDevice = computed(() => appStore.isIOS());

// Handler for beforeinstallprompt event
const handleBeforeInstallPrompt = (e: Event) => {
  // Prevent Chrome 76+ from automatically showing the prompt
  e.preventDefault();
  // Store the event so it can be triggered later
  // appStore.deferredPrompt.value = e;

  // Check if user previously dismissed the prompt
  const promptDismissed = localStorage.getItem(PWA_PROMPT_DISMISSED_KEY);
  if (!promptDismissed) {
    // Show your custom install UI only if user hasn't dismissed it
    appStore.showPrompt = true;
  }
};

const showIOSInstructions = computed(() => appStore.shouldShowIOSInstructions());

const showPrompt = computed({
  get: () => appStore.showPrompt,
  set: (value) => { appStore.showPrompt = value }
});

// Install the app
const installApp = async () => {
  // if (!appStore.deferredPrompt.value) {
  //   return;
  // }

  const installed = await appStore.installPwa();
  if (installed) {
    console.log('User accepted the install prompt');
  } else {
    console.log('User dismissed the install prompt');
  }

  appStore.showPrompt = false;
};

// Dismiss the prompt and save the user's choice
const dismissPrompt = () => {
  appStore.showPrompt = false;
  if (doNotShowAgain.value) {
    // Save different keys based on device type
    localStorage.setItem(PWA_PROMPT_DISMISSED_KEY, 'true');
  }
};

onMounted(() => {
  // Check if the app is already installed
  if (appStore.checkIfInstalled()) {
    // App is already installed, no need to show prompt
    return;
  }

  if (appStore.shouldShowIOSInstructions()) {
    // Show the iOS instructions
    appStore.showPrompt = true;
  }
  // Add event listener for beforeinstallprompt event
  window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
});

onUnmounted(() => {
  // Remove event listener
  window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
});
</script>

<template>
  <!-- Standard PWA install prompt (for non-iOS devices) -->
  <Dialog v-model:visible="showPrompt" :header="t('pwa.popup.title') || 'Install GastronomIA'" :modal="true"
    :closable="true" :style="{ width: '90%', maxWidth: '450px', maxHeight: '90vh' }" @hide="dismissPrompt">
    <div>
      <Avatar class="w-24 h-24 mx-auto" />
    </div>

    <!-- Non-iOS content of the dialog -->
    <div v-if="!isIOSDevice" class="flex flex-col items-center gap-4 p-4">

      <p class="text-center">
        {{ t('pwa.popup.description') || 'Install GastronomIA on your device for a better experience, even offline!'
        }}
      </p>

      <!-- Checkbox for "Do not show again" -->
      <div class="flex items-center">
        <Checkbox inputId="doNotShowAgain" v-model="doNotShowAgain" class="p-checkbox-sm" binary />
        <label for="doNotShowAgain" class="ml-2 text-sm">
          {{ t('pwa.popup.dontShowAgain') || 'Do not show again' }}
        </label>
      </div>

      <div class="flex justify-between w-full gap-2 mt-4">
        <Button :label="t('pwa.popup.cancel') || 'Not Now'" severity="primary" class="p-button-text"
          @click="dismissPrompt" />

        <Button :label="t('pwa.popup.install') || 'Install'" @click="installApp" />
      </div>
    </div>

    <!-- iOS content of the dialog -->
    <div v-else class="flex flex-col items-center gap-4 p-4">

      <p class="mb-4 text-center">
        {{ t('pwa.ios.description') }}
      </p>

      <!-- Steps List -->
      <div class="w-full">
        <div class="flex items-center p-2 mb-4 border-l-4 rounded border-primary-500 bg-primary-50">
          <div class="flex items-center justify-center w-8 h-8 mr-3 text-white rounded-full bg-primary-500">
            <p class="font-bold text-center text-white">1</p>
          </div>
          <div>
            <p class="flex flex-row items-center justify-center gap-2">
              <span class="font-semibold">{{ t('pwa.ios.tapOn') }} </span>
              <span> <IosShare class="w-5" /></span>
            </p>
          </div>
        </div>
        <div class="flex items-center p-2 mb-4 border-l-4 rounded border-primary-500 bg-primary-50">
          <div class="flex items-center justify-center w-8 h-8 mr-3 text-white rounded-full bg-primary-500">
            <p class="font-bold text-center text-white">2</p>
          </div>
          <div>
            <p class="flex flex-row flex-wrap items-center justify-start gap-2">
              <span class="font-semibold">{{ t('pwa.ios.select') }} </span>
              <span class="p-1 bg-white rounded-md flex flex-row gap-2">{{ t('pwa.ios.addHomeScreen') }} 
                <span>
                <IosAddHomeScreenIcon class="w-5" />
              </span>
            </span>
              
            </p>
          </div>
        </div>
      </div>
      <!-- Checkbox for "Do not show again" -->
      <div class="flex items-center">
        <Checkbox inputId="doNotShowAgain" v-model="doNotShowAgain" class="p-checkbox-sm" binary />
        <label for="doNotShowAgain" class="ml-2 text-sm">
          {{ t('pwa.popup.dontShowAgain') }}
        </label>
      </div>

      <div class="flex justify-center w-full gap-2 mt-4">
        <Button :label="t('pwa.ios.gotIt')" @click="dismissPrompt" />
      </div>
    </div>
  </Dialog>

  <!-- iOS specific installation tutorial -->
  <!-- <IOSInstallTutorial v-if="isIOSDevice && showIOSInstructions" /> -->
</template>