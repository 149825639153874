<script setup lang="ts">
import { useDishesStore } from '@/stores/dishesStore'
import { computed, markRaw, nextTick, onMounted, onUnmounted, ref, watch, type Component, type ComputedRef, type Raw, type Ref } from 'vue'
import type { Dish } from '@/types/dish'
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n'
import { useFetch } from '@/composables/useFetch'
import { useEnvStore } from '@/stores/envStore'
import { useToast } from 'primevue/usetoast'
import { useRoute, useRouter } from 'vue-router'
import { usePlanningStore } from '@/stores/planningStore'
import PlannedMenuLine from '@/components/Planning/PlannedMenuLine.vue'
import Button from 'primevue/button'
import ButtonShare from '@/assets/icons/Buttons/ButtonShare.vue'
import InputText from 'primevue/inputtext'
import Dialog from 'primevue/dialog'
import ShareCard from '@/assets/icons/Planning/ShareCard.vue'
import DishImage from '@/components/Menus/DishImage.vue'
import type { MenuCourse } from '@/types/planning'
import Menu from 'primevue/menu'
import ButtonMenu from '@/assets/icons/Buttons/ButtonMenu.vue'
import { useConfirm } from 'primevue/useconfirm'
import InputNumber from '@/components/BaseComponents/InputNumber.vue'
import { useUserStore } from '@/stores/useUserStore'
import PlaceholderImage from '@/components/BaseComponents/PlaceholderImage.vue'
import StarterIcon from '@/assets/icons/Share/StarterIcon.vue'
import MainDishIcon from '@/assets/icons/Share/MainDishIcon.vue'
import FullMenuIcon from '@/assets/icons/Share/FullMenuIcon.vue'
import DessertIcon from '@/assets/icons/Share/DessertIcon.vue'
import ShareMenu from '@/components/Share/ShareMenu.vue'
import { formatPrice } from '@/utils/prices'
import type { ShareData, ShareMenuItem } from '@/types/share'

const { t, locale } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const route = useRoute()
const router = useRouter()
const toast = useToast()
const envStore = useEnvStore()
const dishesStore = useDishesStore()
const dish = ref<Dish | null>(null)
dish.value = dishesStore.selectedDish
const { fetchData } = useFetch()
const confirmDialog = useConfirm()

const planningStore = usePlanningStore()
const { user } = useUserStore()
const shareModalVisible = ref(false)
const isImageModalVisible = ref(false)
const dishImageRef: Ref<InstanceType<typeof DishImage> | null> = ref(null)
const popupMenu = ref()
const popupShareMenu = ref()
const mainDishImageSrc = ref('')
const dessertImageSrc = ref('')
const starterImageSrc = ref('')

const isTitleTooLong = ref(false)
const MAX_TITLE_LENGTH = 20
const MAX_UPPERCASE_LENGTH = 14


const shareData: Ref<ShareData> = ref({
  title: '',
  subtitle: '',
  items: [],
  imageSrc: '',
  price: '',
})

const popUpMenuItems = ref([
  {
    items: [
      {
        label: t('planning.plannedMenu.delete.buttonLabel'),
        icon: 'pi pi-trash',
        command: () => confirmDeletePlannedMenu(),
      },
      // {
      //   label: t('menuDishView.topMenu.duplicate'),
      //   icon: 'pi pi-clone',
      //   command: () =>
      //   console.log("Duplicate"),
      //     // duplicateDish(props.dish.id).then((duplicationResponse) =>
      //     //   emit('handleDuplication', duplicationResponse)
      //     // ),
      // },
      // {
      //   label: t('menuDishView.topMenu.share'),
      //   icon: 'pi pi-share-alt',
      // },
    ],
  },
])

const shareMenuItems = computed(() => {
  const items: { label: string; icon?: string; customIcon?: Raw<Component>; command: () => Promise<void> }[] = []

  // Check if starter exists and has image
  const starterCourse = sortedAndFilledCourses.value[0]
  if (starterCourse?.dish?.selected_image && starterCourse.dish.selected_image !== 'none') {
    items.push({
      label: t('planning.plannedMenu.share.storyStarter'),
      customIcon: markRaw(StarterIcon),
      command: () => handleShare('storyStarter'),
    })
  }
  if (starterCourse?.dish?.selected_image && starterCourse?.dish?.selected_image === 'none') {
    items.push({
      label: t('planning.plannedMenu.share.postStarter'),
      customIcon: markRaw(StarterIcon),
      command: () => handleShare('postStarter'),
    })
  }

  if (mainDish.value && mainDish.value.imageUrl && mainDish.value.selected_image !== 'none') {
    // Add main dish option always since it's handled separately
    items.push({
      label: t('planning.plannedMenu.share.storyMainDish'),
      customIcon: markRaw(MainDishIcon),
      command: () => handleShare('storyMainDish'),
    })
  }

  if (mainDish.value && mainDish.value.selected_image === 'none') {
    items.push({
      label: t('planning.plannedMenu.share.postMainDish'),
      customIcon: markRaw(MainDishIcon),
      command: () => handleShare('postMainDish'),
    })
  }

  // Check if dessert exists and has image
  const dessertCourse = sortedAndFilledCourses.value[2]
  if (dessertCourse?.dish?.selected_image && dessertCourse.dish.selected_image !== 'none') {
    items.push({
      label: t('planning.plannedMenu.share.storyDessert'),
      customIcon: markRaw(DessertIcon),
      command: () => handleShare('storyDessert'),
    })
  }
  if (dessertCourse?.dish?.selected_image && dessertCourse?.dish?.selected_image === 'none') {
    items.push({
      label: t('planning.plannedMenu.share.postDessert'),
      customIcon: markRaw(DessertIcon),
      command: () => handleShare('postDessert'),
    })
  }

  if (mainDish.value && mainDish.value.imageUrl && mainDish.value.selected_image !== 'none') {
    // Add full menu option always
    items.push({
      label: t('planning.plannedMenu.share.storyFull'),
      customIcon: markRaw(FullMenuIcon),
      command: () => handleShare('storyFull'),
    })
  }

  if (mainDish.value && mainDish.value.selected_image === 'none') {
    items.push({
      label: t('planning.plannedMenu.share.postFull'),
      customIcon: markRaw(FullMenuIcon),
      command: () => handleShare('postFull'),
    })
  }


  return items
})

const plannedMenuTitle = ref(planningStore.selectedPlannedMenu?.name || '')

const plannedMenuPrice = ref(planningStore.selectedPlannedMenu?.price || 0);

const formattedPrice: ComputedRef<string> = computed(() => {
  const price = plannedMenuPrice.value;
  const symbol = user?.restaurants[0].currency_symbol || 'chf';
  // Ensure price is a number and convert it if necessary
  const numericPrice = typeof price === 'string' ? parseFloat(price) : Number(price);

  // Check if the conversion resulted in a valid number
  if (isNaN(numericPrice) || numericPrice <= 0) {
    return ``;
  }

  return `${symbol} ${formatPrice(numericPrice)}`;
});


watch(
  () => planningStore.selectedPlannedMenu?.name,
  (newName) => {
    plannedMenuTitle.value = newName || ''
  }
)

watch(
  () => planningStore.selectedPlannedMenu?.price,
  (newPrice) => {
    plannedMenuPrice.value = newPrice || 0;
  }
)
const temporaryMenuCourses = ref<MenuCourse[]>([])

// When dishToPlanClipboard is not null, prepare to add the dish to the temporary list
watch(
  () => planningStore.dishToPlan?.clipboardDish,
  (newDish) => {
    if (newDish) {
      // Add the dish to the first available course (e.g., entree if it's empty)
      const emptyCourse = temporaryMenuCourses.value.find((course) => !course.dish)
      if (emptyCourse) {
        emptyCourse.dish = newDish // Assign the dish to the empty course
      } else {
        // Or push it into a new course
        temporaryMenuCourses.value.push({
          id: -1, // Temporary ID
          name: newDish.name,
          order: 2, // Example: main dish
          dish: newDish,
          planned_menu_id: planningStore.selectedPlannedMenu?.id || -1,
        })
      }
    }
  }
)

const updatePlannedMenuTitle = async () => {
  if (planningStore.selectedPlannedMenu) {
    planningStore.selectedPlannedMenu.name = plannedMenuTitle.value
    await planningStore.updatePlannedMenu(planningStore.selectedPlannedMenu)
  }
}

const updatePlannedMenuPrice = async () => {
  if (planningStore.selectedPlannedMenu) {
    // const parsedPrice = parseFloat(tempPrice.value);
    // Ensure the price is not negative and is a valid number
    // const validPrice = !isNaN(parsedPrice) ? Math.max(0, parsedPrice) : 0;
    // plannedMenuPrice.value = validPrice;
    planningStore.selectedPlannedMenu.price = plannedMenuPrice.value;
    await planningStore.updatePlannedMenu(planningStore.selectedPlannedMenu);
  }
}


// Create a debounced version of the updatePlannedMenuTitle function
// const debouncedUpdatePlannedMenuTitle = debounce(updatePlannedMenuTitle, 1000)
// const debouncedUpdatePlannedMenuPrice = debounce(updatePlannedMenuPrice, 750);

// mounted
onMounted(async () => {
  // console.log("mounted PlannedMenuView");
  if (
    !planningStore.selectedPlannedMenu ||
    (planningStore.selectedPlannedMenu.id.toString() !== route.params.id &&
      !planningStore.dishToPlan)
  ) {
    // console.log("no selectedPlannedMenu, get the plannedMenu from route");
    planningStore.selectPlannedMenu(parseInt(route.params.id.toString()))
  }
})

onUnmounted(() => {
  // console.log("unMount Planned Menu, set selectedPlannedMenu to null")
  planningStore.selectedPlannedMenu = null
})

const formattedDate = computed(() => {
  if (!planningStore.selectedPlannedMenu?.date) return ''

  const date = new Date(planningStore.selectedPlannedMenu.date)
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long', // 'long' format for the day
    month: 'long', // 'long format of month'
    day: '2-digit', // two-digit day
    year: 'numeric', // four-digit year
  }

  // Formatting the date according to locale
  let dateString = date.toLocaleDateString(locale.value, options)

  // Capitalizing the first letter of the weekday and adjusting format
  dateString = dateString.charAt(0).toUpperCase() + dateString.slice(1)
  dateString = dateString.replace(',', '') // Remove any commas

  // Remove the period if it exists at the end
  if (dateString.endsWith('.')) {
    dateString = dateString.slice(0, -1)
  }

  return dateString
})

const sortedAndFilledCourses: ComputedRef<(MenuCourse | null)[]> = computed(() => {
  const courses = planningStore.selectedPlannedMenu?.menu_courses || []
  const sortedCourses = [...courses].sort((a, b) => a.order - b.order)

  // Ensure all three course types are present
  const courseTypes = [1, 2, 3] // Corresponds to Entrée, Plat principal, Dessert
  return courseTypes.map((type) => {
    const found = sortedCourses.find((course) => course.order === type)
    return found || null
  })
})

function openShareModal() {
  shareModalVisible.value = true
}

const mainDish = computed(() => {
  const dish = planningStore.selectedPlannedMenu?.menu_courses.find(
    (course) => course.order === 2
  )?.dish
  if (!dish) return null

  // if (dish.selected_image === 'none') {
  //   return {
  //     ...dish,
  //     imageUrl: '',
  //   }
  // }

  const imageUrl = dish.image_url_gastro || dish.image_url_bistro || dish.image_url_user
  return {
    ...dish,
    imageUrl: imageUrl || '',
  }
})

const imageDataUrl = ref('') // Reactive property to hold the image data URL


async function fetchDishImage(dish: Dish, imageRef: Ref<string>) {
  if (dish.selected_image && dish.selected_image !== 'none') {
    const { data, error } = await fetchData(
      `${envStore.apiUrl}/dishes/${dish.id}/images/${dish.selected_image}`
    );
    if (error) {
      console.error(`Error fetching image for dish ${dish.id}:`, error);
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('planning.plannedMenu.noImage'),
        life: 3000,
      });
      throw new Error('Image fetch failed');
    }
    if (data && data.image) {
      imageRef.value = data.image;
    }
  }
}
async function prepareShareData(type: string): Promise<ShareData> {
  let dish!: Dish;
  const imageSrcRef: Ref<string> = ref('');
  let items: ShareMenuItem[] = [];

  switch (type) {
    case 'storyStarter': {
      const starterCourse = sortedAndFilledCourses.value[0];
      if (!starterCourse?.dish) {
        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: t('planning.plannedMenu.noStarter'),
          life: 3000,
        });
        throw new Error('No starter dish');
      }
      dish = starterCourse.dish;
      imageSrcRef.value = starterImageSrc.value;
      items.push({
        order: starterCourse.order,
        name: starterCourse.dish ? starterCourse.dish.name : starterCourse.name ?? ''
      });
      await fetchDishImage(dish, imageSrcRef);
      break;
    }
    case 'storyMainDish': {
      if (!mainDish.value) {
        throw new Error('Main dish not found');
      }
      dish = mainDish.value;
      imageSrcRef.value = mainDishImageSrc.value;
      items.push({
        order: 2,
        name: mainDish.value.name
      });
      await fetchDishImage(dish, imageSrcRef);
      break;
    }
    case 'storyDessert': {
      const dessertCourse = sortedAndFilledCourses.value[2];
      if (!dessertCourse?.dish) {
        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: t('planning.plannedMenu.noDessert'),
          life: 3000,
        });
        throw new Error('No dessert dish');
      }
      dish = dessertCourse.dish;
      imageSrcRef.value = dessertImageSrc.value;
      items.push({
        order: dessertCourse.order,
        name: dessertCourse.dish ? dessertCourse.dish.name : dessertCourse.name ?? ''
      });
      await fetchDishImage(dish, imageSrcRef);
      break;
    }
    case 'storyFull': {
      if (!mainDish.value) {
        throw new Error('Main dish not found');
      }
      dish = mainDish.value;
      imageSrcRef.value = mainDishImageSrc.value;
      items = sortedAndFilledCourses.value.map((course) => {
        if (course)
          return {
            order: course.order,
            name: course.dish ? course.dish.name : course.name ?? ''
          };
        return { order: 0, name: '' };
      });
      await fetchDishImage(dish, imageSrcRef);
      break;
    }
    // For post types, we set imageSrc to empty
    case 'postMainDish':
    case 'postFull': {
      if (!mainDish.value) {
        throw new Error('Main dish not found');
      }
      dish = mainDish.value;
      imageSrcRef.value = '';
      items.push({
        order: 2,
        name: mainDish.value.name
      });
      if (type === 'postFull') {
        items = sortedAndFilledCourses.value.map((course) => {
          if (course)
            return {
              order: course.order,
              name: course.dish ? course.dish.name : course.name ?? ''
            };
          return { order: 0, name: '' };
        });
      }
      break;
    }
    case 'postDessert': {
      const dessertCourse = sortedAndFilledCourses.value[2];
      if (!dessertCourse?.dish) {
        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: t('planning.plannedMenu.noDessert'),
          life: 3000,
        });
        throw new Error('No dessert dish');
      }
      dish = dessertCourse.dish;
      items.push({
        order: dessertCourse.order,
        name: dessertCourse.dish ? dessertCourse.dish.name : dessertCourse.name ?? ''
      });
      imageSrcRef.value = '';
      break;
    }
    case 'postStarter': {
      const starterCourse = sortedAndFilledCourses.value[0];
      if (!starterCourse?.dish) {
        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: t('planning.plannedMenu.noStarter'),
          life: 3000,
        });
        throw new Error('No starter dish');
      }
      dish = starterCourse.dish;
      items.push({
        order: starterCourse.order,
        name: starterCourse.dish ? starterCourse.dish.name : starterCourse.name ?? ''
      });
      imageSrcRef.value = '';
      break;
    }
    default:
      throw new Error(`Unknown share type: ${type}`);
  }
  return {
    title: plannedMenuTitle.value,
    subtitle: formattedDate.value,
    items: items,
    imageSrc: imageSrcRef.value,
    price: formattedPrice.value,
  };
}

function addOrUpdateMenuCourse(menuCourse: MenuCourse) {
  if (!planningStore.selectedPlannedMenu) return
  menuCourse.planned_menu_id = planningStore.selectedPlannedMenu.id
  planningStore.addOrUpdateMenuCourse(menuCourse, router)
}

function getOrderedIndex(index: number): 1 | 2 | 3 {
  return (((index - 1) % 3) + 1) as 1 | 2 | 3
}

function togglePopUpMenu(event: MouseEvent) {
  popupMenu.value.toggle(event)
}
function togglePopUpShareMenu(event: MouseEvent) {
  popupShareMenu.value.toggle(event)
}

const confirmDeletePlannedMenu = () => {
  confirmDialog.require({
    message: t('planning.plannedMenu.delete.message'),
    header: t('planning.plannedMenu.delete.title'),
    icon: 'pi pi-exclamation-triangle',
    rejectClass: 'p-button-secondary p-button-outlined',
    rejectLabel: t('planning.plannedMenu.delete.cancel'),
    acceptLabel: t('planning.plannedMenu.delete.confirm'),
    accept: () => {
      if (planningStore.selectedPlannedMenu)
        planningStore.deletePlannedMenu(planningStore.selectedPlannedMenu.id, router)
    },
    reject: () => { },
  })
}

// Call fetchMainDishImage when mainDish changes

const fetchMainDishImage = async () => {
  if (mainDish.value && mainDish.value.id && mainDish.value.selected_image) {
    const { data, error } = await fetchData(
      `${envStore.apiUrl}/dishes/${mainDish.value.id}/images/${mainDish.value.selected_image}`
    )

    if (error) {
      console.error('Error fetching image:', error)
      // Handle error (e.g., set a default image or show an error message)
    } else if (data && data.image) {
      mainDishImageSrc.value = data.image
    }
  }
}

onMounted(() => {
  watch(mainDish, fetchMainDishImage, { immediate: true })
})

const countUpperCase = (str: string) => {
  return str.split('').filter((char) => char === char.toUpperCase() && char !== char.toLowerCase())
    .length
}

const titleLengthError = computed(() => {
  const uppercaseCount = countUpperCase(plannedMenuTitle.value)
  if (plannedMenuTitle.value.length > MAX_TITLE_LENGTH || uppercaseCount > MAX_UPPERCASE_LENGTH) {
    return t('planning.plannedMenu.titleTooLongWarning')
  }
  return ''
})

const checkTitleLength = () => {
  const uppercaseCount = countUpperCase(plannedMenuTitle.value)
  isTitleTooLong.value =
    plannedMenuTitle.value.length > MAX_TITLE_LENGTH || uppercaseCount > MAX_UPPERCASE_LENGTH
}

watch(plannedMenuTitle, checkTitleLength)
const loadingShare = ref(false)

const placeholderMenu = ref()
const placeholderMenuItems = ref([
  {
    label: t('planning.plannedMenu.uploadImage'),
    icon: 'pi pi-upload',
    command: () => {
      uploadImage()
    },
  },
  {
    label: t('planning.plannedMenu.generateImage'),
    icon: 'pi pi-image',
    command: () => {
      generateImage()
    },
  },
])

const fileInput: Ref<HTMLInputElement | null> = ref(null)

function togglePlaceholderMenu(event) {
  placeholderMenu.value.toggle(event)
}

function uploadImage() {
  fileInput.value?.click()
}

async function handleFileUpload(event: Event) {
  const files = (event.target as HTMLInputElement).files
  if (files && files.length > 0 && mainDish.value) {
    await dishesStore.uploadUserImage(mainDish.value.id, files[0], toast)
    await fetchMainDishImage()
  }
}

function generateImage() {
  if (!mainDish.value) {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('planning.plannedMenu.noMainDish'),
      life: 3000,
    })
    return
  }

  dishesStore.generateImages(mainDish.value.id, toast)
  router.push({ path: `/menu/dishes/${mainDish.value.id}/images` })
}

const selectedShareFormat = ref<string | null>(null);

async function handleShare(format: string) {
  shareData.value = await prepareShareData(format);
  loadingShare.value = true;
  selectedShareFormat.value = format;
}

function onImageGenerated(image: string) {
  imageDataUrl.value = image;
  shareModalVisible.value = true;
  loadingShare.value = false;
  selectedShareFormat.value = null; // Reset after generation
}
</script>

<template>
  <main>
    <Dialog v-if="mainDish" v-model:visible="isImageModalVisible" modal style="max-width: 90%" class="sm:max-w-3xl">
      <DishImage v-if="mainDish" :dishId="mainDish?.id" :type="mainDish?.selected_image" :alt="mainDish?.name"
        ref="dishImageRef" size="full" />
    </Dialog>
    <Menu ref="popupMenu" :model="popUpMenuItems" :popup="true" :pt="{ submenuHeader: { class: '!p-0' } }"
      :pt-options="{ mergeProps: true }" />
    <Menu ref="popupShareMenu" id="overlay_menu" :model="shareMenuItems" popup
      :pt="{ submenuHeader: { class: '!p-0' } }" :pt-options="{ mergeProps: true }">
      <template #item="{ item }">
        <div class="flex items-center p-2 cursor-pointer" @touch="item.command">
          <div class="flex items-center justify-center w-5 h-5">
            <component :is="item.customIcon" class="w-4 h-4 text-primary-950" />
          </div>
          <span class="ml-2 text-sm font-semibold">{{ item.label }}</span>
        </div>
      </template>
    </Menu>
    <div class="flex flex-col gap-6">
      <div class="flex justify-end gap-6 items-top" v-if="!planningStore.dishToPlan">
        <Button v-if="shareMenuItems.length > 0" rounded link :pt="{ root: { class: '!p-1.5 !w-8 !h-8' } }"
          :pt-options="{ mergeProps: true }" @click="togglePopUpShareMenu">
          <template #icon>
            <i v-if="loadingShare" class="pi pi-spin pi-spinner"></i>
            <ButtonShare v-else />
          </template>
        </Button>
        <Button link rounded :pt="{ root: { class: '!p-1.5 !w-8 !h-8 rotate-90' } }" :pt-options="{ mergeProps: true }"
          @click="togglePopUpMenu">
          <template #icon>
            <ButtonMenu />
          </template>
        </Button>
      </div>
      <!-- <div>
      Header with info
    </div> -->
      <div class="flex justify-center my-5 cursor-pointer" v-if="mainDish">
        <!-- {{ mainDish }} -->
        <DishImage v-if="mainDish.imageUrl && mainDish.selected_image !== 'none'" :dishId="mainDish?.id"
          :type="mainDish?.selected_image" :alt="mainDish?.name" ref="dishImageRef"
          @click="isImageModalVisible = true" />
        <div v-else-if="!mainDish.imageUrl" class="mx-auto w-60 h-60">
          <PlaceholderImage @click="togglePlaceholderMenu" />
          <Menu ref="placeholderMenu" :model="placeholderMenuItems" :popup="true" />
        </div>
      </div>
      <div>
        <p class="text-lg text-center">{{ formattedDate }}</p>
      </div>

      <!-- <p class="text-lg text-center">{{ planningStore.selectedPlannedMenu?.name }}</p> -->
      <div class="flex flex-col gap-4">
        <div class="grid items-center grid-cols-12 gap-2">
          <div class="col-span-2 min-w-4">
            <p>
              {{ t('planning.plannedMenu.titleLabel') }}
            </p>
          </div>
          <div class="flex justify-center col-span-9 min-w-52">
            <div class="flex flex-col w-full">
              <InputText id="plannedMenuTitle" v-model="plannedMenuTitle" @blur="updatePlannedMenuTitle"
                class="w-full" />
              <small v-if="titleLengthError" class="text-xs text-accent">
                {{ titleLengthError }}
              </small>
            </div>
          </div>
        </div>
        <div class="grid items-center grid-cols-12 gap-2">
          <div class="col-span-2 min-w-4">
            <p>
              {{ t('planning.plannedMenu.priceLabel') }}
            </p>
          </div>
          <div class="flex justify-center col-span-9 min-w-52">
            <div class="flex flex-col w-full">
              <InputNumber input-id="plannedMenuPrice" v-model="plannedMenuPrice" @blur="updatePlannedMenuPrice"
                :min="0" :step="0.01" mode="currency" currency="CHF" />
            </div>
          </div>
        </div>
      </div>

      <div v-for="(menuCourse, order) in sortedAndFilledCourses" :key="menuCourse?.id">
        <PlannedMenuLine :order="getOrderedIndex(order + 1)" :menu-course="menuCourse"
          @add-or-update-menu-course="addOrUpdateMenuCourse" @delete-menu-course="planningStore.deleteMenuCourse" />
        <!-- <div v-else class="flex justify-center p-6 text-center" >
          <button @click="console.log('add course')"
            class="flex items-center justify-center w-10 p-2 text-white rounded-full bg-accent aspect-square">
            
            <i class="pi pi-plus" style="font-size: 1rem"></i>
          </button>
        </div> -->
      </div>
    </div>

    <Dialog v-if="sortedAndFilledCourses" :header="t('socialNetworks.share')" v-model:visible="shareModalVisible" modal
      style="max-width: 90%" class="sm:max-w-3xl">
      <ShareCard :image="imageDataUrl" />
    </Dialog>

    <input ref="fileInput" type="file" name="dish-image" id="dish-image"
      accept="image/jpeg, image/jpg, image/png, image/webp, image/heic" @change="handleFileUpload"
      class="hidden mb-4" />
  </main>
  <ShareMenu v-if="selectedShareFormat" :share-data="shareData" :format="selectedShareFormat"
    @image-generated="onImageGenerated" />
</template>

<style scoped></style>
