<script setup lang="ts">
import { computed, markRaw, ref, watchEffect, type Ref, onMounted } from 'vue'
import ProfileMenuItem from '@/components/Profile/ProfileMenuItem.vue'
import { useAuth } from '@/composables/useAuth'
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n/index.js'
import { useRouter } from 'vue-router'
import HotIcon from '@/assets/icons/MenuFilters/HotIcon.vue'
import type { AttributesList, Restaurant } from '@/types/restaurants'
import { useToast } from 'primevue/usetoast'
import { useFetch } from '@/composables/useFetch'
import { useEnvStore } from '@/stores/envStore'
import ImagePlaceholderIcon from '@/assets/icons/Profile/ImagePlaceholderIcon.vue'
import ButtonProfile from '@/assets/icons/Buttons/ButtonProfile.vue'
import DiningIcon from '@/assets/icons/Menus/DiningIcon.vue'
import { useUserStore } from '@/stores/useUserStore'
import ButtonOrders from '@/assets/icons/Buttons/ButtonOrders.vue'
import Button from 'primevue/button'
import { useAppStore } from '@/stores/appStore'
import ManagerProfile from '@/components/Profile/ManagerProfile.vue'
import EmployeeProfile from '@/components/Profile/EmployeeProfile.vue'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
// const toast = useToast()
// const router = useRouter()

// const { logout, isFetching } = useAuth()
// const user = ref(useUserStore().user)
// const { fetchData } = useFetch()
// // const router = useRouter()
// const fileInput: Ref<HTMLInputElement | null> = ref(null);
// const restaurant: Ref<Restaurant | null> = ref(null);
// const attributes: Ref<AttributesList | null> = ref(null)
const userStore = useUserStore()



// watchEffect(async () => {
//   if (user.value?.restaurants[0].id && !restaurant.value) {
//     const { data: dbRestaurant, error: dbRestaurantError } = await fetchData(
//       useEnvStore().apiUrl + '/restaurants/' + user.value?.restaurants[0].id,
//       'GET'
//     )
//     if (dbRestaurantError) console.log(dbRestaurantError)
//     else restaurant.value = dbRestaurant

//     const { data: dbAttributes, error: dbAttributesError } = await fetchData(
//       useEnvStore().apiUrl + '/restaurants/attributes',
//       'GET'
//     )
//     if (dbAttributesError) console.log(dbAttributesError)
//     else attributes.value = dbAttributes
//   }
// })

// const userInfos = computed(() => {
//   if (user.value) {
//     return {
//       fullName: `${user.value.first_name} ${user.value.last_name}`,
//       restaurantName: user.value.restaurants[0].name,
//       restaurationType: user.value.restaurants[0].restauration_type,
//     }
//   }
//   return {
//     fullName: '',
//     restaurantName: '',
//     restaurationType: '',
//   }
// })

// async function uploadRestaurantLogo(event: any) {
//   const files = event.target.files
//   if (files.length > 0) {
//     const file: File = files[0]

//     // Taille maximale autorisée (en octets) - ici 2 Mo (2048 Ko)
//     const maxFileSize = 2 * 1024 * 1024

//     // Vérifier la taille de l'image avant de procéder à l'upload
//     if (file.size > maxFileSize) {
//       toast.add({
//         severity: 'error',
//         summary: t('common.error'),
//         detail: t('menuDishView.fileTooLarge', { maxSizeMB: '2' }),
//         life: 3000,
//       })
//       return // Empêcher l'upload si le fichier est trop gros
//     }

//     const formData = new FormData()
//     formData.append('logo', file)

//     try {
//       const { data, error } = await fetchData(
//         `${useEnvStore().apiUrl}/restaurants/${restaurant.value?.id}/logo`,
//         'POST',
//         formData
//       )

//       // Si une erreur est retournée, vérifiez et affichez un message spécifique ou générique
//       if (error) {
//         console.log(error)
//         const errorMessage =
//           error?.details?.errors?.logo?.[0] || t('menuDishView.addUserImageError')

//         toast.add({
//           severity: 'error',
//           summary: t('common.error'),
//           detail: errorMessage,
//           life: 3000,
//         })
//       } else if (data && restaurant.value) {
//         // Si l'opération est réussie, mettez à jour le logo du restaurant
//         restaurant.value.logo_url = data.logo_url
//         restaurant.value.logo = data.logo

//         // toast.add({
//         //   severity: 'success',
//         //   summary: t('common.success'),
//         //   detail: t('menuDishView.addUserImageSuccess'),
//         //   life: 3000,
//         // })
//         await userStore.refreshUser()
//       }
//     } catch (error) {
//       console.error('An unexpected error occurred:', error)
//       toast.add({
//         severity: 'error',
//         summary: t('common.error'),
//         detail: t('menuDishView.addUserImageError'),
//         life: 3000,
//       })
//     }
//   }
// }

// const links = [
//   {
//     name: t('profile.profile.profileTab.profileTabLabel'),
//     icon: markRaw(ButtonProfile),
//     url: '/profile/my-profile',
//   },
//   {
//     name: t('profile.profile.restaurantTab.restaurantTabLabel'),
//     icon: markRaw(DiningIcon),
//     url: '/profile/my-restaurant',
//   },
//   {
//     name: t('profile.profile.restaurantTab.other.mySuppliers'),
//     icon: markRaw(HotIcon),
//     url: '/profile/suppliers',
//   },
//   {
//     name: t('profile.profile.restaurantTab.other.orders'),
//     icon: markRaw(ButtonOrders),
//     url: '/profile/orders',
//   }
//   // {
//   //   name: t('profile.profile.restaurantTab.other.orders'),
//   //   icon: markRaw(ButtonOrders),
//   //   url: '/profile/orders',
//   // }
//   // {
//   //   name: t('profile.profile.restaurantTab.other.tooling'),
//   //   icon: markRaw(SupplierIcon),
//   //   url: '/profile/my-restaurant?showTooling=true',
//   // },
//   // {
//   //   name: 'Abonnement',
//   //   icon: markRaw(CreditCardIcon),
//   //   url: '/profile/subscription',
//   // },
//   // {
//   //   name: 'Mes commandes',
//   //   icon: markRaw(ShoppingCartIcon),
//   //   url: '/profile/orders',
//   // },
//   // {
//   //   name: 'Plats planifiés',
//   //   icon: markRaw(),
//   //   url: '/profile/favorites',
//   // },
//   // {
//   //   name: 'Mes filtres',
//   //   icon: markRaw(FiltersIcon),
//   //   url: '/profile/filters',
//   // },
//   // {
//   //   name: 'Mes statistiques',
//   //   icon: markRaw(StatisticsIcon),
//   //   url: '/profile/statistics',
//   // },
//   // {
//   //   name: 'Mon équipe',
//   //   icon: markRaw(TeamIcon),
//   //   url: '/profile/team',
//   // },
//   // {
//   //   name: 'Historique',
//   //   icon: markRaw(HistoryIcon),
//   //   url: '/profile/generation-history',
//   // },MyProfileView
//   // {
//   //   name: 'Menus favoris',
//   //   icon: markRaw(HeartEmpty),
//   //   url: '/profile/favorites',
//   // },
//   // {
//   // name: 'Mes scans',
//   // icon: markRaw(ScanIcon),
//   // url: '/profile/my-scans',
//   // },
// ]

// // if (useEnvStore().environment !== 'production') {
// //   links.push({
// //     name: t('profile.profile.restaurantTab.other.orders'),
// //     icon: markRaw(ButtonOrders),
// //     url: '/profile/orders',
// //   })
// // }


///////////// PWA INSTALLATION  ////////////
// Function to check if the app is installed
const appStore = useAppStore()
const showPWAButton = computed(() => !appStore.isPwaInstalled)

async function installPWA() {
  appStore.showPrompt = true;
  // if (appStore.isIOS()) {
  // } else {
  //   await appStore.installPwa()
  // }
}
</script>

<template>
  <main class="flex flex-col min-h-[70vh]">
    <ManagerProfile v-if="!userStore.isEmployee"/>
    <EmployeeProfile v-else />
   
    <!-- <Button :label=" t('auth.logout')" class="w-full mt-8" @click="logout" :loading="isFetching" /> -->
    <div class="w-full py-4 mx-auto mt-auto text-center print:hidden">
      <div v-if="showPWAButton" class="my-4">
        <Button @click="installPWA" icon="pi pi-download" :label="t('pwa.installApp')" />
      </div>
      <p class="text-center">
        <span>{{ useEnvStore().appVersion }} - {{ useEnvStore().lastUpdate }}</span>
        <span v-if="useEnvStore().environment !== 'production'">
          | {{ useUserStore().user?.restaurants[0]?.credits_used }} / {{
          useUserStore().user?.restaurants[0]?.total_credits }}
          crédits utilisés
        </span>
      </p>
    </div>
  </main>
</template>
