import { defineStore } from 'pinia'
import { ref, computed, type Ref } from 'vue'
import type { Ingredient, IngredientShopping, IngredientsToOrder } from '@/types/dish'
import { useEnvStore } from './envStore'
import { useFetch } from '@/composables/useFetch'
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n'
import { useToast } from 'primevue/usetoast'
import router from '@/router'
import { useCategories } from '@/composables/useCategories'
import { adjustUnitsForQuantity } from '@/utils/prices'
import type { ToastMessageOptions } from 'primevue/toast'
import { groupIngredients } from '@/utils/orders'

export const useOrderList = defineStore('orders', () => {
  function displayToast(message: string, severity: ToastMessageOptions['severity']) {
    toast.add({
      severity: severity,
      summary: t(`common.${severity}`),
      detail: message,
      life: 3000,
    })
  }

  const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
  const toast = useToast()
  const { categories } = useCategories()
  const envStore = useEnvStore()
  const { fetchData } = useFetch()
  const isLoading = ref(false)
  const error: Ref<any> = ref(null)
  const shouldRefresh = ref(true)
  // TODO 31.01.25 remove const ingredientsByCategory = ref<{ [key: string]: Ingredient[] }>({})
  const ingredientsByCategory = computed(() => {
    const categorized: { [key: string]: Ingredient[] } = {}

    // Initialize categories
    categories.value.forEach((cat) => {
      categorized[cat.value] = []
    })

    // Group ingredients by category
    ingredientsList.value.forEach((ingredient) => {
      // console.log("ingredient", ingredient)
      const category = ingredient.category
      if (!categorized[category]) {
        categorized[category] = []
      }

      // Transform ingredient data
      const quantity = ingredient.quantity ?? 0
      const convertedQuantity = adjustUnitsForQuantity(quantity, ingredient.unit)

      categorized[category].push({
        ...ingredient,
        quantity: convertedQuantity.quantity,
        unit: convertedQuantity.unit
      })
    })
    // console.log('categorized ingredients', categorized)

    return categorized
  })
  const ingredientsList = ref<IngredientShopping[]>([])
  const ingredientsToOrder = ref<IngredientsToOrder>({ ingredients: [] })

  const ingredientsByDishes = computed(() => {
    const grouped: {
      plannedDishes: { [key: string]: {date:string, dish_id: number, selected: boolean, ingredients: IngredientShopping[]} };
      notPlannedDishes: { [key: string]: { date: string, dish_id: number, selected: boolean, ingredients: IngredientShopping[] } };
      noDish: IngredientShopping[];
    } = {
      plannedDishes: {},
      notPlannedDishes: {},
      noDish: []
    };

    ingredientsList.value.forEach((ingredient) => {
      if (!ingredient.dish_id || !ingredient.dish) {
        grouped.noDish.push(ingredient);
      } else {
        const dishKey = `${ingredient.dish.name}`;
        if (ingredient.dish.isPlanned && ingredient.dish.isPlanned.length > 0) {
          if (!grouped.plannedDishes[dishKey]) {
            grouped.plannedDishes[dishKey] = { date: ingredient.dish.isPlanned[0].date, dish_id: ingredient.dish_id ?? -1, selected: false, ingredients: [] };
          }
          grouped.plannedDishes[dishKey].ingredients.push(ingredient);
          // grouped.plannedDishes.push(ingredient);
        } else {
          if (!grouped.notPlannedDishes[dishKey]) {
            grouped.notPlannedDishes[dishKey] = { date: '', dish_id: ingredient.dish_id ?? -1, selected: false, ingredients: [] };
          }
          grouped.notPlannedDishes[dishKey].ingredients.push(ingredient);
          // grouped.notPlannedDishes.push(ingredient);
        }
      }
    });



    const sortedPlanned = Object.entries(grouped.plannedDishes)
      .sort(([, ingredientsA], [, ingredientsB]) => {
        const dateA = new Date(ingredientsA.date);
        const dateB = new Date(ingredientsB.date);
        return dateA.getTime() - dateB.getTime();
      })
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
      // console.log('sortedPlanned', sortedPlanned)

    grouped.plannedDishes = sortedPlanned;

    console.log("grouped", grouped)
    return grouped;
    // return Object.fromEntries(
    //   Object.entries(grouped)
    //     .sort(([keyA], [keyB]) => {
    //       // Force "noDish" to be last by special comparison
    //       if (keyA === 'noDish') return 1;
    //       if (keyB === 'noDish') return -1;
    //       // Regular alphabetical sort for other keys
    //       return keyA.localeCompare(keyB);
    //     })
    // );
  });

  // function initializeCategories() {
  //   categories.value.forEach((cat) => {
  //     ingredientsByCategory.value[cat.value] = []
  //   })
  // }

  /**
   * Take the ingredients, and group the same ingredients into a group
   * @param ingredients Ingredients to add to the order
   * @returns array of Ingredient in this format : [{group: [{grouped_ingredient_infos, ingredients: [Ingredient]}], ingredients: [Ingredient]}]
   */
  function addIngredientsToOrder(ingredients: Ingredient[], replace = false) {
    if (replace) {
      ingredientsToOrder.value = { ingredients: [], groups: [] }
    }
    const groupedIngredients = groupIngredients(ingredients, false)
    ingredientsToOrder.value = {
      ingredients: [...(ingredientsToOrder.value.ingredients || []), ...(groupedIngredients.ingredients || [])],
      groups: [...(ingredientsToOrder.value.groups || []), ...(groupedIngredients.groups || [])]
    }
  }

  async function fetchIngredients(restaurantId) {
    console.log('Fetching ingredients')

    isLoading.value = true
    error.value = null

    try {
      const { data, error: fetchError } = await fetchData(
        `${useEnvStore().apiUrl}/restaurants/${restaurantId}/ingredients`,
        'GET'
      )
      if (fetchError) {
        error.value = fetchError
        displayToast(t('creationFilters.generationError'), 'error')
      } else {
        // initializeCategories()
        setIngredients(data)
        console.log('ingredients fetched, data : ', data)
      }
      shouldRefresh.value = false
    } catch (err) {
      error.value = err
      displayToast(t('creationFilters.generationError'), 'error')
    } finally {
      isLoading.value = false
    }
  }

  function transformIngredientData(ingredients: Ingredient[]): Ingredient[] {
    return ingredients.map((ingredient) => {
      // Apply necessary transformations, e.g., unit conversion
      const quantity = ingredient.quantity ?? 0
      const convertedQuantity = adjustUnitsForQuantity(quantity, ingredient.unit)

      ingredient.quantity = convertedQuantity.quantity
      ingredient.unit = convertedQuantity.unit
      return ingredient
    })
  }
  // This function transforms and sets the ingredients into a categorized object
  function setIngredients(data) {
    console.log('setting ingredient : ', data)
    ingredientsList.value = data
    // Object.keys(data).forEach((category) => {
    //   ingredientsByCategory.value[category] = transformIngredientData(data[category])
    // })

    // console.log('Ingredients by category', ingredientsByCategory.value)
  }

  // Adds new ingredients to the store and makes an API call to save them
  async function addIngredients(newIngredients, restaurantId, pathToRedirect = '') {
    // if (!Object.keys(ingredientsByCategory.value).length) {
    //   // initializeCategories()
    // }
    isLoading.value = true
    // Group new ingredients by category for local state management
    newIngredients.forEach((ingredient) => {
      const convertedUnitAndQuantity = adjustUnitsForQuantity(ingredient.quantity, ingredient.unit)
      ingredientsList.value.push({
        ...ingredient,
        quantity: convertedUnitAndQuantity.quantity,
        unit: convertedUnitAndQuantity.unit,
      })
    })

    const payload = { ingredients: newIngredients }

    // Perform API call for all ingredients
    const { data, error } = await fetchData(
      `${envStore.apiUrl}/restaurants/${restaurantId}/ingredients`,
      'POST',
      payload
    )

    if (error) {
      // Rollback the local state if there's an error
      newIngredients.forEach((ingredient) => {
        const index = ingredientsList.value.findIndex(i => i.id === ingredient.id)
        if (index !== -1) {
          ingredientsList.value.splice(index, 1)
        }
      })
      displayToast(t('orders.addingIngredientsError'), 'error')
    } else {
      // Update was successful
      displayToast(t('orders.addingIngredientsSuccess'), 'success')
      fetchIngredients(restaurantId)
      if (pathToRedirect) {
        console.log('Redirect user to the path')
        if (pathToRedirect == '-1')
          router.go(-1)
        else
          router.push({ path: pathToRedirect })
      }
    }
    isLoading.value = false
  }

  async function updateIngredient(ingredient, restaurantId) {
    console.log('ingredient that will be updated ', ingredient)

    // Find the ingredient in the list
    const index = ingredientsList.value.findIndex((ing) => ing.id === ingredient.id)

    if (index !== -1) {
      const originalIngredient = ingredientsList.value[index]
      const convertedUnitAndQuantity = adjustUnitsForQuantity(ingredient.quantity, ingredient.unit)

      // Update the ingredient with converted units
      const updatedIngredient = {
        ...originalIngredient,
        ...ingredient,
        quantity: convertedUnitAndQuantity.quantity,
        unit: convertedUnitAndQuantity.unit
      }

      // Optimistically update the local state
      ingredientsList.value[index] = updatedIngredient

      console.log('sending the ingredient to be updated', updatedIngredient)
      const { data, error } = await fetchData(
        `${envStore.apiUrl}/restaurants/${restaurantId}/ingredients/${ingredient.id}`,
        'PUT',
        updatedIngredient
      )

      if (error) {
        // Rollback to original ingredient if there's an error
        ingredientsList.value[index] = originalIngredient
        displayToast(t('orders.updatingIngredientError'), 'error')
      } else {
        displayToast(t('orders.updatingIngredientSuccess'), 'success')
      }
    } else {
      displayToast(t('orders.ingredientNotFound'), 'error')
    }
  }

  // Gets ingredients by category, useful for rendering specific sections
  function getIngredientsByCategory(category) {
    return computed(() => ingredientsByCategory.value[category] || [])
  }

  async function removeIngredient(ingredientId, restaurantId) {
    console.log('Attempting to delete ingredient', { ingredientId, restaurantId })

    // Find the ingredient and remove it optimistically
    const index = ingredientsList.value.findIndex((ing) => ing.id === ingredientId)
    let originalIngredient = <IngredientShopping>{}

    if (index !== -1) {
      // Store the ingredient in case we need to rollback
      originalIngredient = { ...ingredientsList.value[index] }

      // Remove the ingredient optimistically
      ingredientsList.value.splice(index, 1)

      // Send the deletion request to the backend
      try {
        const response = await fetchData(
          `${envStore.apiUrl}/restaurants/${restaurantId}/ingredients/${ingredientId}`,
          'DELETE'
        )

        if (response.error) {
          throw response.error
        }

        // Show success toast
        displayToast(t('orders.deletingIngredientSuccess'), 'success')
      } catch (error) {
        console.error('Failed to delete ingredient:', error)

        // Rollback: restore the original ingredient in the local state
        ingredientsList.value.splice(index, 0, originalIngredient)

        // Show error toast
        displayToast(t('orders.deletingIngredientError'), 'error')
      }
    } else {
      displayToast(t('orders.ingredientNotFound'), 'error')
    }
  }

  async function removeIngredients(ingredientIds, restaurantId) {
    console.log('Attempting to delete ingredients', { ingredientIds, restaurantId })

    // Store original ingredients for potential rollback
    const removedIngredients = ingredientsList.value.filter(ingredient =>
      ingredientIds.includes(ingredient.id)
    )

    // Optimistically remove ingredients from local state
    ingredientsList.value = ingredientsList.value.filter(ingredient =>
      !ingredientIds.includes(ingredient.id)
    )

    isLoading.value = true
    try {
      const payload = { ingredientIds }
      const { data, error } = await fetchData(
        `${useEnvStore().apiUrl}/restaurants/${restaurantId}/ingredients`,
        'DELETE',
        payload
      )

      if (error) {
        // Rollback - Restore the removed ingredients if the API call fails
        console.error('Error deleting ingredients:', error)
        displayToast(t('orders.deletingIngredientsError'), 'error')
        ingredientsList.value = [...ingredientsList.value, ...removedIngredients]
      } else {
        displayToast(t('orders.deletingIngredientsSuccess'), 'success')
        // Optionally, refresh the local ingredients list to reflect changes
        fetchIngredients(restaurantId)
      }
    } catch (error) {
      console.error('Failed to delete ingredients:', error)
      displayToast(t('orders.deletingIngredientsError'), 'error')
      // Rollback - Restore the removed ingredients
      ingredientsList.value = [...ingredientsList.value, ...removedIngredients]
    } finally {
      isLoading.value = false
    }
  }

  async function removeDishIngredients(dishId, restaurantId) {
    isLoading.value = true

    // Store original ingredients for potential rollback
    const originalIngredients = [...ingredientsList.value]

    // Remove ingredients from local state
    ingredientsList.value = ingredientsList.value.filter(
      (ingredient) => ingredient.dish_id !== dishId
    )

    // Call API to remove ingredients for the specific dish from the server
    try {
      const { error } = await fetchData(
        `${useEnvStore().apiUrl}/dishes/${dishId}/shopping-list`,
        'DELETE'
      )

      if (error) {
        // Rollback if there's an error
        ingredientsList.value = originalIngredients
        displayToast(t('orders.deletingIngredientsError'), 'error')
        return { success: false }
      } else {
        displayToast(t('orders.deletingIngredientsSuccess'), 'success')
        // If needed, re-fetch ingredients to update the store with current server state
        fetchIngredients(restaurantId)
        return { success: true }
      }
    } catch (error) {
      // Rollback on error
      ingredientsList.value = originalIngredients
      displayToast(t('orders.deletingIngredientsError'), 'error')
      console.error('Failed to delete ingredients by dishId:', error)
      return { success: false }
    } finally {
      isLoading.value = false
    }
  }

  return {
    ingredientsToOrder,
    ingredientsByCategory,
    ingredientsList,
    addIngredientsToOrder,
    setIngredients,
    addIngredients,
    updateIngredient,
    getIngredientsByCategory,
    removeIngredient,
    removeIngredients,
    removeDishIngredients,
    fetchIngredients,
    isLoading,
    error,
    ingredientsByDishes,
    shouldRefresh
  }
})
