<script setup lang="ts">
import HeartEmpty from '@/assets/icons/2States/HeartEmpty.vue';
import EmployeesIcon from '@/assets/icons/Employees/EmployeesIcon.vue';
import PlanningIcon from '@/assets/icons/Home/PlanningIcon.vue';
import TagIcon from '@/assets/icons/Menus/TagIcon.vue';
import NavCreateIcon from '@/assets/icons/NavBar/NavCreateIcon.vue';
import ScanIcon from '@/assets/icons/Profile/ScanIcon.vue';
import ShortcutButtonHomePage from '@/components/Home/ShortcutButtonHomePage.vue'
import type { MessageSchema } from '@/i18n';
import { useUserStore } from '@/stores/useUserStore';
import { ref, type Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useEnvStore } from '@/stores/envStore';
import { useToast } from 'primevue/usetoast';
import { useFetch } from '@/composables/useFetch';
import LoadingBar from '@/components/Skeletons/LoadingBar.vue'
import LoadingView from './LoadingView.vue';
import { useDishesStore, useRecipeStore } from '@/stores/dishesStore';

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })



const { fetchData } = useFetch();
const router = useRouter();
const { apiUrl } = useEnvStore();
const toast = useToast();

interface shortCutOptions {
  text: string;
  navigate: string;
  icon?: any;
  disabled?: boolean;
}

const featuresOptions: shortCutOptions[] = [
  { text: t('homeView.myRecipes.myCategories'), navigate: '/my-recipes/tags', icon: TagIcon },
  { text: t('homeView.myRecipes.favourites'), navigate: '/my-recipes/favorites', icon: HeartEmpty },
  { text: t('homeView.planning.planning'), navigate: '/planning', icon: PlanningIcon },
  { text: t('homeView.planning.planningPeople'), navigate: '/', icon: EmployeesIcon, disabled: true },
  // { text: t('homeView.create.quickCreation'), navigate: '/menu/create?type=quick', icon: ScanIcon },
  // { text: t('homeView.create.mainProductCreation'), navigate: '/menu/create?type=with_main_product', icon: MainDishIcon },
  // { text: t('homeView.create.customTitleCreation'), navigate: '/menu/create?type=from_title', icon: CustomTitleIcon },
];

const mainFeaturesOptions: shortCutOptions[] = [
  { text: t('homeView.myRecipes.scan'), navigate: '/my-recipes/my-scans', icon: ScanIcon },
  { text: t('homeView.create.generateRecipes'), navigate: '/menu/create', icon: NavCreateIcon },
];


// const planningOptions: shortCutOptions[] = [
// ];

const user = ref(useUserStore().user);
// Ref for managing ImportDish visibility
const isImportDishVisible = ref(false);

// Function to handle scan button click
const scanInput: Ref<HTMLInputElement | null> = ref(null);
const file: Ref<File | null> = ref(null);

const isAnalyzing = ref(false)

function handleScanClick() {
  // Trigger the hidden file input to open the camera
  scanInput.value?.click();
}

// Function to handle file change (camera capture)
function handleFileChange(event) {
  const files = event.target.files;
  if (files.length > 0) {
    file.value = files[0];
    analyzeImage();
  } else {
    file.value = null;
  }
}

// Function to analyze the captured image
function analyzeImage() {
  if (!file.value) return
  if (!useUserStore().validateUserCanGenerate()) {
    return null;
  }
  console.log('Analyzing dish...')
  isAnalyzing.value = true

  const formData = new FormData();
  formData.append('image', file.value);

  fetchData(`${apiUrl}/dishes/import/analyze`, 'POST', formData, 105000)
    .then(({ data, error }) => {
      if (error) {
        console.error('Upload failed:', error);
        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: t('profileFavorites.analyzeError'),
          life: 3000,
        });
        return;
      } else {
        console.log('Upload successful:', data);
        // toast.add({
        //   severity: 'success',
        //   summary: t('common.success'),
        //   detail: t('profileFavorites.analyzeSuccess'),
        //   life: 3000,
        // });

        // Set the recipe and dish data to the stores
        useRecipeStore().recipe = data.recipe
        useDishesStore().selectedDish = data.dish

        // useOcrDishStore().ocrDish = data
        useDishesStore().selectedDish!.ingredients = useDishesStore().selectedDish!.ingredients.map(
          (ingredient) => {
            if (ingredient.quantity === 0) {
              ingredient.quantity = undefined
            }
            if (ingredient.quantity === undefined && ingredient.unit) {
              ingredient.unit = ''
            }
            return ingredient
          }
        )
        router.push({ path: `/menu/dishes/${useDishesStore().selectedDish!.id}/recipe-steps` })
        return;
      }
    })
    .finally(() => {
      isAnalyzing.value = false
    })
}
</script>

<style scoped>
.hello-container {
  position: relative;
  display: inline-block;
}

.hello-container::after {
  content: '';
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 24%;
  height: 8px;
  background-color: #ED8D4C;
  opacity: 0.8;
  z-index: -1;
}

@media screen and (max-height: 520px) {
  .text-welcome {
    display: none;
  }
}
@media screen and (max-height: 663px) {

  .avatar-image {
   display: none;
  }
}

.hello-container {
  font-size: 1rem;
}

@media screen and (min-width: 360px) {
  .hello-container {
    font-size: 1.5rem;
  }
    .hello-container::after {
      content: '';
      position: absolute;
      bottom: 0px;
      right: 0px;
      width: 24%;
      height: 12px;
      background-color: #ED8D4C;
      opacity: 0.8;
      z-index: -1;
    }
}

@media screen and (min-width: 500px) {
  .hello-container {
    font-size: 1.75rem;
  }
    .hello-container::after {
      content: '';
      position: absolute;
      bottom: 0px;
      right: 0px;
      width: 24%;
      height: 14px;
      background-color: #ED8D4C;
      opacity: 0.8;
      z-index: -1;
    }
}
</style>

<template>
  <main>
    <div v-if="isAnalyzing" class="p-6">
      <LoadingView :generation="true" />
      <LoadingBar class="mt-24 mb-3" :durationInSeconds="40" />
    </div>
    <div v-else>
      <div id="home-header">
        <div class="relative">
          <div class="text-welcome">
            <h1 class="w-4/5 m-auto my-2 text-center" v-if="user">
              <span class="max-w-full truncate hello-container">{{ t('homeView.hello') }} {{
                user.first_name }} !</span>
            </h1>
            <h1 class="my-2 text-center" v-else>
              <span class="hello-container">{{ t('homeView.hello') }} !</span>
            </h1>

          </div>
          <img class="absolute top-0 w-full rotate-180 opacity-10 -z-10"
            src="@/assets/images/home-page-background.webp" />
        </div>
        <div class="mt-4 text-center avatar-image">
          <img src="@/assets/images/avatar-rounded.webp" :alt="t('homeView.altImageAvatar')"
            class="w-1/4 mx-auto max-w-32">
        </div>
      </div>
      <div class="flex flex-col justify-between">
        <!--<div>
        <div class="my-8">
          <h3 class="my-4">{{ t('homeView.create.createRecipesLabel')}}</h3>
          -->
        <div class="absolute bottom-16 w-full max-w-[640px]">
          <div class="px-6">
            <h3>
              {{ t('homeView.features') }}
            </h3>
          </div>
          <div class="relative flex flex-col items-center gap-6 p-6 mt-12 bg-gray-100">

            <div class="inline-flex items-start justify-between w-full max-w-80 mt-28">
              <ShortcutButtonHomePage v-for="dishCreation in featuresOptions" :key="dishCreation.text"
                :icon="dishCreation.icon" :text="dishCreation.text" :navigate="dishCreation.navigate"
                :disabled="dishCreation.disabled" :small="true" />
            </div>
            <div class="absolute flex flex-row items-start content-between justify-center gap-3 -top-10">
              <ShortcutButtonHomePage v-for="dishCreation in mainFeaturesOptions" :key="dishCreation.text"
                :icon="dishCreation.icon" :text="dishCreation.text" :navigate="dishCreation.navigate"
                :disabled="dishCreation.disabled" />
              <!-- <ShortcutButtonHomePage :icon="ScanIcon" :text="t('homeView.myRecipes.scan')" navigate="/"
                @click="handleScanClick" />
              <ShortcutButtonHomePage :icon="NavCreateIcon" :text="t('homeView.create.generateRecipes')"
                navigate="/menu/create" /> -->
            </div>
          </div>
        </div>
        <!--
        </div>
        <div class="my-8">
          <h3 class="my-4">{{ t('homeView.myRecipes.myRecipesLabel') }}</h3>
          <div class="flex flex-row items-start content-between justify-center gap-3">
            <ShortcutButtonHomePage v-for="myDishes in myDishesOptions" :key="myDishes.text" :icon="myDishes.icon"
              :text="myDishes.text" :navigate="myDishes.navigate" :disabled="myDishes.disabled" />
          </div>
        </div>
        <div class="my-8">
          <h3 class="my-4">{{ t('homeView.planning.planningLabel') }}</h3>
          <div class="flex flex-row items-start content-between justify-center gap-3">
            <ShortcutButtonHomePage v-for="planning in planningOptions" :key="planning.text" :icon="planning.icon"
              :text="planning.text" :navigate="planning.navigate" :disabled="planning.disabled" />
          </div>
        </div> 
      </div>-->
      </div>
    </div>
    <!-- <ImportDish v-if="isImportDishVisible" @update-analyze-status="handleImportDishClose" />
    <input ref="scanInput" type="file" name="recipe-scan" id="recipe-scan"
      accept="image/jpeg, image/jpg, image/png, image/webp, image/heic" capture="environment" @change="handleFileChange"
      class="hidden mb-4" />-->

  </main>
</template>
