<script setup lang="ts">
import DiningIcon from '@/assets/icons/Menus/DiningIcon.vue'
import ScanIcon from '@/assets/icons/Profile/ScanIcon.vue'
import { useFetch } from '@/composables/useFetch.js'
import type { MessageSchema } from '@/i18n/index.js'
import { useDishesStore, useRecipeStore } from '@/stores/dishesStore.js'
import { useEnvStore } from '@/stores/envStore.js'
import { useUserStore } from '@/stores/useUserStore'
import Button from 'primevue/button'
import Sidebar from 'primevue/sidebar'
import SpeedDial from 'primevue/speeddial'
import { useToast } from 'primevue/usetoast'
import { markRaw, ref, type Ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import FileSource from '@/components/BaseComponents/FileSource.vue'

const emit = defineEmits<{
  (e: 'updateAnalyzeStatus', boolean): boolean
}>()

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const toast = useToast()
const router = useRouter()
const { fetchData } = useFetch()

// const dishInput: Ref<HTMLInputElement | null> = ref(null)
// const recipeInput: Ref<HTMLInputElement | null> = ref(null)

// -------------- DIALOG VISIBILITY -------------- //
const showDishDialog = ref(false)
const showRecipeDialog = ref(false)

// -------------- REFS FOR HIDDEN INPUTS -------------- //
// Dish inputs
const dishCameraInput = ref<HTMLInputElement | null>(null)
const dishGalleryInput = ref<HTMLInputElement | null>(null)

// Recipe inputs
const recipeCameraInput = ref<HTMLInputElement | null>(null)
const recipeGalleryInput = ref<HTMLInputElement | null>(null)

const scanMenuItems = ref([
  {
    label: t('scans.scanDish'),
    icon: 'pi pi-image',
    customIcon: markRaw(DiningIcon),
    command: () => {
      showDishDialog.value = true;
    },
  },
  {
    label: t('scans.scanRecipe'),
    icon: 'pi pi-receipt',
    customIcon: markRaw(ScanIcon),
    command: () => {
      showRecipeDialog.value = true;
    },
  },
])

// const file: Ref<File | null> = ref(null)
function handleDishScanChange(event: Event) {
  const input = event.target as HTMLInputElement
  if (!input.files?.length) return
  const selectedFile = input.files[0]
  analyzeImage(selectedFile, 'dish')
}

function handleRecipeScanChange(event: Event) {
  const input = event.target as HTMLInputElement
  if (!input.files?.length) return
  const selectedFile = input.files[0]
  analyzeImage(selectedFile, 'recipe')
}

function analyzeImage(file: File, type: 'dish' | 'recipe') {
  const userStore = useUserStore()
  if (!userStore.validateUserCanGenerate()) {
    return
  }
    
  console.log(`Analyzing ${type}...`)
  emit('updateAnalyzeStatus', true)

  // Build FormData
  const formData = new FormData()
  formData.append('image', file)
  formData.append('type', type)

  const { apiUrl } = useEnvStore()
  fetchData(`${apiUrl}/dishes/import/analyze`, 'POST', formData, 105000)
    .then(({ data, error }) => {
      if (error) {
        console.error('Upload failed:', error)
        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: t('profileFavorites.analyzeError'),
          life: 3000,
        })
        return
      } else {
        console.log('Upload successful:', data)

        useRecipeStore().recipe = data.recipe
        useDishesStore().selectedDish = data.dish
        useDishesStore().shouldRefetch = true

        // Example cleaning
        const dishStore = useDishesStore()
        dishStore.selectedDish!.ingredients = dishStore.selectedDish!.ingredients.map((ing) => {
          if (ing.quantity === 0) {
            ing.quantity = undefined
          }
          if (ing.quantity === undefined && ing.unit) {
            ing.unit = ''
          }
          return ing
        })

        // Navigate to next page
        router.push({ path: `/menu/dishes/${dishStore.selectedDish!.id}/recipe-steps` })
        return
      }
    })
    .finally(() => emit('updateAnalyzeStatus', false))
}

// -------------- METHODS TO OPEN INPUTS -------------- //
function openDishCamera() {
  dishCameraInput.value?.click()
  showDishDialog.value = false
}
function openDishGallery() {
  dishGalleryInput.value?.click()
  showDishDialog.value = false
}
function openRecipeCamera() {
  recipeCameraInput.value?.click()
  showRecipeDialog.value = false
}
function openRecipeGallery() {
  recipeGalleryInput.value?.click()
  showRecipeDialog.value = false
}

function handleDishFileUploaded(payload: { type: 'camera' | 'gallery', file: File }) {
  analyzeImage(payload.file, 'dish')
}
function handleRecipeFileUploaded(payload: { type: 'camera' | 'gallery', file: File }) {
  analyzeImage(payload.file, 'recipe')
}
</script>

<template>
  <div>
    <SpeedDial :model="scanMenuItems" :radius="120" type="linear" direction="up" class="!fixed bottom-24 right-8 "
      :pt="{ root: { style: { 'align-items': 'flex-end' } }, menu: { style: { 'align-items': 'flex-end' } } }">
      <template #item="{ item, onClick }">
        <button
          class="flex items-center justify-end gap-3 px-4 py-3 my-1 text-white rounded-full bg-surface-600 hover:bg-surface-700"
          @click="onClick">
          <p class="text-sm text-white font-content">{{ item.label }}</p>
          <component :is="item.customIcon" class="w-4 h-4 text-white" />
        </button>
      </template>
    </SpeedDial>
  </div>

  <FileSource v-model:visible="showDishDialog" :header="t('scans.chooseSource')"
    @file-uploaded="handleDishFileUploaded" />

  <FileSource v-model:visible="showRecipeDialog" :header="t('scans.chooseSource')"
    @file-uploaded="handleRecipeFileUploaded" />

</template>
