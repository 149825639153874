<script setup lang="ts">
import CreationFilter from '@/components/Menus/Filters/CreationFilter.vue'
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import Textarea from 'primevue/textarea'
import { ref, watchEffect, type Ref, computed, onUnmounted } from 'vue';
import { useDishesStore } from '@/stores/dishesStore';
import { useEnvStore } from '@/stores/envStore'
import { watch } from 'vue';
import FileSource from '@/components/BaseComponents/FileSource.vue'
const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const dishesStore = ref(useDishesStore());
const selectedFileName = ref('');
const imagePreview = ref('');


// New ref to control FileSource modal visibility
const showIngredientFileSource = ref(false);

function handleIngredientFileUploaded(payload: { type: 'camera' | 'gallery', file: File }) {
  // Revoke previous preview if exists
  if(imagePreview.value) {
    URL.revokeObjectURL(imagePreview.value);

  }
  selectedFileName.value = payload.file.name;
  dishesStore.value.generationParams.img_product = payload.file;
  imagePreview.value = URL.createObjectURL(payload.file);
}

const removePicture = () => {
  if (imagePreview.value) {
    URL.revokeObjectURL(imagePreview.value)
  }
  selectedFileName.value = '';
  dishesStore.value.generationParams.img_product = null as unknown as File;
  imagePreview.value = '';
};

watch(
  () => dishesStore.value.generationParams.img_product,
  (newImg) => {
    if (!newImg) {
      selectedFileName.value = '';
      imagePreview.value = '';
    }
  }
);

// onUnmounted(() => {
//   if (imagePreview.value) {
//     URL.revokeObjectURL(imagePreview.value)
//   }
// })
</script>

<template>
  <CreationFilter>
    <template #title>{{ t('creationFilters.mainProduct') }}</template>
    <template #content>
      <div class="flex flex-row items-center justify-between">
        <!-- Always show the Textarea in production and force full width -->
        <Textarea v-if="!selectedFileName" v-model="dishesStore.generationParams.main_product"
          :class="dishesStore.generationParams.main_product ? 'w-full' : 'w-1/2'" rows="2"
          :placeholder="t('creationFilters.mainProductPlaceholder')" />
        <!-- 'or' text hidden in production -->
        <div v-if="!selectedFileName && !dishesStore.generationParams.main_product">
          {{ t('common.or') }}
        </div>
        <!-- Replace triggerFileInput button with FileSource trigger -->
        <div v-if="!dishesStore.generationParams.main_product || selectedFileName"
          :class="selectedFileName ? 'w-full' : 'w-1/4'">
          <!-- Show a button to open the FileSource modal if no file selected -->
          <button v-if="!selectedFileName"
            class="flex items-center justify-center w-10 p-2 text-white rounded-full bg-accent aspect-square"
            @click="showIngredientFileSource = true">
            <i class="pi pi-camera" style="font-size: 1rem"></i>
          </button>
          <!-- Display image preview when file is selected -->
          <div v-if="selectedFileName && dishesStore.generationParams.img_product"
            class="flex flex-row items-center justify-center gap-2">
            <img :src="imagePreview" class="object-cover w-20 h-20 rounded-lg" />
            <button @click="removePicture" class="flex items-center">
              <span class="pi pi-times"></span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </CreationFilter>
  <!-- Include FileSource modal -->
  <FileSource v-model:visible="showIngredientFileSource" :header="t('scans.chooseSource')"
    @file-uploaded="handleIngredientFileUploaded" />
</template>
