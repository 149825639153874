import { createRouter, createWebHistory, type RouteLocationNormalized } from 'vue-router'
import { useUserStore } from '@/stores/useUserStore'
import adminRoutes from '@/router/adminRoutes'
import authRoutes from '@/router/authRoutes'
import homeRoutes from '@/router/homeRoutes'
import loadingRoutes from '@/router/loadingRoutes'
import menuRoutes from '@/router/menuRoutes'
import ordersRoutes from '@/router/ordersRoutes'
import planningRoutes from '@/router/planningRoutes'
import profileRoutes from '@/router/profileRoutes'
import searchRoutes from '@/router/searchRoutes'
import { useAppStore } from '@/stores/appStore'
import { useRouterStore } from '@/stores/routerStore'
import HomeView from '@/views/HomeView.vue'
import myRecipesRoutes from '@/router/myRecipesRoutes'
import suppliersRoutes from '@/router/suppliersRoute'
import employeeRoutes from './employeeRoutes'
import type { PermissionCategory } from '@/constants/permissions'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    ...adminRoutes,
    ...authRoutes,
    ...homeRoutes,
    ...loadingRoutes,
    ...menuRoutes,
    ...ordersRoutes,
    ...planningRoutes,
    ...profileRoutes,
    ...searchRoutes,
    ...myRecipesRoutes,
    ...suppliersRoutes,
    ...employeeRoutes,
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: HomeView },
  ],
  scrollBehavior(to) {
    if (to.name !== 'generation-history')
      return {
        top: 0,
        // behavior: 'smooth',
      }
  },
})

router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  // console.log('from : ', from)
  // console.log('to : ', to)

  if (!to.meta.footerConfig || !to.meta.headerConfig || !to.meta.securityConfig) {
    console.log('missing meta')
    // Check if user is employee and redirect accordingly
    const userStore = useUserStore();
    if (userStore.isEmployee) {
      return { name: 'timeclock' }
    }
    return { name: 'home' }
  }

  const userStore = useUserStore()
  await userStore.initUser()
  if (userStore.user) {
    // Conditionally update the restaurant information
    await userStore.updateRestaurantInfo()
  }

  const homePage = userStore.isEmployee ? 'timeclock' : 'home'

  if (to.meta.securityConfig.requireAuthentication) {
    if (userStore.user) {
      if (to.meta.securityConfig.rolesAllowed.includes(userStore.user.role)) {

        if (to.meta.securityConfig.restaurantRoleAllowed &&
          userStore.activeRestaurant &&
          userStore.activeRestaurant.role) {

          // Check if user's restaurant role is allowed
          const restaurantRoleAllowed = to.meta.securityConfig.restaurantRoleAllowed;
          if (!restaurantRoleAllowed.includes(userStore.activeRestaurant.role)) {
            console.log(`User's restaurant role ${userStore.activeRestaurant.role} not allowed for this route`);
            useAppStore().appReady = true;
            return { name: homePage };
          }
        }


        // Then check restaurant-specific permissions if needed
        if (to.meta.securityConfig.requiredPermissions) {
          if (userStore.activeRestaurant && userStore.activeRestaurant.role === 'admin') {
            console.log('Restaurant admin bypassing permission checks');
          } else {
            const permissions = to.meta.securityConfig.requiredPermissions;

            // If it's an array of permissions, check if user has ANY of them
            if (Array.isArray(permissions)) {
              const hasAnyPermission = permissions.some(({ category, action }) =>
                userStore.hasPermission(category as PermissionCategory, action)
              );

              if (!hasAnyPermission) {
                console.log(`User lacks any of the required permissions`);
                useAppStore().appReady = true;
                return { name: homePage };
              }
            } else {
              // Single permission object
              const { category, action } = permissions;
              if (!userStore.hasPermission(category as PermissionCategory, action)) {
                console.log(`User lacks permission: ${category}.${action}`);
                useAppStore().appReady = true;
                return { name: homePage };
              }
            }
          }
        }
        console.log('User authorized')
        useAppStore().appReady = true
        return true
      }
      console.log('User not authorized')
      useAppStore().appReady = true
      return { name: homePage }
    } else {
      console.log('User not logged in')
      useAppStore().appReady = true
      return { name: 'login' }
    }
  } else {
    if (
      (to.name === 'login' || to.name === 'register' || to.name === 'forgot-password') &&
      userStore.user
    ) {
      useAppStore().appReady = true
      return { name: homePage }
    } else {
      useAppStore().appReady = true
      return true
    }
  }
})

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  useRouterStore().previousPath = from.path
})

export default router
