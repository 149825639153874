import { useEnvStore } from "@/stores/envStore"
import { ref } from "vue"
import { useFetch } from "./useFetch"

export const useManager = () => {
  const isLoading = ref(false)
  const error = ref(null)
  const envStore = useEnvStore();
  const { fetchData } = useFetch()

  async function getTeam(restaurant_user_id: number) {
    console.log('getTeam')
    isLoading.value = true
    error.value = null

    try {
      const { data, error: fetchError } = await fetchData(
        `${envStore.apiUrl}/manager/${restaurant_user_id}/team`,
        'GET'
      )

      isLoading.value = false

      if (fetchError) {
        return {
          data: null,
          error: fetchError
        }
      }
      console.log('getTeam data', data);

      return {
        data,
        error: null
      }
    } catch (err: any) {
      isLoading.value = false
      error.value = err.message || 'An error occurred'

      return {
        data: null,
        error: error.value
      }
    }
  }

  async function getUserDetails(restaurant_user_id: number, userId: string) {
    console.log('getUserDetails')
    isLoading.value = true
    error.value = null

    try {
      const { data, error: fetchError } = await fetchData(
        `${envStore.apiUrl}/manager/${restaurant_user_id}/team/${userId}`,
        'GET'
      )

      isLoading.value = false

      if (fetchError) {
        return {
          data: null,
          error: fetchError
        }
      }
      console.log('getUserDetails data', data);

      return {
        data,
        error: null
      }
    } catch (err: any) {
      isLoading.value = false
      error.value = err.message || 'An error occurred'

      return {
        data: null,
        error: error.value
      }
    }
  }

  async function exportWorkerHours(restaurant_user_id, workerId) {
    isLoading.value = true
    error.value = null

    try {
      const { data, error: fetchError } = await fetchData(
        `${envStore.apiUrl}/manager/${restaurant_user_id}/team/${workerId}/export-working-periods`,
        'GET'
      )

      isLoading.value = false

      if (fetchError) {
        return {
          data: null,
          error: fetchError
        }
      }
      console.log('exportWorkerHours data', data);

      // Download the CSV data
      downloadCSV(data, `worker-hours-${workerId}.csv`);

      return {
        data,
        error: null
      }
    } catch (err: any) {
      isLoading.value = false
      error.value = err.message || 'An error occurred'

      return {
        data: null,
        error: error.value
      }
    }
  }

  function downloadCSV(csvData: string, filename: string) {
    // Create a Blob with the CSV data
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

    // Create a temporary URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a temporary link element
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);

    // Append the link to the body
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up
    setTimeout(() => {
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }, 100);
  }



  return {
    getTeam,
    getUserDetails,
    exportWorkerHours,
    isLoading,
    error
  }
}