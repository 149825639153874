<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import dayjs from 'dayjs'
import { formatBalance, formatLocalDate, formatTime, getCurrentUTCTime, getTimeDifferenceInSeconds, toLocalTime } from '@/utils/time'
import Button from 'primevue/button'
import ConfirmDialog from 'primevue/confirmdialog'
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'
import Skeleton from 'primevue/skeleton'
import Message from 'primevue/message'
import { useEmployee } from '@/composables/useEmployee'
import { useUserStore } from '@/stores/useUserStore'

// Track document visibility to handle background/foreground app states
const visibility = ref(document.visibilityState)
const handleVisibilityChange = () => {
  const prevVisibility = visibility.value
  visibility.value = document.visibilityState

  if (visibility.value === 'visible' && prevVisibility === 'hidden') {
    initTimeClock()
  } else if (visibility.value === 'hidden') {
    if (clock) clearInterval(clock)
    clock = null
    workingTime.value = 0
    currentlyWorking.value = false
    balance.value = 0
  }
}

onMounted(() => {
  document.addEventListener('visibilitychange', handleVisibilityChange)
})

const toast = useToast()
const confirm = useConfirm()
const { startWorkingPeriod, endWorkingPeriod, getLastWorkingPeriod, getWorkingPeriods, getBalance } = useEmployee()
const userStore = useUserStore()
const user = ref(userStore.user)

const alreadyWorkedToday = ref(false)
const currentlyWorking = ref(false)
const dayFinished = ref(false)
const isAbsentToday = ref(false)
const isAbsenceModalOpen = ref(false)

const loading = ref(true)
const startNewDayLoading = ref(false)
const endDayLoading = ref(false)
const toggleClockLoading = ref(false)

const workingTime = ref(0)
const balance = ref(0) // Seconds of balance time (positive or negative)
let currentWorkingPeriodID = 0

let clock: number | null = null
let clockButtonLabel = computed(() =>
  currentlyWorking.value ? 'Sortie' : 'Entrée'
)
// let clockButtonLabel = computed(() =>
//   currentlyWorking.value ? 'Prendre une pause' : 'Reprendre le travail'
// )

// Local state for simplified version
// const dailyWorkHours = ref(8.5) // Default daily work hours

// async function initBalance() {

//   if (!user.value || !user.value.active_restaurant) return
//   console.log("Init balance")

//   try {
//     // const today = formatLocalDate();
//     const { data, error } = await getBalance(
//       user.value.active_restaurant.restaurant_user_id
//     )

//     if (error) {
//       console.error(error)
//       toast.add({
//         severity: 'error',
//         summary: 'Erreur',
//         detail: 'Une erreur est survenue lors de la récupération du solde',
//         life: 3000
//       })
//       return
//     } else if (data) {
//       // console.log("data", data)
//       balance.value = data.balance_s
//     }
//   } catch (err) {
//     console.error('Error initializing balance:', err)
//   }
// }


async function initWorkingTime() {
  if (!user.value || !user.value.active_restaurant) return
  try {
    // const today = formatLocalDate()
    const { data, error } = await getLastWorkingPeriod(
      user.value.active_restaurant.restaurant_user_id
    )

    if (error) {
      console.error(error)
      toast.add({
        severity: 'error',
        summary: 'Erreur',
        detail: 'Une erreur est survenue lors de la récupération des périodes de travail',
        life: 3000
      })
      return
    }

    // let hasOpenPeriod = false
    if (data && data.last_working_period) {
      const workingPeriod = data.last_working_period

      if (!workingPeriod.end_time) {
        // hasOpenPeriod = true
        currentWorkingPeriodID = workingPeriod.id
        currentlyWorking.value = true

        // Log the hours worked so far with proper timezone handling
        // console.log("Working period start time (not local):", workingPeriod.start_time)
        // console.log("Working period start time (local):", toLocalTime(workingPeriod.start_time))
        // console.log("Now (local):", new Date())

        // Calculate time difference with timezone awareness
        const currentWorkingTime = getTimeDifferenceInSeconds(workingPeriod.start_time)
        // console.log("Current working time (seconds):", currentWorkingTime)

        workingTime.value += currentWorkingTime
        console.log("Working period enaad time:", workingPeriod.end_time)
        startClock(false)
      } else {
        workingTime.value = 0;
        currentlyWorking.value = false;
      }
    }
    // console.log("Working time:", workingTime.value)
  } catch (err) {
    console.error('Error initializing working periods:', err)
  }
}


// async function initWorkingPeriod() {
//   if (!user.value || !user.value.active_restaurant) return

//   try {
//     const today = formatLocalDate()
//     const { data, error } = await getWorkingPeriods(
//       user.value.active_restaurant.restaurant_user_id,
//       { start_date: today, end_date: today }
//     )

//     if (error) {
//       console.error(error)
//       toast.add({
//         severity: 'error',
//         summary: 'Erreur',
//         detail: 'Une erreur est survenue lors de la récupération des périodes de travail',
//         life: 3000
//       })
//       return
//     }

//     if (data && data.working_periods && data.working_periods.length > 0) {
//       alreadyWorkedToday.value = true
//       const workingPeriods = data.working_periods
//       let hasOpenPeriod = false

//       for (let workingPeriod of workingPeriods) {
//         if (workingPeriod.last_period_of_day) {
//           dayFinished.value = true
//         }

//         if (workingPeriod.end_time) {
//           workingTime.value += getTimeDifferenceInSeconds(workingPeriod.start_time, workingPeriod.end_time)
//         } else {
//           hasOpenPeriod = true
//           currentWorkingPeriodID = workingPeriod.id

//           // Log the hours worked so far with proper timezone handling
//           // console.log("Working period start time (not local):", workingPeriod.start_time)
//           // console.log("Working period start time (local):", toLocalTime(workingPeriod.start_time))
//           // console.log("Now (local):", new Date())

//           // Calculate time difference with timezone awareness
//           const currentWorkingTime = getTimeDifferenceInSeconds(workingPeriod.start_time)
//           // console.log("Current working time (seconds):", currentWorkingTime)

//           workingTime.value += currentWorkingTime
//           console.log("Working period enaad time:", workingPeriod.end_time)
//           balance.value += currentWorkingTime
//         }
//       }
//       // Start the clock only if there's an open period
//       if (hasOpenPeriod) {
//         startClock(false)
//       }
//       // console.log("Working time:", workingTime.value)
//     }
//   } catch (err) {
//     console.error('Error initializing working periods:', err)
//   }
// }

async function initDayOff() {
  // This would need an API endpoint to check if the user is off today
  // For now, we'll assume the user is not off
  isAbsentToday.value = false
}

// async function startNewDay() {
//   if (!user.value || !user.value.active_restaurant) return

//   workingTime.value = 0
//   startNewDayLoading.value = true

//   // On weekend or absent days, don't deduct from balance
//   if (new Date().getDay() === 0 || new Date().getDay() === 6 || isAbsentToday.value) {
//     await startClock(true)
//     startNewDayLoading.value = false
//     return
//   }

//   // Deduct daily work hours from balance
//   balance.value -= dailyWorkHours.value * 60 * 60

//   await startClock(true)
//   startNewDayLoading.value = false
// }

async function toggleClock() {
  toggleClockLoading.value = true
  if (clock) {
    await stopClock()
  } else {
    await startClock()
  }
  toggleClockLoading.value = false
}

async function startClock(needDBInsert: boolean = true) {
  clearClockInterval();
  if (!user.value || !user.value.active_restaurant) return

  if (needDBInsert) {
    const { data, error } = await startWorkingPeriod(
      user.value.active_restaurant.restaurant_user_id,
      { start_time: getCurrentUTCTime() }
    )

    if (data) {
      currentWorkingPeriodID = data.id
    } else {
      console.log('error', error)
      toast.add({
        severity: 'error',
        summary: 'Erreur',
        detail: 'Une erreur est survenue lors de la création de la période de travail',
        life: 3000
      })
      return
    }
  }

  console.log("WORKING TIME", workingTime.value)
  const startTime = new Date().getTime()
  const initialWorkingTime = workingTime.value
  const initialBalance = balance.value
  console.log("initialWorkingTime", initialWorkingTime)

  if (typeof window !== 'undefined') {
    clock = window.setInterval(() => {
      console.log("workingTime", workingTime.value)
      console.log("initialWorkingTime", initialWorkingTime)
      const currentTime = new Date().getTime()
      const elapsedTime = Math.round((currentTime - startTime) / 1000)
      console.log("elapsedTime", elapsedTime)

      workingTime.value = initialWorkingTime + elapsedTime
      balance.value = initialBalance + elapsedTime
    }, 1000)
  }
  currentlyWorking.value = true
}

async function stopClock(): Promise<{ success: boolean }> {
  if (!clock || !user.value || !user.value.active_restaurant) return { success: false }

  if (currentWorkingPeriodID) {
    const { data, error } = await endWorkingPeriod(
      user.value.active_restaurant.restaurant_user_id,
      {
        end_time: getCurrentUTCTime(),
        last_period_of_day: false
      }
    )

    if (error) {
      console.log('error ending working period', error)
      toast.add({
        severity: 'error',
        summary: 'Erreur',
        detail: 'Une erreur est survenue lors de la fin de la période de travail',
        life: 3000
      })
      return { success: false }
    }
  }

  clearInterval(clock)
  clock = null
  currentlyWorking.value = false
  alreadyWorkedToday.value = true
  workingTime.value = 0
  return { success: true }
}
// async function stopClock(): Promise<{ success: boolean }> {
//   if (!clock || !user.value || !user.value.active_restaurant) return { success: false }

//   if (currentWorkingPeriodID) {
//     const { data, error } = await endWorkingPeriod(
//       user.value.active_restaurant.restaurant_user_id,
//       {
//         end_time: getCurrentUTCTime(),
//         last_period_of_day: false
//       }
//     )

//     if (error) {
//       console.log('error ending working period', error)
//       toast.add({
//         severity: 'error',
//         summary: 'Erreur',
//         detail: 'Une erreur est survenue lors de la fin de la période de travail',
//         life: 3000
//       })
//       return { success: false }
//     }
//   }

//   clearInterval(clock)
//   clock = null
//   currentlyWorking.value = false
//   alreadyWorkedToday.value = true
//   return { success: true }
// }

// async function endDay() {
//   endDayLoading.value = true

//   if (clock) {
//     // When ending the day, mark the period as the last one
//     if (user.value && user.value.active_restaurant && currentWorkingPeriodID) {
//       const { data, error } = await endWorkingPeriod(
//         user.value.active_restaurant.restaurant_user_id,
//         {
//           end_time: getCurrentUTCTime(),
//           last_period_of_day: true
//         }
//       )

//       if (error) {
//         console.log('error ending working period for the day', error)
//         toast.add({
//           severity: 'error',
//           summary: 'Erreur',
//           detail: 'Une erreur est survenue lors de la fin de la journée',
//           life: 3000
//         })
//         endDayLoading.value = false
//         return
//       }
//     }

//     const { success } = await stopClock()
//     if (!success) {
//       endDayLoading.value = false
//       return
//     }
//   }

//   toast.add({
//     severity: 'success',
//     summary: 'Bravo !',
//     detail: 'La journée est terminée',
//     life: 3000
//   })

//   // Mark day as finished
//   dayFinished.value = true
//   endDayLoading.value = false
// }

// function modalConfirmEndDay() {
//   confirm.require({
//     group: 'endDay',
//     message: 'Voulez-vous vraiment terminer la journée?',
//     header: 'Confirmation',
//     accept: () => {
//       endDay()
//     },
//     reject: () => {}
//   })
// }

function markAbsent() {
  isAbsentToday.value = true
}

async function initTimeClock() {
  loading.value = true

  // Check if user needs to be refreshed
  if (!user.value) {
    await userStore.refreshUser()
    user.value = userStore.user
  }

  // await initBalance()
  // await initWorkingPeriod()
  await initWorkingTime()
  await initDayOff()

  loading.value = false
}

onMounted(async () => {
  await initTimeClock()
})

onBeforeUnmount(() => {
  clearClockInterval();
})

function clearClockInterval() {
  if (clock !== null) {
    clearInterval(clock)
    clock = null
  }
}
</script>

<template>
  <main class="flex flex-col h-full">
    <ConfirmDialog group="endDay">
      <template #container="{ message, acceptCallback, rejectCallback }">
        <div class="flex p-5 flex-column surface-overlay border-round">
          <span class="block text-2xl font-bold">{{ message.header }}</span>
          <p class="mb-0">{{ message.message }}</p>
          <div class="flex gap-2 mt-4 align-items-center justify-content-end">
            <Button label="Annuler" outlined @click="rejectCallback"></Button>
            <Button label="Confirmer" @click="acceptCallback"></Button>
          </div>
        </div>
      </template>
    </ConfirmDialog>

    <div class="flex items-center justify-center flex-grow h-[60dvh]">
      <Message severity="info" v-if="isAbsentToday">Vous ne travaillez pas aujourd'hui</Message>

      <div class="flex gap-8 mb-4 justify-content-center flex-column flex-grow-1">
        <div class="flex flex-col items-center gap-2 align-middle">
          Temps de travail en cours:
          <Skeleton v-if="loading" width="10rem" height="3rem" class="block" />
          <span v-else class="text-4xl font-bold number" :class="{ 'text-gray-400': !currentlyWorking }">{{
            formatTime(workingTime) }}</span>
        </div>
      </div>
    </div>

    <div class="fixed left-0 z-50 flex items-center justify-center w-full gap-2 py-2 align-middle bottom-16" v-if="!loading">
      <div class="flex items-center justify-center w-full gap-2 align-middle flex-column lg:flex-row">
        <Button @click="toggleClock()" class="w-2/3 min-w-64 max-w-96" :label="clockButtonLabel" :loading="toggleClockLoading" />
      </div>
    </div>
    <!-- <div class="flex gap-8 mb-4 justify-content-center flex-column flex-grow-1">
      <div class="flex gap-2 flex-column align-items-center">
        Temps travaillé aujourd'hui:
        <Skeleton v-if="loading" width="10rem" height="3rem" class="block" />
        <span v-else class="text-4xl font-bold number">{{ formatTime(workingTime) }}</span>
      </div>
      <div class="flex gap-2 flex-column align-items-center">
        Solde:
        <Skeleton v-if="loading" width="10rem" height="3rem" class="block" />
        <span v-else class="text-4xl font-bold number">{{ formatBalance(balance) }}</span>
      </div>
    </div>

    <div class="flex gap-2 flex-column lg:flex-row" v-if="!loading">
      <div v-if="!alreadyWorkedToday && !currentlyWorking" class="flex flex-row w-full gap-2">
        <Button icon="pi pi-megaphone" severity="secondary" @click="markAbsent" />
        <Button
          @click="startNewDay()"
          class="flex-grow-1"
          label="Commencer la journée"
          :loading="startNewDayLoading"
        />
      </div>

      <div
        v-else-if="(alreadyWorkedToday || currentlyWorking) && !dayFinished"
        class="flex w-full gap-2 flex-column lg:flex-row"
      >
        <Button
          @click="toggleClock()"
          class="flex-grow-1"
          :label="clockButtonLabel"
          :loading="toggleClockLoading"
        />
        <Button
          v-if="(alreadyWorkedToday || currentlyWorking) && !dayFinished"
          @click="modalConfirmEndDay()"
          class="flex-grow-1"
          label="Terminer la journée"
          severity="secondary"
          :loading="endDayLoading"
        />
      </div>
    </div> -->
  </main>
</template>
