<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 21H17M12 21C14.3869 21 16.6761 20.0518 18.364 18.364C20.0518 16.6761 21 14.3869 21 12H3C3 14.3869 3.94821 16.6761 5.63604 18.364C7.32387 20.0518 9.61305 21 12 21Z"
      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M11.3799 12C10.7743 12.0056 10.1889 11.7821 9.74117 11.3742C9.29346 10.9663 9.01653 10.4042 8.96592 9.80066C8.91531 9.19711 9.09476 8.59675 9.46828 8.11998C9.8418 7.64321 10.3818 7.3253 10.9799 7.23C10.8841 6.80623 10.9052 6.36441 11.0408 5.95167C11.1765 5.53893 11.4216 5.17075 11.7501 4.88641C12.0786 4.60206 12.4781 4.41222 12.906 4.33712C13.3339 4.26203 13.7742 4.3045 14.1799 4.46C14.3575 4.18036 14.5911 3.94053 14.866 3.7556C15.1409 3.57067 15.451 3.44465 15.777 3.38547C16.103 3.32629 16.4376 3.33523 16.76 3.41174C17.0823 3.48824 17.3853 3.63065 17.6499 3.83C18.1115 3.46722 18.69 3.28621 19.276 3.32119C19.862 3.35618 20.4149 3.60475 20.83 4.01987C21.2451 4.43499 21.4937 4.98787 21.5287 5.57389C21.5637 6.15991 21.3827 6.73844 21.0199 7.2C21.2457 7.49993 21.398 7.84865 21.4645 8.21813C21.5311 8.58761 21.51 8.96755 21.403 9.32741C21.296 9.68727 21.1061 10.017 20.8485 10.2901C20.5909 10.5633 20.2729 10.7721 19.9199 10.9C20.0115 11.2599 20.0217 11.6357 19.9499 12M12.9999 12L16.9999 8"
      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M10.9001 7.25C10.3515 6.66527 9.6396 6.25934 8.85703 6.08512C8.07445 5.9109 7.25754 5.97648 6.51278 6.27331C5.76802 6.57013 5.12995 7.08443 4.68175 7.74918C4.23354 8.41392 3.99599 9.19828 4.00005 10C4.00005 10.73 4.20005 11.41 4.54005 12"
      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>

</template>
