import AddEmployeeView from '@/views/Admin/AddEmployeeView.vue'
import GenerationHistoryView from '@/views/Admin/GenerationHistoryView.vue'

const adminRoutes = [
  {
    path: '/admin',
    name: 'admin',
    redirect: '/admin/generation-history',
    children: [
      {
        path: 'generation-history',
        meta: {
          headerConfig: {
            headerLabel: 'history',
            hide: false,
            showBack: true,
            showProfile: false,
          },
          footerConfig: {
            hide: false,
          },
          securityConfig: {
            requireAuthentication: true,
            rolesAllowed: ['admin'],
          },
          containerMargins: true,
        },
        component: GenerationHistoryView,
      },
    ],
  }, 
   {
      path: '/employee/add',
      name: 'add-employee',
      meta: {
        headerConfig: {
          headerLabel: 'addEmployee',
          hide: false,
          showBack: true,
          showProfile: true,
        },
        footerConfig: {
          hide: false,
        },
        securityConfig: {
          requireAuthentication: true,
          rolesAllowed: ['common', 'admin'],
        },
        containerMargins: true,
      },
      component: AddEmployeeView,
    },
  
]

export default adminRoutes
