import OrdersShoppingListView from '@/views/Orders/OrdersShoppingListView.vue'
import OrderFormView from '@/views/Orders/OrderFormView.vue'

const ordersRoutes = [
  {
    path: '/orders',
    name: 'orders',
    redirect: '/orders/shopping-list',
    children: [
      {
        path: 'shopping-list',
        meta: {
          headerConfig: {
            headerLabel: 'orders',
            hide: false,
            showBack: false,
            showProfile: true,
          },
          footerConfig: {
            hide: false,
          },
          securityConfig: {
            requireAuthentication: true,
            rolesAllowed: ['common', 'admin'],
            // requiredPermissions: {
            //   category: 'orders',
            //   action: 'view'
            // }
          },
          containerMargins: true,
        },
        component: OrdersShoppingListView,
      },
      {
        path: 'order-form',
        name: 'order-form',
        meta: {
          headerConfig: {
            headerLabel: 'orderForm',
            hide: false,
            showBack: true,
            showProfile: false,
          },
          footerConfig: {
            hide: false,
          },
          securityConfig: {
            requireAuthentication: true,
            rolesAllowed: ['common', 'admin'],
            restaurantRoleAllowed: ['admin', 'manager'],
            // requiredPermissions: {
            //   category: 'orders',
            //   action: 'view'
            // },
          },
          containerMargins: true,
        },
        component: OrderFormView,
      },
    ],
  },
]

export default ordersRoutes
