<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import Button from 'primevue/button'
import type { UserRegisterRequest } from '@/types/api'
import InputText from 'primevue/inputtext'
import Password from 'primevue/password'
import { computed, onMounted, reactive, ref } from 'vue'
import InlineMessage from '@/components/InlineMessage.vue'
import Divider from 'primevue/divider'
import { useAuth } from '@/composables/useAuth.js'
import { useFetch } from '@/composables/useFetch.js'
import { useEnvStore } from '@/stores/envStore.js'
import { useToast } from 'primevue/usetoast'
import { useAppStore } from '@/stores/appStore'
import Dropdown from 'primevue/dropdown'
import FileUpload from 'primevue/fileupload'
import InlineLink from '@/components/InlineLink.vue'
import { useRoute, useRouter } from 'vue-router'
import Checkbox from 'primevue/checkbox'

const { completeRegistration, errors, isFetching } = useAuth()
const { fetchData } = useFetch()
const toast = useToast()
const route = useRoute()
const router = useRouter()

errors.value = {}

const password = ref('')
const passwordConfirmation = ref('')
const isTokenValid = ref(false)
const isValidating = ref(true)
const acceptedCGU = ref(false)
const appStore = useAppStore()

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const userForm = ref<UserRegisterRequest & { avs_number?: string; address?: string }>({
  email: '',
  password: '',
  password_confirmation: '',
  first_name: '',
  last_name: '',
  language: useAppStore().language,
  country: 'CH',
  restaurant_name: '',
  restauration_type_id: 0,
  is_private: true,
  avs_number: '',
  address: '',
})

const touched = reactive({
  email: false,
  password: false,
  passwordConfirmation: false,
  first_name: false,
  last_name: false,
  avs_number: false,
  address: false,
})

const isEmailValid = computed(() => {
  if (!touched.email) return true
  return touched.email && userForm.value.email && isValidEmail(userForm.value.email)
})

const isPasswordValid = computed(() => {
  if (!touched.password) return true
  return touched.password && password.value?.length >= 8
})

const isPasswordConfirmationValid = computed(() => {
  if (!touched.passwordConfirmation) return true
  return touched.passwordConfirmation && password.value === passwordConfirmation.value
})

const isFirstNameValid = computed(() => {
  if (!touched.first_name) return true
  return touched.first_name && userForm.value.first_name
})

const isLastNameValid = computed(() => {
  if (!touched.last_name) return true
  return touched.last_name && userForm.value.last_name
})

const isAvsNumberValid = computed(() => {
  if (!touched.avs_number) return true
  return touched.avs_number && /^756\.\d{4}\.\d{4}\.\d{2}$/.test(userForm.value.avs_number || '')
})

const isAddressValid = computed(() => {
  if (!touched.address) return true
  return touched.address && userForm.value.address && userForm.value.address.length >= 5
})

const isFormValid = computed(() => {
  return (
    userForm.value.email &&
    isValidEmail(userForm.value.email) &&
    password.value?.length >= 8 &&
    password.value === passwordConfirmation.value &&
    userForm.value.first_name &&
    userForm.value.last_name &&
    // isAvsNumberValid.value &&
    // isAddressValid.value &&
    acceptedCGU.value
  )
})

const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

const hasError = (field: string) => {
  return errors.value && field in errors.value
}

async function validateToken() {
  // isTokenValid.value = true;
  // isValidating.value = false;
  // return true
  try {
    const { data, error } = await fetchData(
      `${useEnvStore().apiUrl}/validate-employee-token/${route.params.token}`,
      'GET'
    )
    if (error) {
      toast.add({
        severity: 'error',
        summary: 'Error',
        detail: t('auth.registration.invalidToken'),
        life: 3000,
      })
      router.push('/login')
      return
    }
    isTokenValid.value = true
    if (data?.user) {
      const userData = data.user
      // Pre-fill form fields with user data
      userForm.value.email = userData.email || ''
      userForm.value.first_name = userData.first_name || ''
      userForm.value.last_name = userData.last_name || ''

      // If language is available, update the app language
      if (userData.language && ['en', 'fr', 'de', 'it'].includes(userData.language)) {
        useAppStore().setLanguage(userData.language as 'en' | 'fr' | 'de' | 'it')
        selectedLanguage.value = languagesList.value.find(
          (language) => language.value === userData.language
        ) || selectedLanguage.value
      }
    }
  } catch (err) {
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: t('auth.registration.invalidToken'),
      life: 3000,
    })
    router.push('/login')
  } finally {
    isValidating.value = false
  }
}

async function submitForm() {
  if (!isFormValid.value) {
    Object.keys(touched).forEach(key => touched[key] = true)
    return
  }

  userForm.value.is_private = true
  await completeRegistration({
    ...userForm.value,
    password: password.value,
    password_confirmation: passwordConfirmation.value,
    employee_token: route.params.token as string
  }, password.value, passwordConfirmation.value, toast)

  if (Object.keys(errors.value).length === 0) {
    router.push('/timeclock')
  }
}

const onUpload = (event: any) => {
  if (event.files && event.files[0]) {
    const file = event.files[0]
    console.log('File uploaded:', file)
  }
}

const languagesList = ref<{ label: string; value: 'fr' | 'en' | 'de' | 'it' }[]>([
  { label: 'Français', value: 'fr' },
  { label: 'English', value: 'en' },
  { label: 'Deutsch', value: 'de' },
  { label: 'Italiano', value: 'it' },
])

const selectedLanguage = ref(
  languagesList.value.find((language) => language.value === useAppStore().language) || languagesList.value[0]
)

function updateLanguage() {
  const newLanguage = selectedLanguage.value.value
  useAppStore().setLanguage(newLanguage)
}

onMounted(async () => {
  if (!route.params.token) {
    router.push('/login')
    return
  }
  await validateToken()

  const browserLanguage = navigator.language || navigator.languages[0]
  const languageCode = browserLanguage.split('-')[0]
  const supportedLanguages = ['en', 'fr', 'de', 'it']
  if (supportedLanguages.includes(languageCode)) {
    useAppStore().setLanguage(languageCode as 'en' | 'fr' | 'de' | 'it')
    selectedLanguage.value = languagesList.value.find((language) => language.value === languageCode) || languagesList.value[0]
  }
})
</script>

<template>
  <main v-if="!isValidating">
    <div class="flex justify-end mb-4">
      <Dropdown class="w-40" :options="languagesList" optionLabel="label" v-model="selectedLanguage"
        @change="updateLanguage">
        <template #value="slotProps">
          {{ slotProps.value.label }}
        </template>
        <template #option="slotProps">
          {{ slotProps.option.label }}
        </template>
      </Dropdown>
    </div>

    <div v-if="isTokenValid" class="flex flex-col gap-4">
      <h2 class="mb-4 text-2xl font-semibold">{{ t('auth.registration.completeRegistration') }}</h2>

      <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-2">
          <label for="email">{{ t('auth.user.form.label.mail') }} *</label>
          <p class="text-sm font-bold">{{ userForm.email }}</p>
          <!-- <InputText type="email" id="email" class="w-full" v-model="userForm.email"
            :placeholder="t('auth.user.form.placeholder.mail')" :invalid="hasError('email') || !isEmailValid"
            @blur="touched.email = true" disabled/>
          <InlineMessage severity="error" v-if="hasError('email') || !isEmailValid">
            {{ !isEmailValid ? t('auth.user.form.error.invalid_email') : errors.email[0] }}
          </InlineMessage> -->
        </div>

        <div class="flex flex-col gap-2">
          <label for="firstName">{{ t('auth.user.form.label.first_name') }} *</label>
          <InputText type="text" id="firstName" v-model="userForm.first_name" class="w-full"
            :placeholder="t('auth.user.form.placeholder.first_name')"
            :invalid="hasError('first_name') || !isFirstNameValid" @blur="touched.first_name = true" />
          <InlineMessage severity="error" v-if="hasError('first_name') || !isFirstNameValid">
            {{ !isFirstNameValid ? t('auth.user.form.error.invalid_first_name') : errors.first_name[0] }}
          </InlineMessage>
        </div>

        <div class="flex flex-col gap-2">
          <label for="lastName">{{ t('auth.user.form.label.last_name') }} *</label>
          <InputText type="text" id="lastName" v-model="userForm.last_name" class="w-full"
            :placeholder="t('auth.user.form.placeholder.last_name')"
            :invalid="hasError('last_name') || !isLastNameValid" @blur="touched.last_name = true" />
          <InlineMessage severity="error" v-if="hasError('last_name') || !isLastNameValid">
            {{ !isLastNameValid ? t('auth.user.form.error.invalid_last_name') : errors.last_name[0] }}
          </InlineMessage>
        </div>

        <!-- <div class="flex flex-col gap-2">
          <label for="avsNumber">{{ t('auth.user.form.label.avs_number') }} *</label>
          <InputText type="text" id="avsNumber" v-model="userForm.avs_number" class="w-full"
            placeholder="756.XXXX.XXXX.XX"
            :invalid="hasError('avs_number') || !isAvsNumberValid" @blur="touched.avs_number = true" />
          <InlineMessage severity="error" v-if="hasError('avs_number') || !isAvsNumberValid">
            {{ !isAvsNumberValid ? t('auth.user.form.error.invalid_avs_number') : errors.avs_number[0] }}
          </InlineMessage>
        </div> -->

        <!-- <div class="flex flex-col gap-2">
          <label for="address">{{ t('auth.user.form.label.address') }} *</label>
          <InputText type="text" id="address" v-model="userForm.address" class="w-full"
            :placeholder="t('auth.user.form.placeholder.address')"
            :invalid="hasError('address') || !isAddressValid" @blur="touched.address = true" />
          <InlineMessage severity="error" v-if="hasError('address') || !isAddressValid">
            {{ !isAddressValid ? t('auth.user.form.error.invalid_address') : errors.address[0] }}
          </InlineMessage>
        </div> -->

        <div class="flex flex-col gap-2">
          <label for="password">{{ t('auth.user.form.label.password') }} *</label>
          <Password id="password" v-model="password" :promptLabel="t('auth.user.form.placeholder.password')"
            :weakLabel="t('auth.user.form.label.password_weak')"
            :mediumLabel="t('auth.user.form.label.password_medium')"
            :strongLabel="t('auth.user.form.label.password_strong')" :invalid="hasError('password') || !isPasswordValid"
            :placeholder="t('auth.user.form.placeholder.password')" toggleMask class="w-full"
            @blur="touched.password = true" />
          <small id="password-help">{{ t('auth.user.form.label.password_help') }}</small>
          <InlineMessage severity="error" v-if="hasError('password') || !isPasswordValid">
            {{ !isPasswordValid ? t('auth.user.form.error.invalid_password') : errors.password[0] }}
          </InlineMessage>
        </div>

        <div class="flex flex-col gap-2">
          <label for="passwordConfirmation">{{ t('auth.user.form.label.password_confirmation') }} *</label>
          <Password id="passwordConfirmation" v-model="passwordConfirmation"
            :placeholder="t('auth.user.form.placeholder.password_confirmation')"
            :invalid="hasError('password_confirmation') || !isPasswordConfirmationValid" toggleMask class="w-full"
            @blur="touched.passwordConfirmation = true" />
          <InlineMessage severity="error" v-if="hasError('password_confirmation') || !isPasswordConfirmationValid">
            {{ !isPasswordConfirmationValid ? t('auth.user.form.error.invalid_password_confirmation') :
            errors.password_confirmation[0] }}
          </InlineMessage>
        </div>

        <!-- <div class="flex flex-col gap-2">
          <label>{{ t('auth.user.form.label.profile_picture') }}</label>
          <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" @upload="onUpload"
            :chooseLabel="t('common.chooseFile')" />
        </div> -->
        <div class="flex items-center gap-2 mt-8">
          <Checkbox v-model="acceptedCGU" :binary="true" inputId="acceptCGU" />
          <label for="acceptCGU">
            {{ t('auth.registration.acceptCGU') }}
            <InlineLink :destination="`/conditions-generales/${appStore.language}`" target="_blank">
              {{ t('auth.registration.readCGU') }}
            </InlineLink>
          </label>
        </div>

        <Button :label="t('auth.registration.register')" :disabled="!isFormValid || isFetching" :loading="isFetching"
          class="w-full mt-4" size="large" @click="submitForm" />
      </div>
    </div>
  </main>
</template>
