<script setup lang="ts">
import NavBarItem from '@/components/NavBar/NavBarItem.vue'
import NavHomeIcon from '@/assets/icons/NavBar/NavHomeIcon.vue'
import NavSearchIcon from '@/assets/icons/NavBar/NavSearchIcon.vue'
import NavOrdersIcon from '@/assets/icons/NavBar/NavOrdersIcon.vue'
import NavCreateIcon from '@/assets/icons/NavBar/NavCreateIcon.vue'
import NavPlanningIcon from '@/assets/icons/NavBar/NavPlanningIcon.vue'
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import { useEnvStore } from '@/stores/envStore'
import NavMyRecipesIcon from '@/assets/icons/NavBar/NavMyRecipesIcon.vue'
import { useUserStore } from '@/stores/useUserStore'
import NavTimeClockIcon from '@/assets/icons/NavBar/NavTimeClockIcon.vue'
import type { PermissionCategory } from '@/constants/permissions'
import { usePermissions } from '@/composables/usePermissions'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const userStore = useUserStore();
const { can } = usePermissions();


interface RoutePermission {
  category: PermissionCategory;
  actions: string[];
}
const navPermissions: Record<string, RoutePermission> = {
  // '/orders': { category: 'orders', actions: ['view'] },
  '/menu': { category: 'dishes', actions: ['create'] },
  // '/planning': { category: 'planning', actions: ['view'] },
  // '/my-recipes': { category: 'recipes', actions: ['view'] },
}

function hasRequiredPermission(path: string): boolean {
  //TODO :  add permissions check in the future, according to the needs
  // return true
  const permissionConfig = navPermissions[path];

  // If no permissions are defined for the route, always show it
  if (!permissionConfig) return true;

  // Check if the user has any of the required permissions
  return permissionConfig.actions.some(action =>
    can(permissionConfig.category, `${permissionConfig.category}_${action}`)
  );
}

</script>


<template>
  <nav
    :class="(useEnvStore().environment === 'staging' ? 'staging-mode ' : '') + (useEnvStore().environment === 'local' || useEnvStore().environment === 'development' ? 'dev-mode ' : '') + 'fixed bottom-0 grid max-w-[40rem]  w-full h-16 grid-flow-col bg-white border-t-2 border-primary-300 text-primary-700 z-20'">
    <NavBarItem v-if="!userStore.isEmployee" to="/">
      <template #icon>
        <NavHomeIcon />
      </template>
      <template #text> {{ t('navBar.home') }} </template>
    </NavBarItem>

    <NavBarItem v-if="userStore.isEmployee" to="/timeclock">
      <template #icon>
        <NavTimeClockIcon />
      </template>
      <template #text> {{ t('navBar.timeclock') }} </template>
    </NavBarItem>

    <NavBarItem v-if="hasRequiredPermission('/my-recipes')" to="/my-recipes">
      <template #icon>
        <NavMyRecipesIcon />
      </template>
      <template #text> {{ t('navBar.myRecipes') }} </template>
    </NavBarItem>

    <NavBarItem v-if="hasRequiredPermission('/menu')" to="/menu">
      <template #icon>
        <NavCreateIcon />
      </template>
      <template #text> {{ t('navBar.menu') }} </template>
    </NavBarItem>

    <NavBarItem v-if="hasRequiredPermission('/orders')" to="/orders">
      <template #icon>
        <NavOrdersIcon />
      </template>
      <template #text> {{ t('navBar.orders') }} </template>
    </NavBarItem>

    <NavBarItem v-if="hasRequiredPermission('/planning')" to="/planning">
      <template #icon>
        <NavPlanningIcon />
      </template>
      <template #text> {{ t('navBar.planning') }} </template>
    </NavBarItem>
  </nav>
</template>

<style scoped>
.dev-mode {
  background-color: rgb(169 255 175)
}

.staging-mode {
  background-color: rgb(243 178 141)
}
</style>